import React, { FunctionComponent, useState } from "react";
import {
  Col,
  ImageRibbon,
  TripoCard,
} from "@triporate/triporate-design-system";
import { Result } from "../types";
import DetailsContent from "./DetailsContent";
import MainContent from "./MainContent";

type SolutionProps = {
  data: Result;
};

const Solution: FunctionComponent<SolutionProps> = ({ data }) => {
  const [showDetails, setShowDetails] = useState(false);
  const handleMoreInfo = () => setShowDetails(!showDetails);

  return (
    <Col span={24} className="car-solution-row">
      <ImageRibbon src={data.col1.provider}>
        <TripoCard shadow>
          <MainContent
            data={data}
            showDetails={showDetails}
            handleMoreInfo={handleMoreInfo}
          />
          <DetailsContent
            url={data.col4.moreInfo.href}
            solutionId={data.col6.solutionId}
            showDetails={showDetails}
          />
        </TripoCard>
      </ImageRibbon>
    </Col>
  );
};

export default Solution;
