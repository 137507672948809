import React, { FunctionComponent, useState, useEffect } from "react";
import { Row, Col, Space, Skeleton } from "@triporate/triporate-design-system";

import { getResultDetails } from "../../../../../../services/Results";
import { SegmentData, SegmentId } from "../types";
import { DetailsData } from "./types";
import SegmentDetails from "./SegmentDetails";
import FareDetails from "./FareDetails";
import "./DetailsContent.scss";

type DetailsContentProps = {
  segment: SegmentData;
  selectedRadio: SegmentId;
  opendedSegmentId?: SegmentId;
  selectedSegmentsIds: {
    [key: number]: SegmentId;
  };
};

const DetailsContent: FunctionComponent<DetailsContentProps> = ({
  segment,
  selectedRadio,
  opendedSegmentId,
  selectedSegmentsIds,
}) => {
  const [dataCache, setDataCache] = useState<{ [key: string]: DetailsData }>(
    {}
  );
  const [data, setData] = useState<DetailsData>();
  const [loading, setLoading] = useState<boolean>(false);
  const showDetails =
    selectedRadio === segment.optionId && opendedSegmentId === segment.optionId;
  const url = segment.moreInfo.href;

  useEffect(() => {
    if (!showDetails || !url) return setData(undefined);
    if (data) return;

    const handleFetch = async (url: string, cacheKey: string) => {
      setLoading(true);

      const body = JSON.stringify({
        journeys: Object.values(selectedSegmentsIds),
      });

      const { data } = await getResultDetails(url, body);
      if (data) {
        setDataCache((prevState) => ({
          ...prevState,
          [cacheKey]: data,
        }));
        setData(data);
      }
      setLoading(false);
    };

    const cacheKey = Object.values(selectedSegmentsIds).toString();

    if (dataCache[cacheKey]) setData(dataCache[cacheKey]);
    else handleFetch(url, cacheKey);
  }, [showDetails, dataCache, data, url, selectedSegmentsIds]);

  if (!showDetails) return <></>;

  return (
    <Row>
      <Col span={22} offset={2}>
        <Space direction="vertical" className="space-witdh-100">
          {!data || loading ? (
            <Row>
              <Col span={20}>
                <Skeleton paragraph={{ rows: 2 }} active />
              </Col>
            </Row>
          ) : (
            <>
              {data.segment.map((segment, key) => (
                <SegmentDetails key={key} data={segment} />
              ))}
              <FareDetails data={data.fare} />
            </>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default DetailsContent;
