import { ErrorPageData } from "./types";

const errorPageMsgMockData: ErrorPageData = {
  code: 403,
  text: "¡Vaya! parece que no tienes permisos para acceder a esta página. Por favor, ponte en contacto con tu Travel Manager.",
  callToActionText: "Ir al Company Dahsboard",
  callToActionURL: "/",
};

export default errorPageMsgMockData;
