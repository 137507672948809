import React, { FunctionComponent } from "react";
import {
  Row,
  Col,
  Typography,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";
import TrainJourneySegment from "./TrainJourneySegment";
import TrainJourneyDetails from "./TrainJourneyDetails";
import TrainJourneyAncilliaries from "./TrainJourneyAncilliaries";
import { TrainJourneyData } from "../types";

type TrainJourneyInfoProps = {
  data?: TrainJourneyData;
};

const { Text } = Typography;

const TrainJourneyInfo: FunctionComponent<TrainJourneyInfoProps> = ({
  data,
}) => {
  return (
    <Row gutter={[0, 14]}>
      <Col span={24}>
        <TrainJourneyDetails data={data?.details} />
      </Col>
      <Col span={24}>
        <Row>
          <Col span={13}>
            <TrainJourneySegment data={data?.segment} />
          </Col>
          <Col span={8}>
            <TrainJourneyAncilliaries data={data?.ancilliaries} />
            <Row>
              <Col span={24}>
                <Icons icon={validateIconType(data?.conditions.icon)} />
                <Text>{data?.conditions.text}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Icons icon={validateIconType(data?.fareNames?.icon)} />
                <Text>{data?.fareNames?.text}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TrainJourneyInfo;
