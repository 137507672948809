import React from "react";
import { Row, Col, Skeleton } from "@triporate/triporate-design-system";

const LoadingFooter = (): JSX.Element => (
  <Row justify="end" gutter={12}>
    <Col>
      <Skeleton.Button active />
    </Col>
    <Col>
      <Skeleton.Button active />
    </Col>
  </Row>
);

export default LoadingFooter;
