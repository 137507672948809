import React, { FunctionComponent } from "react";
import {
  Row,
  Col,
  Icons,
  validateIconType,
  Button,
  Space,
} from "@triporate/triporate-design-system";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { InputData, FormValues, Fields } from "../../../../../../types";
import { SelectSearchAsyncOptions } from "../../../../CommonComponents";
import DateInput from "./DateInput";
import MultiFormItem from "./MultiFormItem";
import "./Inputs.scss";

type InputsProps = {
  fieldKey: number;
  name: number;
  inputListName: string;
  add: () => void;
  remove: (name: number) => void;
  formValues: FormValues;
  getFieldValue: (inputName: string) => unknown;
  setFieldsValue: (values: Fields) => void;
  isMultiTrip: boolean;
  isRoundTrip: boolean;
  departureName: string;
  returnName: string;
  datesName: string;
  departureData: InputData;
  returnData: InputData;
  datesData: InputData;
};

const Inputs: FunctionComponent<InputsProps> = ({
  fieldKey,
  add,
  remove,
  name,
  inputListName,
  formValues,
  getFieldValue,
  setFieldsValue,
  isMultiTrip,
  isRoundTrip,
  departureName,
  departureData,
  returnName,
  returnData,
  datesName,
  datesData,
  ...restField
}) => {
  const handleReverseDestinations = () => {
    const trips = getFieldValue(inputListName) as {
      dates: Date[];
      departure: string;
      return: string;
    }[];

    const reverseDestinations = (trip: {
      dates: Date[];
      departure: string;
      return: string;
    }) => {
      const returnDest = trip.departure;
      const departureDest = trip.return;

      return { ...trip, return: returnDest, departure: departureDest };
    };

    trips[fieldKey] = reverseDestinations(trips[fieldKey]);

    setFieldsValue((prevState: Fields) => ({ ...prevState, trips }));
  };

  return (
    <Row>
      <Space size={"middle"}>
        <div className="search-inputs-wrapper">
          <Col>
            <div className="search-input_width">
              <MultiFormItem
                {...restField}
                name={name}
                fieldKey={fieldKey}
                inputName={departureName}
                inputData={departureData}
                wrapperCol={{ span: 24 }}
              >
                <SelectSearchAsyncOptions
                  inputListName={inputListName}
                  inputName={departureName}
                  formValues={formValues}
                  inputData={departureData}
                  setFieldsValue={setFieldsValue}
                  addClass="no-right-border-radius"
                />
              </MultiFormItem>
            </div>
          </Col>

          <Col>
            <div className="direction-input_width">
              <Button
                type="primary"
                className="transport-journey-btn"
                onClick={handleReverseDestinations}
              >
                <Icons icon={validateIconType("journey")} />
              </Button>
            </div>
          </Col>

          <Col>
            <div className="search-input_width">
              <MultiFormItem
                {...restField}
                name={name}
                fieldKey={fieldKey}
                inputName={returnName}
                inputData={returnData}
                wrapperCol={{ span: 24 }}
              >
                <SelectSearchAsyncOptions
                  inputListName={inputListName}
                  inputName={returnName}
                  formValues={formValues}
                  inputData={returnData}
                  setFieldsValue={setFieldsValue}
                  addClass="no-left-border-radius"
                />
              </MultiFormItem>
            </div>
          </Col>
        </div>
        <Col>
          <div className="dates-input_width">
            <MultiFormItem
              {...restField}
              name={name}
              fieldKey={fieldKey}
              inputName={datesName}
              inputData={datesData}
            >
              <DateInput
                useRangepicker={isRoundTrip}
                inputListName={inputListName}
                datesName={datesName}
                datesData={datesData}
                name={name}
                formValues={formValues}
                setFieldsValue={setFieldsValue}
              />
            </MultiFormItem>
          </div>
        </Col>
      </Space>
      <Col span={1} className="multi-trip-icons primary-color">
        {isMultiTrip && (
          <>
            {name !== 0 && (
              <MinusCircleOutlined
                className="minus-icon"
                onClick={() => remove(name)}
              />
            )}
            <PlusCircleOutlined onClick={() => add()} />
          </>
        )}
      </Col>
    </Row>
  );
};

export default Inputs;
