import React from "react";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { StoreContext } from "redux-react-hook";
import { ConfigProvider, esLocale } from "@triporate/triporate-design-system";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");
import "@triporate/triporate-design-system/dist/css/fontello.css";
import i18n from "./i18n";
import { store } from "./store";
import RouterSwitch from "./RouterSwitch";
import {
  AuthContainer,
  ConfigContainer,
  TagManagerContainer,
  UniverseStyleContainer,
} from "./components/AppContainers";
import "./App.scss";

const App = (): JSX.Element => {
  return (
    <ConfigProvider locale={esLocale}>
      <BrowserRouter>
        <StoreContext.Provider value={store}>
          <I18nextProvider i18n={i18n}>
            <ConfigContainer>
              <UniverseStyleContainer>
                <TagManagerContainer>
                  <AuthContainer>
                    <RouterSwitch />
                  </AuthContainer>
                </TagManagerContainer>
              </UniverseStyleContainer>
            </ConfigContainer>
          </I18nextProvider>
        </StoreContext.Provider>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
