import React, { FunctionComponent, useEffect } from "react";

type InputsHandlersProps = {
  isMultiTrip: boolean;
  fieldsAmount: number;
  setFieldsAmount: (amount: number) => void;
  add: () => void;
  remove: (index: number) => void;
};

const InputsHandlers: FunctionComponent<InputsHandlersProps> = ({
  isMultiTrip,
  fieldsAmount,
  setFieldsAmount,
  add,
  remove,
}) => {
  useEffect(() => {
    setFieldsAmount(fieldsAmount);
  }, [fieldsAmount, setFieldsAmount]);

  useEffect(() => {
    // Add input if none is present.
    if (fieldsAmount === 0) add();
  }, [add, fieldsAmount]);

  useEffect(() => {
    // Remove all but the first field if not multi options.
    if (!isMultiTrip && fieldsAmount > 1) remove(1);
  }, [isMultiTrip, fieldsAmount, remove]);

  return <></>;
};

export default InputsHandlers;
