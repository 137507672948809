/* eslint-disable max-lines */
import React, {
    FunctionComponent,
    Fragment,
    useState,
    useEffect,
    useRef,
} from "react";
import {
    Select,
    Tooltip,
    Spin,
    Icons,
    validateIconType,
    Typography,
    Tag,
} from "@triporate/triporate-design-system";
import debounce from "lodash/debounce";

import { handleMandatoryParamQuery } from "../../../../../../utils";
import {
    getSelectOptions,
    getSelectSearchAsyncOptions,
} from "../../../../../../services/SearchForm";
import { OptionsGroup, InputData, FormValues, Fields } from "../../../../types";
import { validateSelectModeValue } from "./utils";

const { Option, OptGroup } = Select;
const { Text } = Typography;

type SelectSearchAsyncOptionsProps = {
    inputListName?: string;
    className?: string;
    inputData: InputData;
    inputName: string;
    disabled?: boolean;
    addClass?: string;
    formValues: FormValues;
    inputToCleanOnChange?: string | string[];
    cleanOnChange?: (inputRelated: string) => void;
    setFieldsValue: (values: Fields) => void;
    setQuerySearch?: (values: string) => void;
};

const SelectSearchAsyncOptions: FunctionComponent<
    SelectSearchAsyncOptionsProps
> = ({
    inputListName,
    inputName,
    inputData,
    formValues,
    inputToCleanOnChange,
    cleanOnChange,
    setFieldsValue,
    disabled,
    setQuerySearch,
    addClass,
    ...props
}): JSX.Element => {
    const {
        options: optionsUrl,
        defaultValue,
        placeholder,
        mode,
        query,
    } = inputData || {};

    const debounceTimeout = 800;
    const [loading, setLoading] = useState(false);
    const [optionsGroups, setOptionsGroups] = useState<OptionsGroup[]>([]);
    const formFieldReferenceName = query?.input;
    const formFieldReferenceValue =
        formFieldReferenceName && formValues[formFieldReferenceName]?.value;
    const fetchRef = useRef(0);

    const debounceFetcher = React.useMemo(() => {
        let isMounted = true;
        const url = handleMandatoryParamQuery(
            {
                baseUrl: optionsUrl,
                param: query?.param,
                value:
                    typeof formFieldReferenceValue === "string"
                        ? formFieldReferenceValue
                        : JSON.stringify(formFieldReferenceValue),
            },
            !!query?.search
        );

        const loadOptions = (value: string, firstAccess?: boolean) => {
            if (!url) return;

            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptionsGroups([]);
            if (isMounted) setLoading(true);
            setQuerySearch && setQuerySearch(value);
            getSelectSearchAsyncOptions(url, `${query?.search}=${value}`).then(
                ({ data }) => {
                    // for fetch callback order
                    if (fetchId !== fetchRef.current) return;
                    if (data) setOptionsGroups(data);
                    // Get the first coincidence with firstValue
                    if (firstAccess) {
                        const firstCoincidence = {
                            [inputName]: data?.[0]?.options?.[0],
                        };
                        inputListName
                            ? setFieldsValue({
                                  [inputListName]: [firstCoincidence],
                              })
                            : setFieldsValue(firstCoincidence);
                    }
                    setLoading(false);
                }
            );
            return function cleanup() {
                isMounted = false;
            };
        };
        const handleFetch = async (url: string) => {
            if (isMounted) setLoading(true);
            const { data } = await getSelectOptions(url);
            if (!data || !isMounted) return;
            setOptionsGroups(data);
            setLoading(false);
        };

        //handleFetch only for inputs with query.param
        if (url && query?.param && !disabled) {
            handleFetch(url).then(() => (isMounted = false));
        }

        return debounce(loadOptions, debounceTimeout);
    }, [
        debounceTimeout,
        optionsUrl,
        formFieldReferenceValue,
        query?.param,
        query?.search,
        setFieldsValue,
        inputName,
        inputListName,
        disabled,
        setQuerySearch,
    ]);

    //If defaultValue exist, load the first coincidence only the first time when access
    useEffect(() => {
        if (defaultValue && typeof defaultValue === "string")
            debounceFetcher(defaultValue, true);
    }, [defaultValue, debounceFetcher]);

    useEffect(() => {
        if (disabled) setOptionsGroups([]);
    }, [disabled]);

    useEffect(() => {
        setOptionsGroups([]);
        setFieldsValue(
            inputListName ? { [inputListName]: [] } : { [inputName]: undefined }
        );
    }, [query?.search, setFieldsValue, inputName, inputListName]);

    const handleCleanOnChange = () => {
        if (cleanOnChange && inputToCleanOnChange) {
            Array.isArray(inputToCleanOnChange)
                ? inputToCleanOnChange.forEach((input) => cleanOnChange(input))
                : cleanOnChange(inputToCleanOnChange);
        }
    };

    return (
        <Select
            {...props}
            labelInValue
            filterOption={!!formFieldReferenceValue}
            onSearch={(value) => {
                //Only search if param no exists on input definition
                if (formFieldReferenceValue) return;
                debounceFetcher(value);
            }}
            allowClear
            showSearch
            notFoundContent={loading && <Spin size="small" />}
            placeholder={placeholder}
            mode={validateSelectModeValue(mode)}
            loading={loading}
            disabled={disabled}
            dropdownClassName={`custom-select-dropdown-width`}
            onSelect={handleCleanOnChange}
            className={addClass}
        >
            {optionsGroups.map(
                ({ groupName, options }: OptionsGroup, groupKey: number) =>
                    groupName ? (
                        <OptGroup key={groupKey} label={groupName}>
                            {options.map(
                                (
                                    { icon, label, value, tooltip, moreInfo },
                                    optionKey: number
                                ) => (
                                    <Option key={optionKey} value={value}>
                                        <Tooltip
                                            title={tooltip}
                                            placement="left"
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div style={{ flexGrow: 1 }}>
                                                    <Icons
                                                        icon={validateIconType(
                                                            icon
                                                        )}
                                                    />
                                                    <Text
                                                        style={{
                                                            paddingLeft: "2px",
                                                        }}
                                                    >
                                                        {label}
                                                    </Text>
                                                </div>
                                                {query?.search ===
                                                    "?station" && (
                                                    <Tag color="default">
                                                        {value}
                                                    </Tag>
                                                )}
                                                <Text className="moreInfoOption">
                                                    {moreInfo}
                                                </Text>
                                            </div>
                                        </Tooltip>
                                    </Option>
                                )
                            )}
                        </OptGroup>
                    ) : (
                        <Fragment key={groupKey}>
                            {options?.map(
                                (
                                    { icon, label, value, tooltip, moreInfo },
                                    optionKey: number
                                ) => (
                                    <Option key={optionKey} value={value}>
                                        <Tooltip
                                            title={tooltip}
                                            placement="left"
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div style={{ flexGrow: 1 }}>
                                                    <Icons
                                                        icon={validateIconType(
                                                            icon
                                                        )}
                                                    />
                                                    <Text
                                                        style={{
                                                            paddingLeft: "2px",
                                                        }}
                                                    >
                                                        {label}
                                                    </Text>
                                                </div>
                                                {query?.search ===
                                                    "?station" && (
                                                    <Tag color="default">
                                                        {value}
                                                    </Tag>
                                                )}
                                                <Text className="moreInfoOption">
                                                    {moreInfo}
                                                </Text>
                                            </div>
                                        </Tooltip>
                                    </Option>
                                )
                            )}
                        </Fragment>
                    )
            )}
        </Select>
    );
};
export default SelectSearchAsyncOptions;
