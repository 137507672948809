export enum Roles {
  unset = "unset",
  invalid = "invalid",
  agent = "agent",
  travelManager = "travelManager",
  traveller = "traveller",
}

export type State = keyof typeof Roles;

export const SETROLE = "SETROLE";

interface setRole {
  type: typeof SETROLE;
  payload: keyof typeof Roles;
}

export type ActionTypes = setRole;
