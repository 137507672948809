import queryString from "query-string";

export const parseLocationSearch = (
  search: string,
  param: string
): string | undefined => {
  const parsedSearch = search.split("?")[1];
  const query = queryString.parse(parsedSearch);
  const paramValue = query[param];
  if (typeof paramValue === "string") return paramValue;
  if (Array.isArray(paramValue)) return paramValue[0]?.toString();
};
