import React, { FunctionComponent } from "react";
import {
    Row,
    Col,
    Radio,
    Button,
    Image,
} from "@triporate/triporate-design-system";

import { SegmentProps } from "../types";
import TransportInfo from "./TransportInfo";
import TransportDetails from "./TransportDetails";
import { useCanIUseByRole, useRoles } from "../../../../../../hooks";
import "./MainContent.scss";
import { lastTicketDateInfo } from "../../../../../../services/Results/getTransportResults/type";

const MainContent: FunctionComponent<SegmentProps> = ({
    provider,
    segment,
    lastTicketDate,
}) => {
    const [roles] = useRoles();

    const [canTravelAgentUse, loadingCanTravelAgentUse] = useCanIUseByRole(
        roles.agent
    );
    return (
        <Row align="middle">
            <Col span={9}>
                <TransportInfo data={segment.info} />
            </Col>
            <Col span={13}>
                <TransportDetails
                    data={segment.details}
                    provider={provider}
                    canTravelAgentUse={canTravelAgentUse}
                    loadingCanTravelAgentUse={loadingCanTravelAgentUse}
                    lastTicketDate={lastTicketDate}
                />
            </Col>
        </Row>
    );
};

export default MainContent;
