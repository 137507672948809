import AuthToken from "./AuthToken";
import { store } from "../../store";
export default class FetchService extends AuthToken {
  public constructor() {
    super();
  }

  public async get(
    url: string
  ): Promise<ReturnType<FetchService["handleFetch"]>> {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (!url) return { error: true };
    return this.handleFetch(url, config);
  }

  public async post(
    url: string,
    body: BodyInit | null | undefined
  ): Promise<ReturnType<FetchService["handleFetch"]>> {
    const config: RequestInit = {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (typeof body !== "string") delete config.headers;
    if (!url || !body) return { error: true };

    return this.handleFetch(url, config);
  }

  private async handleFetch(
    url: string,
    config: RequestInit
  ): Promise<{
    error?: boolean;
    data?: unknown;
  }> {
    const token = this.getToken();
    const init = {
      ...config,
      headers: {
        ...(config.headers || {}),
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, init);

    const error = !response.ok;

    if (response.status === 401) this.handleLogout();
    if (response.status === 403) window.location.href = "/forbidden";
    if (error) return { error };

    const data: unknown = await response.json();
    return { data };
  }

  protected handleLogout = async (): Promise<void> => {
    const { role } = store.getState();
    const environment = await this.get(`${this.baseUrl}/environment`);
    const { backendURL } = environment.data as { backendURL: string };
    const response = await this.get(`${backendURL}/auth/logout?role=${role}`);

    window.location.href = response.data as string;
  };
}
