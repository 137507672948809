import React, { FunctionComponent } from "react";
import {
  Typography,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";

import { FareData } from "../../types";

const { Text } = Typography;

type SeatsProps = {
  data: FareData["col5"];
};

const Seats: FunctionComponent<SeatsProps> = ({ data }) => (
  <>
    <Text className="icons-right-margin">
      <Icons icon={validateIconType(data.icon)} />
    </Text>
    <Text strong>{data.text1}</Text> <Text>{data.text2}</Text>
  </>
);

export default Seats;
