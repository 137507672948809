import { FetchService } from "../FetchService";

interface Response {
  error?: boolean;
  data?: {
    billingFulfilled: boolean;
    billingFulfillmentEnabled: boolean;
    showFormBtn: string;
    formSrc: string;
  };
}

export const getBillingFulfillmentInfo = async (
  backendUrl: string,
  query: string
) => {
  const fetchService = new FetchService();

  const url = `${backendUrl}/v2/offer/billingFulfillment?${query}`;
  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
};
