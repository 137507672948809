import { FetchService } from "../FetchService";
interface Response {
  error?: boolean;
  data?: any;
}

interface SearchValue {
  query: string;
  office: string;
}

export async function getOfficeInfo(
  url: string,
  searchValue: SearchValue
): Promise<Response> {
  const fetchService = new FetchService();
  //OJO MOCK
  const { data, error } = await await fetchService.get(
    `${url}?geo=${searchValue.query}&office=${searchValue.office}`
  );

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
