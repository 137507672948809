import React, { useEffect } from "react";
import { Spinner } from "@triporate/triporate-design-system";
import { ConfigService } from "../../../../config/configService";

const ConfigLoader = (): JSX.Element => {
  useEffect(() => {
    const configService = new ConfigService();
    configService.setConfig();
  }, []);

  return <Spinner />;
};

export default ConfigLoader;
