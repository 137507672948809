import React, { FunctionComponent, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useFilterResults } from "@triporate/triporate-design-system";
import { AppliedFilters } from "../../../../components/commonTypes";
import { parseLocationSearch } from "../../../../utils";
import { getTransportResults } from "../../../../services/Results";
import LoadingResults from "../LoadingResults";
import NoResultsMessage from "../NoResultsMessage";
import { SegmentId, RequestData, ResultsData } from "./types";
import Context from "./Context";
import Solutions from "./Solutions";
import Ancillaries from "./Ancillaries";
import ConfirmationDrawer from "../ConfirmationDrawer";
import "./TransportResults.scss";

type TransportResultsProps = {
  requestData?: RequestData;
  appliedFilters?: AppliedFilters[];
  appliedOrder?: AppliedFilters;
  setPaymentsRequestData: (url: string, body: string) => void;
  setIsAfterSearch?: (isAfterSearch: boolean) => void;
};

const TransportResults: FunctionComponent<TransportResultsProps> = ({
  requestData,
  appliedFilters = [],
  setPaymentsRequestData,
  appliedOrder,
  setIsAfterSearch
}) => {
  const { search } = useLocation();
  const [searched, setSearched] = useState(false);
  const [results, setResults] = useState<ResultsData["results"]>([]);
  const [noResults, setNoResults] = useState<ResultsData["noResults"]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [cleanAncillaries, setCleanAncillaries] = useState(false);
  const [ancillariesRequestData, setAncillariesRequestData] =
    useState<RequestData | null>(null);
  const [summaryRequestData, setSummaryRequestData] =
    useState<RequestData | null>(null);

  useEffect(() => {
    const handleFetch = async ({ url, body }: RequestData) => {
      setResults([]);
      setSearched(true);
      setLoading(true);
      const { data } = await getTransportResults(url, body);
      if (data) {
        setResults(data.results);
        setNoResults(data.noResults);
        setIsAfterSearch ? setIsAfterSearch(true) : ''
      }
      setLoading(false);
    };

    if (requestData) handleFetch(requestData);
  }, [requestData]);

  const onBuyButtonClick = (
    url: string,
    ids: {
      [key: number]: SegmentId;
    },
    code?: string
  ) => {
    const offerId = parseLocationSearch(search, "offerId");
    const tripId = parseLocationSearch(search, "tripId");
    const body = JSON.stringify({
      offerId,
      journeys: Object.values(ids),
      tripId,
      residentsCode: code,
    });
    setAncillariesRequestData({ url, body });
  };

  const [filteredResults, setAppliedFilters, setAppliedOrder] =
    useFilterResults(results);

  useEffect(() => {
    setAppliedFilters(appliedFilters);
  }, [appliedFilters, setAppliedFilters]);

  useEffect(() => {
    setAppliedOrder(appliedOrder);
  }, [appliedOrder, setAppliedOrder]);

  if (loading) return <LoadingResults />;
  if (searched && !results?.length)
    return <NoResultsMessage message={noResults?.apiMsg} />;
  if (searched && !filteredResults?.length)
    return <NoResultsMessage message={noResults?.filtersMsg} />;

  return (
    <Context.Provider
      value={{
        onBuyButtonClick,
      }}
    >
      <Solutions solutions={filteredResults} />
      <Ancillaries
        requestData={ancillariesRequestData}
        cleanRequestData={() => {
          setAncillariesRequestData(null);
          setSummaryRequestData(null);
        }}
        setPaymentsRequestData={setPaymentsRequestData}
        setSummaryRequestData={(url, body) =>
          setSummaryRequestData({ url, body })
        }
        cleanAncillaries={cleanAncillaries}
        hideAncillaries={() => setCleanAncillaries(false)}
      />
      <ConfirmationDrawer
        requestData={summaryRequestData}
        cleanRequestData={() => {
          setSummaryRequestData(null);
          setCleanAncillaries(true);
        }}
        setPaymentsRequestData={setPaymentsRequestData}
      />
    </Context.Provider>
  );
};

export default TransportResults;
