import React, { FunctionComponent, useState, useEffect } from "react";
import { Divider, Row, Col, Button } from "@triporate/triporate-design-system";
import {
  OtherSolution as OtherSolutionType,
  OtherSolutions as OtherSolutionsType,
} from "../../types";
import OtherSolutionContent from "./OtherSolutionContent";
import "./OtherSolutionContent.scss";

type OtherSolutionsGroupProps = OtherSolutionsType;

const generateShadowClass = (index: number) => {
  const isOdd = index % 2 !== 0;
  if (isOdd) return "grey-background";
  return "";
};

const OtherSolutionsGroup: FunctionComponent<OtherSolutionsGroupProps> = ({
  groupName,
  options,
}): JSX.Element => {
  const [visibleOptions, setVisibleOptions] = useState<typeof options>();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSizeNumber] = useState(3);
  const currentPageNumber = pageNumber * pageSizeNumber;

  useEffect(() => {
    const paginatedOffers = options?.slice(0, currentPageNumber);
    setVisibleOptions(paginatedOffers);
  }, [options, currentPageNumber, setVisibleOptions]);

  const handleNextPage = () => {
    if (options && pageNumber + 1 <= options.length)
      setPageNumber((prevState) => prevState + 1);
  };

  return (
    <Row>
      <Divider orientation="left" plain></Divider>
      <Col span={24}>
        <Row>
          {visibleOptions?.map((option: OtherSolutionType, key) => (
            <Col key={key} span={24} className={generateShadowClass(key)}>
              <OtherSolutionContent
                index={key}
                option={option}
                groupName={groupName}
              />
            </Col>
          ))}
          {visibleOptions?.length !== options.length && (
            <Col className="center-content more-options" span={24}>
              <Button onClick={handleNextPage}>Más Opciones</Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};
export default OtherSolutionsGroup;
