import { SelfBookingToolData } from "./types";

const selfBookingToolMockData: SelfBookingToolData = {
  header: {
    isEstimate: false,
    mode: "online",
    label: "¿Qué necesitas?",
    basket: {
      basketLabel: "Finalizar Pedido",
      itinerary: {
        href: "",
      },
    },
    textModalLogoutWithoutSaving:
      "¿Desea que se le envie el itinerario antes de salir?",
    options: [
      {
        icon: {
          class: "flight",
        },
        label: "Flights",
        href: "flight-form",
        type: "flight",
      },
      {
        icon: {
          class: "hotel",
        },
        label: "Hotels",
        href: "hotel-form",
        type: "hotel",
      },
      {
        icon: {
          class: "car",
        },
        label: "Cars",
        href: "car-form",
        type: "car",
      },
    ],
    company: {
      name: "Random",
      logo: "https://res.cloudinary.com/chalo/image/upload/v1536848019/webassets/brand-box.png",
      alt: "Company",
      agency: {
        name: "Random",
        logo: "https://res.cloudinary.com/chalo/image/upload/v1627460688/logos/brand-triporate-horizontal-rgb-72dpi.png",
        alt: "Agency",
      },
    },
    user: {
      name: "Utonio",
    },
    logout: { icon: "logout" },
    help: {
      phone: {
        text: "Para cualquier duda sobre tu viaje, puedes contactarnos al número {phone}.",
        pattern: "{phone}",
        value: "+34 910 86 76 85",
      },
      schedule: {
        text: "En el horario de:",
        items: [
          "Lunes, Martes, Miercoles y Jueves de 09:00 a 19:00.",
          "Viernes de 09:00 a 17:00.",
        ],
      },
      icon: "info",
    },
  },
  summary: {
    href: "hotel-form",
  },
  role: "travelManager",
  sideBar: [{ tabName: "Filtros", key: "filters" }],
  offerId: "mock-offer-id",
};

export default selfBookingToolMockData;
