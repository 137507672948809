import { Row, Col, Select, Radio, Space, Tooltip, Tag, Form } from '@triporate/triporate-design-system';
import React from 'react'
import { Typography } from "antd";
import { CheckOutlined, CloseOutlined, DingdingOutlined } from '@ant-design/icons';
import { getSelectOptions } from '../../../services/SearchForm/getSelectOptions';
import { FetchService } from '../../../services/FetchService';

type Props = {
    onChange?: (values: any) => void
}

const { Title } = Typography

const { Option } = Select

const AirlinesFormComponents: React.FC<Props> = ({ onChange }) => {

    const [currentSelect, setCurrentSelect] = React.useState<string>('');
    const [formData, setFormData] = React.useState<any>({});
    const [options, setOptions] = React.useState<any>({
        p1: [], p2: [], p3: [],
        e1: [], e2: [], e3: []
    });

    React.useEffect(() => {
        if (!onChange) return

        const companies: any = {
            add: [],
            avoid: []
        }

        Object.keys(formData).map((index: string) => {

            if (!formData[index]) return

            if (['e1', 'e2', 'e3'].includes(index)) {
                companies.avoid.push(formData[index])
            } else {
                companies.add.push(formData[index])
            }
        })

        onChange({
            companies
        })

    }, [formData])

    const fetchOptions = async (value: any) => {
        if (!value) return;

        const fetchService = new FetchService()

        const url = fetchService.baseUrl + '/location/flight/airline?q=' + value

        const {data}: any = await fetchService.get(url)

        if (!data?.options) return;

        const aux: any = {}

        aux[currentSelect] = data.options.map((item: any) => ({
            value: item.value,
            label: item.label,
            shortLabel: item.value
        }))

        // Suponiendo que la respuesta sea un array de objetos con id y nombre
        setOptions((prevState: any) => ({ ...prevState, ...aux }));
    };

    const handleSearch = (selectName: string, value: any) => {

        setCurrentSelect(selectName)

        debouncedFetchOptions(value);
    };

    const debounce = (fn: Function, ms = 300) => {
        let timeoutId: ReturnType<typeof setTimeout>;
        return function (this: any, ...args: any[]) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => fn.apply(this, args), ms);
        };
    };

    // Utilizamos debounce para limitar las peticiones mientras el usuario escribe
    const debouncedFetchOptions = React.useCallback(debounce(fetchOptions, 300), [currentSelect]);

    return (
        <Form
            initialValues={{ remember: true }}
            autoComplete="off"
            onValuesChange={(values, allValues) => setFormData(allValues)}>
            <div style={{ margin: 10 }}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Space>
                            <Title style={{ color: '#52c41a' }} level={5}><CheckOutlined /></Title>
                            <Title level={5}>Preferencias</Title>
                        </Space>

                        <Form.Item name="p1" style={{ marginBottom: 10 }} >
                            <Select
                                showSearch
                                allowClear
                                filterOption={false}
                                placeholder="Aerolínea 1"
                                onSearch={(e) => handleSearch("p1", e)}>
                                {
                                    options['p1'].map((option: { value: string, label: string, shortLabel: string }, index: number) => (
                                        <Option
                                            key={index}
                                            value={option.value}>
                                            <Row justify={'space-between'}>
                                                {option.label}
                                                <Tag color="default">
                                                    {option.shortLabel}
                                                </Tag>
                                            </Row>
                                        </Option>))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item name="p2" style={{ marginBottom: 10 }} >
                            <Select
                                showSearch
                                allowClear
                                filterOption={false}
                                placeholder="Aerolínea 2"
                                onSearch={(e) => handleSearch("p2", e)}>
                                {
                                    options['p2'].map((option: { value: string, label: string, shortLabel: string }, index: number) => (
                                        <Option
                                            key={index}
                                            value={option.value}>
                                            <Row justify={'space-between'}>
                                                {option.label}
                                                <Tag color="default">
                                                    {option.shortLabel}
                                                </Tag>
                                            </Row>
                                        </Option>))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item name="p3" style={{ marginBottom: 10 }} >
                            <Select
                                showSearch
                                allowClear
                                filterOption={false}
                                placeholder="Aerolínea 3"
                                onSearch={(e) => handleSearch("p3", e)}>
                                {
                                    options['p3'].map((option: { value: string, label: string, shortLabel: string }, index: number) => (
                                        <Option
                                            key={index}
                                            value={option.value}>
                                            <Row justify={'space-between'}>
                                                {option.label}
                                                <Tag color="default">
                                                    {option.shortLabel}
                                                </Tag>
                                            </Row>
                                        </Option>))
                                }
                            </Select>
                        </Form.Item>

                    </Col>
                    <Col span={8}>
                        <Space>
                            <Title style={{ color: '#f5222d' }} level={5}><CloseOutlined /></Title>
                            <Title level={5}>Evitar</Title>
                        </Space>
                        <Form.Item name="e1" style={{ marginBottom: 10 }}>
                            <Select
                                showSearch
                                allowClear
                                filterOption={false}
                                placeholder="Aerolínea 1"
                                onSearch={(e) => handleSearch('e1', e)}
                                options={options["e1"] || []}>
                                {
                                    options['e1'].map((option: { value: string, label: string, shortLabel: string }, index: number) => (
                                        <Option
                                            key={index}
                                            value={option.value}>
                                            <Row justify={'space-between'}>
                                                {option.label}
                                                <Tag color="default">
                                                    {option.shortLabel}
                                                </Tag>
                                            </Row>
                                        </Option>))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item name="e2" style={{ marginBottom: 10 }}>
                            <Select
                                showSearch
                                allowClear
                                filterOption={false}
                                placeholder="Aerolínea 2"
                                onSearch={(e) => handleSearch('e2', e)}
                                options={options["e2"] || []}>
                                {
                                    options['e2'].map((option: { value: string, label: string, shortLabel: string }, index: number) => (
                                        <Option
                                            key={index}
                                            value={option.value}>
                                            <Row justify={'space-between'}>
                                                {option.label}
                                                <Tag color="default">
                                                    {option.shortLabel}
                                                </Tag>
                                            </Row>
                                        </Option>))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item name="e3" style={{ marginBottom: 10 }}>
                            <Select
                                showSearch
                                allowClear
                                filterOption={false}
                                placeholder="Aerolínea 3"
                                onSearch={(e) => handleSearch('e3', e)}
                                options={options["e3"] || []}>
                                {
                                    options['e3'].map((option: { value: string, label: string, shortLabel: string }, index: number) => (
                                        <Option
                                            key={index}
                                            value={option.value}>
                                            <Row justify={'space-between'}>
                                                {option.label}
                                                <Tag color="default">
                                                    {option.shortLabel}
                                                </Tag>
                                            </Row>
                                        </Option>))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Space style={{ marginLeft: 30 }} direction='vertical'>
                            <Space>
                                <Title level={5}><DingdingOutlined /></Title>
                                <Title level={5}>Alianzas</Title>
                            </Space>
                            <Form.Item name="alliance" style={{ marginBottom: 10 }}>
                                <Radio.Group>
                                    <Radio value={false}>Sin definir</Radio>
                                    <Radio value="*O">One world</Radio>
                                    <Radio value="*S">Star Team</Radio>
                                    <Radio value="*A">Star Aliance</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </div>
        </Form>
    )
}

export default AirlinesFormComponents