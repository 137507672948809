import { useEffect, useRef, useState } from "react";

// DOCS:
// https://non-traditional.dev/how-to-use-an-intersectionobserver-in-a-react-hook-9fb061ac6cb5
// https://codesandbox.io/s/04vvrxj79p

const useIsVisible = (): [(node: Element | null) => void, boolean] => {
  const [entry, updateEntry] = useState<{ intersectionRatio: number }>({
    intersectionRatio: 0,
  });
  const [node, setNode] = useState<Element | null>(null);

  const observer = useRef(
    new window.IntersectionObserver(([entry]) => updateEntry(entry), {
      root: null,
    })
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (node) currentObserver.observe(node);

    return () => currentObserver.disconnect();
  }, [node]);

  return [setNode, entry.intersectionRatio > 0];
};

export default useIsVisible;
