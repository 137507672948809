import React, { FunctionComponent } from "react";
import { Button, Modal, Typography } from "@triporate/triporate-design-system";

import { OptionData } from "../types";
import EmailLink from "./EmailLink";

const { Paragraph, Text } = Typography;

type OptionModalProps = {
  data?: OptionData;
  closeModal: () => void;
};

const OptionModal: FunctionComponent<OptionModalProps> = ({
  data,
  closeModal,
}): JSX.Element => {
  const handleCloseModal = () => {
    closeModal();
  };

  const handleCloseDiscovery = () => {
    closeModal();
    window.close();
  };

  return (
    <Modal
      visible={!!data}
      title={data?.title}
      onCancel={handleCloseModal}
      onOk={handleCloseModal}
      footer={[
        <Button
          key="link"
          href={data?.offer.href}
          target="_blank"
          onClick={handleCloseModal}
        >
          {data?.offer.buttonOffer}
        </Button>,
        <Button key="back" onClick={handleCloseModal}>
          {data?.buttonAdd}
        </Button>,
        <Button key="submit" type="primary" onClick={handleCloseDiscovery}>
          {data?.buttonClose}
        </Button>,
      ]}
    >
      <Paragraph>{data?.subtitle}</Paragraph>
      <Paragraph>
        <Text>{data?.text}</Text>
        <EmailLink data={data?.email} />
      </Paragraph>
    </Modal>
  );
};

export default OptionModal;
