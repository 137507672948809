import React, { FunctionComponent } from "react";
import { Row, Col, Space } from "@triporate/triporate-design-system";
import AncillariesTabs from "./AncillariesTabs";
import AncillariesContent from "./AncillariesContent";
import AncillariesTotalPrice from "./AncillariesTotalPrice";
import LoadingBody from "./LoadingBody";
import {
  BodyData,
  SelectedFare,
  SelectedPriority,
  SelectedBaggage,
  SelectedBaggagePrice,
  SelectedId,
  SelectedIdBag,
} from "./types";
import { Addons } from "../../../../../../services/Results/getTransportAncillaries/types";

type BodyProps = {
  data?: BodyData;
  loading: boolean;
  selectedFare: SelectedFare;
  setSelectedFare: (value: SelectedFare) => void;
  selectedPriority: SelectedPriority;
  setSelectedPriority: (value: SelectedPriority) => void;
  selectedBaggage: SelectedBaggage;
  setSelectedBaggage: (value: SelectedBaggage) => void;
  selectedTab: string;
  setSelectedTab: (value: string) => void;
  selectedBaggagePrice: SelectedBaggagePrice;
  setSelectedBaggagePrice: (value: SelectedBaggagePrice) => void;
  selectedId: SelectedId;
  setSelectedId: (value: SelectedId) => void;
  addons: Addons;
  selectedIdBag: SelectedIdBag;
  setSelectedIdBag: (value: SelectedIdBag) => void;
};

const Body: FunctionComponent<BodyProps> = ({
  data,
  loading,
  selectedFare,
  setSelectedFare,
  selectedPriority,
  setSelectedPriority,
  selectedBaggage,
  setSelectedBaggage,
  selectedTab,
  setSelectedTab,
  selectedBaggagePrice,
  setSelectedBaggagePrice,
  selectedId,
  setSelectedId,
  addons,
  selectedIdBag,
  setSelectedIdBag,
}) => {
  if (loading) return <LoadingBody />;

  if (!data) return <></>;

  return (
    <>
      <Space direction="vertical" className="space-witdh-100" size="large">
        <Row align="middle" justify="space-between">
          <Col span={24}>
            <AncillariesTabs
              data={data.tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </Col>
          <Col span={24}>
            <AncillariesContent
              selectedTab={selectedTab}
              faresData={data.fares}
              selectedFare={selectedFare}
              setSelectedFare={setSelectedFare}
              selectedPriority={selectedPriority}
              setSelectedPriority={setSelectedPriority}
              selectedBaggage={selectedBaggage}
              setSelectedBaggage={setSelectedBaggage}
              selectedBaggagePrice={selectedBaggagePrice}
              setSelectedBaggagePrice={setSelectedBaggagePrice}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              addons={addons}
              selectedIdBag={selectedIdBag}
              setSelectedIdBag={setSelectedIdBag}
            />
          </Col>
        </Row>
      </Space>
      <AncillariesTotalPrice
        data={data.price}
        selectedFare={selectedFare}
        selectedPriority={selectedPriority}
        selectedBaggage={selectedBaggage}
      />
    </>
  );
};

export default Body;
