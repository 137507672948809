import { useState, useEffect } from "react";

function usePagination<T>(
  data?: T[],
  pageSize?: number
): [T[] | null, () => void] {
  const [visibleOptions, setVisibleOptions] = useState<T[] | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const currentPageNumber = pageNumber * (pageSize || 5);

  useEffect(() => {
    const paginatedOffers = data?.slice(0, currentPageNumber);
    if (paginatedOffers) setVisibleOptions(paginatedOffers);
  }, [data, currentPageNumber, setVisibleOptions]);

  const handleNextPage = () => {
    if (data && pageNumber + 1 <= data.length)
      setPageNumber((prevState) => prevState + 1);
  };

  return [visibleOptions, handleNextPage];
}

export default usePagination;
