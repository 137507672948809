import React, { FunctionComponent } from "react";
import { Modal } from "@triporate/triporate-design-system";

import { useWindowMessagesHandler } from "../../hooks";

import "./IframeModal.scss";

type ModalProps = {
  visible: boolean;
  closeModal: () => void;
  closeMessage: string;
  redirectModal: () => void;
  redirectMessage: string;
  src?: string;
};

const IframeModal: FunctionComponent<ModalProps> = ({
  visible,
  closeModal,
  closeMessage,
  redirectModal,
  redirectMessage,
  src,
}): JSX.Element => {
  useWindowMessagesHandler([
    {
      message: closeMessage,
      messageHandler: closeModal,
    },
    {
      message: redirectMessage,
      messageHandler: redirectModal,
    },
  ]);

  return (
    <Modal
      className="iframe-modal"
      visible={!!(src && visible)}
      onCancel={closeModal}
    >
      <iframe src={src} />
    </Modal>
  );
};

export default IframeModal;
