import React, { FunctionComponent, useState } from "react";
import {
  Typography,
  Icons,
  validateIconType,
  Button,
  Spin,
} from "@triporate/triporate-design-system";

import { getCancellationDate } from "../../../../../../../services/CancellationDate";

const { Text, Paragraph } = Typography;

type CancellationDateProps = {
  url: string;
  icon: string;
};

const CancellationDate: FunctionComponent<CancellationDateProps> = ({
  url,
  icon,
}): JSX.Element => {
  const [cancellationDate, setCancellationDate] = useState<string>();
  const [loading, setLoading] = useState(false);

  const cancellationDateFetch = async (url: string) => {
    setLoading(true);

    const { data } = await getCancellationDate(url);
    if (data) {
      setCancellationDate(data.cancellationDate);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <Button
          type="link"
          onClick={() => cancellationDateFetch(url)}
          className="cancellationDate_button"
        >
          <Icons icon={validateIconType(icon)} />
        </Button>
      ) : (
        <Spin size="small" className="cancellationDate_spiner" />
      )}

      {cancellationDate && (
        <Paragraph>
          <Text>{cancellationDate} </Text>
        </Paragraph>
      )}
    </>
  );
};

export default CancellationDate;
