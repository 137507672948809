import React, { FunctionComponent } from "react";
import { Row, Col, Button } from "@triporate/triporate-design-system";
import { FooterData } from "../types";
import LoadingFooter from "./LoadingFooter";

type FooterProps = {
  closeDrawer: () => void;
  handleRequestData: () => void;
  data?: FooterData;
  loading: boolean;
};

const Footer: FunctionComponent<FooterProps> = ({
  closeDrawer,
  handleRequestData,
  data,
  loading,
}) => {
  if (loading) return <LoadingFooter />;

  if (!data) return <></>;

  return (
    <Row justify="end" gutter={12}>
      <Col>
        <Button type="default" onClick={closeDrawer}>
          {data.btn1.text}
        </Button>
      </Col>
      <Col>
        <Button id="add-button" type="primary" onClick={handleRequestData}>
          {data.btn2.text}
        </Button>
      </Col>
    </Row>
  );
};

export default Footer;
