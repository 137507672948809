import { FetchService } from "../FetchService";
import mockData from "./errorPageMockData";
import { ErrorPageData } from "./types";

interface Response {
  error?: boolean;
  data?: ErrorPageData;
}

const generateMockData = () => mockData;

export async function getErrorPageData(
  errorCode: string | number
): Promise<Response> {
  const fetchService = new FetchService();

  if (fetchService.useFakeApi) {
    const data = generateMockData();
    return { data };
  }
  if (!fetchService.baseUrl) return { error: true };

  const environment = await fetchService.get(
    `${fetchService.baseUrl}/environment`
  );

  const { backendURL } = environment.data as { backendURL: string };
  if (!backendURL) return { error: true };

  const { data } = await fetchService.get(
    backendURL + `/errorPages?errorCode=${errorCode}`
  );
  const castedData = data as Response["data"];
  return { data: castedData };
}
