import React, { FunctionComponent } from "react";
import {
  Button,
  Icons,
  Tooltip,
  validateIconType,
} from "@triporate/triporate-design-system";

type LogoutProps = {
  data: string;
  handleLogout: () => void;
};

const Logout: FunctionComponent<LogoutProps> = ({
  data,
  handleLogout,
}): JSX.Element => (
  <div className="logout-div">
    <Tooltip title="Logout">
      <Button
        type="link"
        onClick={handleLogout}
        className="header-button logout-button"
      >
        <Icons icon={validateIconType(data)} />
      </Button>
    </Tooltip>
  </div>
);

export default Logout;
