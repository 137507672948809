import React, { FunctionComponent, useCallback, useState } from "react";
import { Row, Col, TripoCard } from "@triporate/triporate-design-system";

import Journeys from "../Journeys";
import BuyInfo from "./BuyInfo";

import { SolutionData, SegmentId } from "../types";

type SolutionProps = {
    solution: SolutionData;
};

const Solution: FunctionComponent<SolutionProps> = ({ solution }) => {
    const [opendedSegmentIdDictionary, setOpendedSegmentIdDictionary] =
        useState<{
            [key: string]: SegmentId;
        }>({});
    const [selectedSegmentsIds, setSelectedSegmentsIds] = useState<{
        [key: number]: SegmentId;
    }>({});

    const handleSelectedSegment = useCallback(
        (key: number, id: SegmentId) => {
            setSelectedSegmentsIds((prevState) => ({
                ...prevState,
                [key]: id,
            }));
        },
        [setSelectedSegmentsIds]
    );

    const handleOpendedSegmentId = useCallback(
        (key: number, id?: SegmentId) => {
            setOpendedSegmentIdDictionary((prevState) => ({
                ...prevState,
                [key]: id,
            }));
        },
        [setOpendedSegmentIdDictionary]
    );

    return (
        <Col span={24}>
            <TripoCard hoverable shadow bodyStyle={{ padding: 0 }}>
                <Col span={24}>
                    <Row className="transport-solution">
                        <Col className="transport-journey" span={21}>
                            <Journeys
                                journeys={solution.journeys}
                                opendedSegmentIdDictionary={
                                    opendedSegmentIdDictionary
                                }
                                setOpendedSegmentIdDictionary={
                                    handleOpendedSegmentId
                                }
                                selectedSegmentsIds={selectedSegmentsIds}
                                setSelectedSegment={handleSelectedSegment}
                            />
                        </Col>
                        <Col className="transport-buy-button" span={3}>
                            <BuyInfo
                                data={solution.buyButton}
                                provider={solution.provider?.src}
                                selectedSegmentsIds={selectedSegmentsIds}
                                lastTicketDate={solution.lastTicketDate}
                                isResidentCandidate={
                                    solution.isResidentCandidate
                                }
                                label={solution.provider.label}
                            />
                        </Col>
                    </Row>
                </Col>
            </TripoCard>
        </Col>
    );
};

export default Solution;
