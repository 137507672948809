import React, { FunctionComponent } from "react";
import { Row, Col, Space } from "@triporate/triporate-design-system";
import LoadingBody from "./LoadingBody";
import CarJourneyInfo from "./CarJourneyInfo";
import ExtraInfo from "./ExtraInfo";
import HotelInfo from "./HotelInfo";
import PaymentInfo from "./PaymentInfo";
import TrainJourneyInfo from "./TrainJourneyInfo";
import { BodyData } from "./types";
import { SummaryNotes } from "../types";

import "../ConfirmationDrawer.scss";
import HotelImageList from "./HotelInfo/HotelImageList";

type BodyProps = {
    data?: BodyData;
    loading: boolean;
    handleNotes: (data: SummaryNotes) => void;
    handleHotelNotes?: (data: SummaryNotes) => void;
    handleSelectedImage?: (data: SummaryNotes) => void;
};

const Body: FunctionComponent<BodyProps> = ({
    data,
    loading,
    handleNotes,
    handleHotelNotes = () => undefined,
    handleSelectedImage = () => undefined,
}) => {
    if (loading) return <LoadingBody />;

    return (
        <>
            <Row align="top" className="confirmationDrawer-top_row">
                <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: "flex" }}
                >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ display: "flex" }}
                    >
                        {data?.transportJourneys?.map((journeyData, i) => (
                            <Col key={i} span={24}>
                                <TrainJourneyInfo data={journeyData} />
                            </Col>
                        ))}
                    </Space>
                    {data?.carJourneys?.map((journeyData, i) => (
                        <Col key={i} span={24}>
                            <CarJourneyInfo data={journeyData} />
                        </Col>
                    ))}

                    {data?.hotelData && (
                        <Col span={24}>
                            <HotelInfo
                                data={data?.hotelData}
                                handleHotelNotes={handleHotelNotes}
                            />
                        </Col>
                    )}

                    {data?.extraInfo && (
                        <Col span={24}>
                            <ExtraInfo
                                data={data.extraInfo}
                                handleNotes={handleNotes}
                                addonsData={data.addonsInfo}
                            />
                        </Col>
                    )}

                    {data?.hotelData &&
                        data?.hotelData.images.imageList.length > 0 && (
                            <Col span={24}>
                                <HotelImageList
                                    data={data.hotelData.images}
                                    handleSelectedImage={handleSelectedImage}
                                />
                            </Col>
                        )}
                </Space>
            </Row>

            <Row className="confirmationDrawer-bottom_row">
                {data?.paymentInfo && (
                    <Col span={24}>
                        <PaymentInfo
                            data={data?.paymentInfo}
                            isRefreshing={loading}
                        />
                    </Col>
                )}
            </Row>
        </>
    );
};

export default Body;
