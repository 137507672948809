import { FetchService } from "../../FetchService";
import { flightDetails, trainDetails } from "./mockData";
import { DetailsData } from "./type";

interface Response {
  error?: boolean;
  data?: DetailsData;
}

const handleMockData = (key: string): Response => {
  let data;
  let error = false;
  switch (true) {
    case key.includes("flight-details"):
      data = flightDetails;
      break;
    case key.includes("train-details"):
      data = trainDetails;
      break;
    default:
      error = true;
  }

  return { data, error };
};

export async function getResultDetails(
  url: string,
  body: string
): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData(url);

  const { data, error } = await fetchService.post(url, body);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
