import React, { FunctionComponent } from "react";
import {
  Drawer,
  MessageItem,
  useWindowMessagesHandler,
} from "@triporate/triporate-design-system";
// import {
//   useWindowMessagesHandler,
//   MessageItem,
// } from "@triporate/triporate-design-system/dist/utils/hooks";

type IframeModalProps = {
  closable: boolean;
  visible: boolean;
  src: string;
  closeModal: () => void;
  messageItem: MessageItem[];
  className?: string;
};

const IframeModal: FunctionComponent<IframeModalProps> = ({
  closable,
  visible,
  closeModal,
  src,
  messageItem,
  className = "", //iframe-modal
}): JSX.Element => {
  useWindowMessagesHandler(messageItem);
  return (
    <Drawer
      className={className}
      closable={closable}
      visible={visible}
      onClose={closeModal}
      width={800}
      mask
      maskClosable={false}
      maskStyle={{ background: "transparent", width: 0 }}
    >
      <iframe
        src={src}
        style={{ width: "100%", height: "100%", border: "none" }}
      />
    </Drawer>
  );
};

export default IframeModal;
