import { FetchService } from "../../FetchService";

type OptionData = {
  buttonAdd: string;
  buttonClose: string;
  subtitle: string;
  text: string;
  email: {
    icon: string;
    href: string;
    tripNumber: string;
  };
  offer: {
    buttonOffer: string;
    href: string;
  };
  title: string;
};

interface Response {
  error?: boolean;
  data?: OptionData;
}

const generateMockData = () => ({
  title: "Nueva opción añadida con éxito",
  subtitle: "Has añadido 1 train",
  buttonAdd: "Añade más opciones",
  buttonClose: "Cerrar discovery",
  text: "Ahora tienes 0 Hotel, 1 Vuelos 5 Trenes",
  email: {
    icon: "envelope",
    href: "undefined/offers/email/8e7d57b0-7717-40c0-bffb-146ac048a563?tripId=5779",
    tripNumber: "919094",
  },
  offer: {
    buttonOffer: "Ir a offers",
    href: "undefined/agent/offerv2?offerId=8e7d57b0-7717-40c0-bffb-146ac048a563",
  },
});

export async function addOption(url: string, body: string): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return { data: generateMockData() };

  const { data, error } = await fetchService.post(url, body);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
