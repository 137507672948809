import React, { FunctionComponent } from "react";
import {
  Button,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";

import "./CarInputs.scss";

type OfficeInfoBtnProps = {
  value: string;
  handleFetch: (office: string, url?: string) => void;
  url?: string;
  icon?: string;
};

const OfficeInfoBtn: FunctionComponent<OfficeInfoBtnProps> = ({
  value,
  handleFetch,
  url,
  icon,
}): JSX.Element => {
  return (
    <Button
      className="form-button"
      disabled={!value}
      onClick={() => handleFetch(value, url)}
    >
      <Icons icon={validateIconType(icon)} />
    </Button>
  );
};

export default OfficeInfoBtn;
