import React, { FunctionComponent } from "react";

import TransportResults from "./TransportResults";
import HotelResults from "./HotelResults";
import CarResults from "./CarResults";
import { ValidTypes } from "../../validTypes";
import { AppliedFilters } from "../../../components/commonTypes";
import { AppliedOrder } from "@triporate/triporate-design-system/dist/components/utils/FiltersUI/type";
import SubResultsSwitch from "./SubResultsSwitch";

type RequestData = {
    url: string;
    body: string;
};

type ResultsSwitchProps = {
    type?: string;
    requestData?: RequestData;
    appliedFilters: AppliedFilters[];
    setPaymentsRequestData: (url: string, body: string) => void;
    appliedOrder?: AppliedOrder;
    setIsAfterSearch?: (isAfterSearch: boolean) => void;
};

const ResultsSwitch: FunctionComponent<ResultsSwitchProps> = ({
    type,
    requestData,
    appliedFilters,
    setPaymentsRequestData,
    appliedOrder,
    setIsAfterSearch,
}) => {
    switch (type) {
        case ValidTypes.flight:
            return (
                <TransportResults
                    appliedFilters={appliedFilters}
                    key={ValidTypes.flight}
                    requestData={requestData}
                    setPaymentsRequestData={setPaymentsRequestData}
                    appliedOrder={appliedOrder}
                    setIsAfterSearch={setIsAfterSearch}
                />
            );
        case ValidTypes.train:
            return (
                <SubResultsSwitch
                    key={ValidTypes.train}
                    requestData={requestData}
                    appliedFilters={appliedFilters}
                    setPaymentsRequestData={setPaymentsRequestData}
                    setIsAfterSearch={setIsAfterSearch}
                />
            );
        case ValidTypes.hotel:
            return (
                <HotelResults
                    requestData={requestData}
                    appliedFilters={appliedFilters}
                    setPaymentsRequestData={setPaymentsRequestData}
                    appliedOrder={appliedOrder}
                />
            );
        case "car":
            return (
                <CarResults
                    requestData={requestData}
                    appliedFilters={appliedFilters}
                    setPaymentsRequestData={setPaymentsRequestData}
                />
            );
        default:
            return <> </>;
    }
};

export default ResultsSwitch;
