import React, { FunctionComponent } from "react";
import { UnifiedFilters, Skeleton } from "@triporate/triporate-design-system";
import Articles from "../Articles";
import { getSelectSearchAsyncOptions } from "../../../services/SearchForm";
import "../AgentsBookingTool.scss";
import { TabType } from "./types";
import { Filters } from "../../../services/SearchForm/getInputsData/types";

type SideBarContentProps = {
  filtersConfig: Filters[];
  selectedTab: string;
  url?: string;
};

const SideBarContent: FunctionComponent<SideBarContentProps> = ({
  filtersConfig,
  selectedTab,
  url,
}): JSX.Element => {
  switch (selectedTab) {
    case TabType.filters:
      if (filtersConfig.length === 0) {
        return <Skeleton paragraph={{ rows: 2 }} active />;
      } else {
        return (
          <UnifiedFilters
            getSelectSearchAsyncOptions={getSelectSearchAsyncOptions}
          />
        );
      }
    case TabType.ticketInfo:
    default:
      return <Articles url={url} />;
  }
};

export default SideBarContent;
