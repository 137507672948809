import { FetchService } from "../FetchService";
import data from "./getTravellersFormIFrame.json";
import { TravellersFormIFrame } from "./types";

interface Response {
  error?: boolean;
  data?: TravellersFormIFrame;
}

const handleMockData = (): Response => {
  const { createSrc, updateSrc } = data;
  return { data: { createSrc, updateSrc } };
};

async function getTravellersFormSrc(url: string): Promise<Response> {
  const fetchService = new FetchService();

  if (fetchService.useFakeApi) return handleMockData();

  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}

export default getTravellersFormSrc;
