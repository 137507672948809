import React, { FunctionComponent } from "react";
import { Row } from "@triporate/triporate-design-system";
import { usePagination } from "../../../../../hooks";
import NextResults from "../../NextResults";
import { ResultsData, SegmentId } from "../types";

import Solution from "./Solution";

export interface SetSegment {
  [key: number]: SegmentId;
}
type SolutionsProps = {
  solutions?: ResultsData["results"];
};

const Solutions: FunctionComponent<SolutionsProps> = ({ solutions }) => {
  const [visibleOptions, handleNextPage] = usePagination(solutions);

  return (
    <Row className="transport-solutions" gutter={[0, 24]}>
      {visibleOptions?.map((solution, key) => (
        <Solution key={key} solution={solution} />
      ))}
      <NextResults
        showSpinner={!(solutions?.length === visibleOptions?.length)}
        nextPage={handleNextPage}
      />
    </Row>
  );
};

export default Solutions;
