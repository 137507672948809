import { FetchService } from "../FetchService";

import {
  addressOptions,
  branchOptions,
  poiOptions,
  userOptions,
  kmOptions,
  travellersOptions,
  flightTripTypeOptions,
  trainTripTypeOptions,
} from "./optionsMockData";

type Option = {
  label: string;
  value: string | number;
  tooltip?: string;
  disabled?: boolean;
  icon?: string;
  moreInfo?: string;
  city?: string;
};

type OptionsGroup = {
  groupName?: string;
  options: Option[];
};

interface Response {
  error?: boolean;
  data?: OptionsGroup[];
}

const handleMockData = (key: string): Response => {
  let data;
  let error = false;
  switch (true) {
    case key.includes("address-options"):
      data = addressOptions;
      break;
    case key.includes("poi-options"):
      data = poiOptions;
      break;
    case key.includes("user-options"):
      data = userOptions;
      break;
    case key.includes("km-options"):
      data = kmOptions;
      break;
    case key.includes("travellers-options"):
      data = travellersOptions;
      break;
    case key.includes("flight-trip-type-options"):
      data = flightTripTypeOptions;
      break;
    case key.includes("train-trip-type-options"):
      data = trainTripTypeOptions;
      break;
    case key.includes("branch-options"):
      data = branchOptions;
      break;

    default:
      error = true;
  }

  return { data, error };
};

export async function getSelectOptions(url: string): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData(url);

  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
