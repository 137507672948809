import React, { FunctionComponent, useEffect, useState } from "react";
import { ErrorPageTemplate } from "@triporate/triporate-design-system";
import { getErrorPageData } from "../../services/ErrorPage";
import { Response as ResponseType } from "./types";
import { ExceptionStatusType } from "../../services/ErrorPage/types";

type ErrorPageProps = {
  errorCode: ExceptionStatusType;
};

const ErrorPage: FunctionComponent<ErrorPageProps> = ({
  errorCode,
}): JSX.Element => {
  const [ErrorData, setErrorData] = useState<ResponseType["data"]>({
    code: errorCode,
    text: `Ups! Error ${errorCode}`,
    callToActionText: "",
    callToActionURL: "#",
  });

  useEffect(() => {
    async function handleFetch() {
      const { data } = await getErrorPageData(errorCode.toString());

      if (!data) return;
      setErrorData(data);
    }

    handleFetch();
  }, [errorCode]);

  return ErrorData ? <ErrorPageTemplate errorPageInfo={ErrorData} /> : <></>;
};

export default ErrorPage;
