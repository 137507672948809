import { ResidentCandidateModalInfo } from "../../../components/Results/ResultsSwitch/TransportResults/types";
import { FetchService } from "../../FetchService";

interface Response {
  error?: boolean;
  data?: ResidentCandidateModalInfo;
}

const generateMockData = () => ({
  title: "Residente en islas",
  text: "¿Quieres que se aplique el descuento para los pasajeros residentes en",
  code: "BL",
  codeName: "Baleares",
  note: "Recuerda que los perfiles de viajero no se tienen en cuenta en este proceso.",
  btnNo: "No, continuar sin aplicar",
  btnYes: "Si, aplicar descuento",
});

export async function getResidentsModal(url: string): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return { data: generateMockData() };

  const { data, error } = await fetchService.get(url);
  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
