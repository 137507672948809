import moment from "moment";
import { Fields } from "../../types";
import { CommonInputFields, ValidInputGroups } from "../constants";

const carValuesFormater = (key: string, formatedValue: unknown[]) => {
  if (key !== CommonInputFields.users) return formatedValue;
  if (Array.isArray(formatedValue)) return [formatedValue[0]];
  return [formatedValue];
};

const formatBody = (
  data: Fields,
  type: string,
  momentArray?: boolean
): Fields =>
  Object.entries(data).reduce((body, [key, value]) => {
    let formatedValue = value;
    const isCarType = type === ValidInputGroups.car;

    if (formatedValue?.value) formatedValue = value?.value;

    if (isCarType) formatedValue = carValuesFormater(key, formatedValue);

    if (Array.isArray(formatedValue))
      formatedValue = formatedValue.map((value) => {
        if (isCarType && moment.isMoment(value))
          return `${value.format("YYYY-MM-DD")} ${value.format("HH:mm")}:00`;
        if (moment.isMoment(value)) return value;
        if (value?.value) return value?.value;
        if (typeof value === "object") return formatBody(value, type, true);
        return value;
      });

    if (momentArray && moment.isMoment(formatedValue)) formatedValue = [value];

    return { ...body, [key]: formatedValue };
  }, {});

export default formatBody;
