import React, { FunctionComponent } from "react";
import { Row, Col, Button } from "@triporate/triporate-design-system";
import LoadingFooter from "./LoadingFooter";
import { FooterSummary } from "../types";

type FooterProps = {
  footer?: FooterSummary;
  loading: boolean;
  handleRequest: () => void;
  onClose: () => void;
};

const Footer: FunctionComponent<FooterProps> = ({
  footer,
  loading,
  handleRequest,
  onClose,
}) => {
  if (loading) return <LoadingFooter />;

  return (
    <Row
      justify={
        footer?.finishButton && footer?.continueButton ? "space-evenly" : "end"
      }
    >
      <Col>
        <Button type="primary" onClick={onClose}>
          {footer?.closeButton?.text || footer?.continueButton?.text}
        </Button>
      </Col>
      {footer?.finishButton && (
        <Col>
          <Button id="add-button" type="primary" onClick={handleRequest}>
            {footer.finishButton.text}
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default Footer;
