import React, { FunctionComponent } from "react";
import { Row, Col, Radio, Button } from "@triporate/triporate-design-system";

import { SegmentProps } from "../types";
import TransportInfo from "./TransportInfo";
import TransportDetails from "./TransportDetails";
import "./MainContent.scss";

const MainContent: FunctionComponent<SegmentProps> = ({
  segment,
  selectedRadio,
  setSelectedRadio,
  opendedSegmentId,
  setOpendedSegmentId,
}) => {
  const { optionId } = segment;

  const openDetails = () => {
    const value = opendedSegmentId === optionId ? undefined : optionId;
    setOpendedSegmentId(value);
  };

  const handleRowOnClick = () => {
    if (selectedRadio !== optionId) setSelectedRadio(optionId);
  };

  const handleMoreInfoColOnClick = () => {
    if (selectedRadio === optionId) openDetails();
  };

  return (
    <Row justify="space-between" align="middle" onClick={handleRowOnClick}>
      <Col span={1}>
        <Radio.Group value={selectedRadio}>
          <Radio value={optionId} />
        </Radio.Group>
      </Col>

      <Col span={13}>
        <TransportInfo data={segment.info} />
      </Col>
      <Col span={8}>
        <TransportDetails data={segment.details} />
      </Col>

      <Col span={2} onClick={handleMoreInfoColOnClick}>
        {selectedRadio === optionId && (
          <Button type="link">
            {opendedSegmentId === optionId ? "- " : "+ "}
            {segment.moreInfo.text}
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default MainContent;
