import React, { FunctionComponent } from "react";
import {
  Row,
  Col,
  Typography,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";

import { SegmentData } from "../types";

const { Text, Paragraph } = Typography;

type SegmentDetailsProps = {
  data: SegmentData[0];
};

const SegmentDetails: FunctionComponent<SegmentDetailsProps> = ({ data }) => (
  <Row gutter={[12, 12]}>
    <Col span={24}>
      <Row>
        <Col span={4}>
          <Text className="icons-right-margin">
            <Icons icon={validateIconType(data.col1.icon)} />
          </Text>
          <Text>{data.col1.text}</Text>
        </Col>
        <Col span={4}>
          <Text>{data.col2.text}</Text>
        </Col>
        <Col span={16}>
          <Text>{data.col3.text} </Text>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <Row>
            <Col span={6}>
              <Text>
                <Icons icon={validateIconType(data.col4.icon)} />
              </Text>
            </Col>
            <Col span={18}>
              <Text>{data.col4.text}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={4}>
          <Text>{data.col5.text}</Text>
        </Col>
        <Col span={16}>
          <Text>{data.col6.text}</Text>
        </Col>
      </Row>
    </Col>
    {data.col7.text && (
      <Col span={24}>
        <Paragraph>{data.col7.text}</Paragraph>
      </Col>
    )}
  </Row>
);

export default SegmentDetails;
