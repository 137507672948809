/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
    FunctionComponent,
    useState,
    useEffect,
    useCallback,
} from "react";
import { Drawer } from "@triporate/triporate-design-system";
import { Addons } from "../../../../../services/Results/getTransportAncillaries/types";
import { getTransportAncillaries } from "../../../../../services/Results";
import { RequestData } from "../types";
import {
    AncillariesData,
    SelectedBaggage,
    SelectedFare,
    SelectedPriority,
    SelectedBaggagePrice,
    SelectedId,
    SelectedIdBag,
} from "./types";
import { setOptionAddons } from "./utils";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import "./Ancillaries.scss";

type AncillariesProps = {
    requestData: RequestData | null;
    cleanRequestData: () => void;
    setPaymentsRequestData: (url: string, body: string) => void;
    setSummaryRequestData: (url: string, body: string) => void;
    cleanAncillaries: boolean;
    hideAncillaries: () => void;
};

const Ancillaries: FunctionComponent<AncillariesProps> = ({
    requestData,
    cleanRequestData,
    setPaymentsRequestData,
    setSummaryRequestData,
    cleanAncillaries,
    hideAncillaries,
}): JSX.Element => {
    const [selectedTab, setSelectedTab] = useState("fares");
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [ancillaries, setAncillaries] = useState<AncillariesData | null>(
        null
    );
    const [addons, setAddons] = useState<Addons>([]);
    const [selectedFare, setSelectedFare] = useState<SelectedFare>({
        solutionId: "",
        fareBasis: "",
        price: 0,
    });
    const [selectedPriority, setSelectedPriority] = useState<SelectedPriority>(
        []
    );
    const [selectedBaggage, setSelectedBaggage] = useState<SelectedBaggage>([]);
    const [selectedBaggagePrice, setSelectedBaggagePrice] =
        useState<SelectedBaggagePrice>([]);
    const [selectedId, setSelectedId] = useState<SelectedId>([]);
    const [selectedIdBag, setSelectedIdBag] = useState<SelectedIdBag>([]);

    useEffect(() => {
        const handleFetch = async ({
            url,
            body,
        }: {
            url: string;
            body: string;
        }) => {
            setLoading(true);
            setVisible(true);

            const { data } = await getTransportAncillaries(url, body);
            if (data) setAncillaries(data);
            setLoading(false);
        };

        if (requestData) handleFetch(requestData);
    }, [requestData]);

    //set fare data for ancillaries drawer
    useEffect(() => {
        if (ancillaries?.body?.addons) {
            setAddons(ancillaries?.body?.addons);
        }
        if (ancillaries?.body?.fares?.content[0]) {
            setSelectedFare({
                solutionId: ancillaries?.body?.fares?.content[0].solutionId,
                fareBasis: ancillaries?.body?.fares?.content[0].fareBasis,
                price: ancillaries?.body?.fares?.content[0].col7.price.money
                    .amount,
            });
        }
    }, [ancillaries?.body]);

    const closeDrawer = useCallback(() => {
        setVisible(false);
        setAncillaries(null);
        setSelectedPriority([]);
        setSelectedBaggage([]);
        setSelectedBaggagePrice([]);
        setSelectedFare({
            solutionId: "",
            fareBasis: "",
            price: 0,
        });
        cleanRequestData();
        setSelectedTab("fares");
        setSelectedId([]);
        setSelectedIdBag([]);
    }, [cleanRequestData]);

    const handleRequestData = useCallback(() => {
        if (!ancillaries?.footer.btn2 || !requestData) return;
        const { href, param, showSummary } = ancillaries?.footer.btn2;
        const { solutionId, fareBasis } = selectedFare;
        const url = `${href}${param}=${solutionId}`;
        const addons = setOptionAddons(selectedBaggage, selectedPriority);
        const body = JSON.stringify({
            ...JSON.parse(requestData.body),
            fareBasis,
            solutionId,
            addons,
        });
        if (showSummary) {
            setSummaryRequestData(url, body);
        } else {
            setPaymentsRequestData(url, body);
            closeDrawer();
        }
    }, [
        ancillaries?.footer.btn2,
        closeDrawer,
        requestData,
        selectedBaggage,
        selectedFare,
        selectedPriority,
        setPaymentsRequestData,
        setSummaryRequestData,
    ]);

    useEffect(() => {
        if (cleanAncillaries) {
            closeDrawer();
            hideAncillaries();
        }
    }, [cleanAncillaries, hideAncillaries, closeDrawer]);

    return (
        <Drawer
            className="ancillaries-drawer"
            title={<Header loading={loading} data={ancillaries?.header} />}
            placement="right"
            closable={!loading && !ancillaries}
            visible={visible}
            onClose={closeDrawer}
            mask
            maskClosable={false}
            maskStyle={{ background: "transparent" }}
            key="right"
            width={800}
            footer={
                <Footer
                    loading={loading}
                    data={ancillaries?.footer}
                    closeDrawer={closeDrawer}
                    handleRequestData={handleRequestData}
                />
            }
        >
            <Body
                loading={loading}
                data={ancillaries?.body}
                selectedFare={selectedFare}
                setSelectedFare={setSelectedFare}
                selectedPriority={selectedPriority}
                setSelectedPriority={setSelectedPriority}
                selectedBaggage={selectedBaggage}
                setSelectedBaggage={setSelectedBaggage}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                selectedBaggagePrice={selectedBaggagePrice}
                setSelectedBaggagePrice={setSelectedBaggagePrice}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                addons={addons}
                selectedIdBag={selectedIdBag}
                setSelectedIdBag={setSelectedIdBag}
            />
        </Drawer>
    );
};

export default Ancillaries;
