import React, { FunctionComponent } from "react";
import { Col, Row, Typography } from "@triporate/triporate-design-system";
import { FinishTrip } from "../../../types";
import ContactInfo from "../../../../../ContactInfo";

type SummaryFinishTripProps = {
  finishTrip: FinishTrip;
};

const { Text } = Typography;

const SummaryFinishTrip: FunctionComponent<SummaryFinishTripProps> = ({
  finishTrip,
}): JSX.Element => {
  return (
    <Row>
      <Col>
        <Text>{finishTrip?.text1}</Text>
        <Text strong>{finishTrip?.link.text}</Text>
        <Text>{finishTrip?.text2}</Text>
        {finishTrip?.schedule && finishTrip?.phone && (
          <ContactInfo
            phone={finishTrip.phone}
            schedule={finishTrip.schedule}
          />
        )}
      </Col>
    </Row>
  );
};

export default SummaryFinishTrip;
