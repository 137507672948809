import React, {
  FunctionComponent,
  useCallback,
  useState,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";
import { useMappedState } from "redux-react-hook";
import { ConfigState } from "../../store/config";
import { getBillingFulfillmentInfo } from "../../services/BillingFulfillment/getBillingFulfillmentInfo";
import { parseLocationSearch } from "../../utils";
import { BillingFulfillmentBtn } from "./BillingFulfillmentBtn";
import { BillingFulfillmentFormIFrame } from "./BillingFulfillmentFormIFrame";

interface BillingFulfillmentProps {
  onlineMode: boolean;
}

export const BillingFulfillment: FunctionComponent<BillingFulfillmentProps> = ({
  onlineMode,
}) => {
  const { search } = useLocation();
  const offerId = parseLocationSearch(search, "offerId");
  const tripId = parseLocationSearch(search, "tripId");

  const mapState = (state: ConfigState): string => state.config.backendUrl;
  const backendUrl = useMappedState(mapState);

  const [fetchedData, setFetchedData] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [forceModal, setForceModal] = useState<boolean>(false);
  const [billingFulfilled, setBillingFulfilled] = useState<boolean>(false);
  const [billingFulfillmentEnabled, setBillingFulfillmentEnabled] =
    useState<boolean>(false);
  const [btnContent, setBtnContent] = useState<string>("");
  const [formSrc, setFormSrc] = useState<string>("");

  const fetchBillingFulfillmentInfo = useCallback(
    async ({
      offerId,
      tripId,
    }: {
      offerId: string | undefined;
      tripId?: string;
    }) => {
      let query = "";
      if (offerId) {
        query = `offerId=${offerId}`;
      } else if (tripId) {
        query = `tripId=${tripId}`;
      }
      if (!query) return;

      const { data } = await getBillingFulfillmentInfo(backendUrl, query);
      if (!data) return;
      setBillingFulfilled(data?.billingFulfilled);
      setBillingFulfillmentEnabled(data?.billingFulfillmentEnabled);
      setBtnContent(data?.showFormBtn);
      setFormSrc(data?.formSrc);
      setFetchedData(true);
    },
    [backendUrl]
  );

  useEffect(() => {
    fetchBillingFulfillmentInfo({ offerId, tripId });
  }, [offerId, tripId]);

  useEffect(() => {
    if (fetchedData && onlineMode && !billingFulfilled) setForceModal(true);
  }, [onlineMode, billingFulfilled, fetchedData]);

  useEffect(() => {
    if (forceModal) setShowModal(true);
  }, [forceModal]);

  if (!billingFulfillmentEnabled) return <></>;
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <BillingFulfillmentBtn
        billingFulfilled={billingFulfilled}
        btnContent={btnContent}
        showModal={() => setShowModal(true)}
      />
      <BillingFulfillmentFormIFrame
        forceModal={forceModal}
        visible={showModal}
        formSrc={formSrc}
        hideCloseModalBtn={onlineMode && !billingFulfilled}
        closeModal={() => {
          setShowModal(false);
          fetchBillingFulfillmentInfo({ offerId, tripId });
        }}
      />
    </div>
  );
};
