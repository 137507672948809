import React, { FunctionComponent } from "react";
import { Typography } from "@triporate/triporate-design-system";

const { Text } = Typography;

type SummaryNoItems = {
  text?: string;
};

const SummaryNoItems: FunctionComponent<SummaryNoItems> = ({
  text,
}): JSX.Element => <Text>{text}</Text>;

export default SummaryNoItems;
