import React, { FunctionComponent } from "react";
import {
  Icons,
  Typography,
  validateIconType,
  Row,
  Col,
  TripoMap,
} from "@triporate/triporate-design-system";
import { CarOfficeInfo } from "../InputGroupSwitch/InputGroups/CarInputs/types";
import { store } from "../../../../store";

const { Title, Text, Paragraph } = Typography;

type ContactInfoProps = {
  data: CarOfficeInfo;
};

const OfficeInfo: FunctionComponent<ContactInfoProps> = ({
  data,
}): JSX.Element => {
  const { config } = store.getState();
  const apiKey = config.googleMapsApiKey;

  return (
    <Row gutter={[12, 24]}>
      <Col span={18}>
        <Title level={5}>
          <Icons icon={validateIconType(data.icon)} />
          {data.title}
        </Title>

        <Row gutter={[18, 0]}>
          <Col span={12}>
            <Text strong>{data.address.title}</Text>
            <Paragraph>{data.address.text}</Paragraph>
          </Col>
          <Col span={12}>
            <Text strong>{data.hours.title}</Text>
            <Paragraph>{data.hours.text}</Paragraph>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Text strong>{data.contact.title}</Text>
            <Paragraph>
              {data.contact.tel.text}: {data.contact.tel.telf}
            </Paragraph>
          </Col>
          <Col span={12}>
            <Text strong>{data.return.title}</Text>
            <Paragraph>{data.return.text}</Paragraph>
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        <div className="hola">
          <TripoMap
            locations={[data.coord]}
            googleMapsApiKey={apiKey}
            height={300}
          />
        </div>
      </Col>
    </Row>
  );
};

export default OfficeInfo;
