/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent } from "react";
import {
  Row,
  Col,
  Typography,
  Space,
} from "@triporate/triporate-design-system";
import {
  SelectedPriority,
  SelectedBaggage,
  SelectedBaggagePrice,
  SelectedId,
  SelectedIdBag,
} from "../../types";
import PriorityBoarding from "./PriorityBoarding/PriorityBoarding";
import Baggage from "./Baggage/Baggage";
import { Addons } from "../../../../../../../services/Results/getTransportAncillaries/types";
import { AncillarieType } from "../../AncillarieType";

const { Title, Text } = Typography;
type CommonProps = {
  selectedPriority: SelectedPriority;
  setSelectedPriority: (value: SelectedPriority) => void;
  selectedTab: string;
  selectedBaggage: SelectedBaggage;
  setSelectedBaggage: (value: SelectedBaggage) => void;
  selectedBaggagePrice: SelectedBaggagePrice;
  setSelectedBaggagePrice: (value: SelectedBaggagePrice) => void;
  selectedId: SelectedId;
  setSelectedId: (value: SelectedId) => void;
  addons: Addons;
  selectedIdBag: SelectedIdBag;
  setSelectedIdBag: (value: SelectedIdBag) => void;
};
const Common: FunctionComponent<CommonProps> = ({
  selectedPriority,
  setSelectedPriority,
  selectedTab,
  selectedBaggage,
  setSelectedBaggage,
  selectedBaggagePrice,
  setSelectedBaggagePrice,
  selectedId,
  setSelectedId,
  addons,
  selectedIdBag,
  setSelectedIdBag,
}) => {
  let hasPriority = false;
  let hasBaggage = false;
  if (addons.length > 0) {
    addons.map((addon) => {
      if (addon.priority) hasPriority = addon.priority.length > 0;
      if (addon.baggage) hasBaggage = addon.baggage.length > 0;
    });
  }

  if (!hasPriority && selectedTab === AncillarieType.priority)
    return <Text>Seleccion de embarque prioritario no disponible</Text>;

  if (!hasBaggage && selectedTab === AncillarieType.baggage)
    return <Text>Seleccion de equipaje no disponible</Text>;

  return (
    <Row className="priority-boarding-section">
      <Col span={24}>
        <Space direction="vertical" size="middle" className="space-witdh-100">
          {addons.map((ancillaries, index) => (
            <>
              {hasPriority && selectedTab === AncillarieType.priority && (
                <Row className="priority-boarding-pax" key={index}>
                  <Col span={24}>
                    <Title level={5}>{ancillaries.paxName}</Title>
                  </Col>
                  <PriorityBoarding
                    selectedPriority={selectedPriority}
                    setSelectedPriority={setSelectedPriority}
                    priorityBoardingData={ancillaries.priority}
                    pax={ancillaries.pax}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                  />
                </Row>
              )}
              {hasBaggage && selectedTab === AncillarieType.baggage && (
                <Row className="priority-boarding-pax" key={index}>
                  <Col span={24}>
                    <Title level={5}>{ancillaries.paxName}</Title>
                  </Col>
                  <Baggage
                    selectedBaggage={selectedBaggage}
                    setSelectedBaggage={setSelectedBaggage}
                    baggageData={ancillaries.baggage}
                    pax={ancillaries.pax}
                    selectedBaggagePrice={selectedBaggagePrice}
                    setSelectedBaggagePrice={setSelectedBaggagePrice}
                    selectedIdBag={selectedIdBag}
                    setSelectedIdBag={setSelectedIdBag}
                  />
                </Row>
              )}
            </>
          ))}
        </Space>
      </Col>
    </Row>
  );
};

export default Common;
