/* eslint-disable max-lines */
import React, { FunctionComponent } from "react";
import {
  Row,
  Col,
  Image,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";
import { SegmentInfo } from "../types";
import JourneyInfo from "./JourneyInfo";

type TransportInfoProps = {
  data: SegmentInfo;
};

const TransportInfo: FunctionComponent<TransportInfoProps> = ({
  data,
}): JSX.Element => (
  <Row justify="space-between" align="middle" className="transport-info">
    <Col span={2} className="img-col">
      <Image
        width={35}
        src={data.image.src}
        alt={data.image.alt}
        preview={false}
      />
    </Col>

    <Col span={3}>
      <JourneyInfo data={data.origin} />
    </Col>

    <Col span={2}>
      <Icons icon={validateIconType("arrowRight")} size="big" />
    </Col>

    <Col span={3}>
      <JourneyInfo data={data.destination} />
    </Col>
    <Col span={1} />
  </Row>
);

export default TransportInfo;
