import React, { FunctionComponent } from "react";
import {
  Row,
  Col,
  Typography,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";
import { TrainJourneyData } from "../types";

const { Text } = Typography;

type JourneyDetailsProps = {
  data?: TrainJourneyData["details"];
};

const JourneyDetails: FunctionComponent<JourneyDetailsProps> = ({ data }) => {
  if (!data) return <></>;
  return (
    <Row className="detail_row">
      <Col span={3}>
        <Text strong>{data.text}</Text>
      </Col>
      <Col span={5} className="detail-date_col">
        <Text>{data.date}</Text>
      </Col>
      <Col span={3}></Col>
      <Col span={13}>
        <Icons icon={validateIconType(data.icon)} />
        <Text>{data.time}</Text>
      </Col>
    </Row>
  );
};

export default JourneyDetails;
