/* eslint-disable max-lines */
import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Alert, Space } from "@triporate/triporate-design-system";
import { InputData, Fields } from "../../../../types";
import {
  FormItem,
  FetchSelect,
  SelectSearchAsyncOptions,
  RangePicker,
} from "../../CommonComponents";
import "./PoiAddressInputs.scss";

type SearchFormProps = {
  poiName: string;
  hotelName: string;
  inputs: { [key: string]: InputData };
  formValues: { [key: string]: Fields };
  locationName: string;
  isFirstSubmit: boolean;
  getFieldValue: (inpuntName: string) => void;
  setFieldsValue: (values: Fields) => void;
  validateFields: (value: string[]) => void;
  distanceInputName: string;
  datesInputName: string;
};

const SearchForm: FunctionComponent<SearchFormProps> = ({
  poiName,
  hotelName,
  inputs,
  formValues,
  locationName,
  isFirstSubmit,
  getFieldValue,
  setFieldsValue,
  validateFields,
  distanceInputName,
  datesInputName,
}): JSX.Element => {
  const [searchBycity, setSearchByCity] = useState(inputs[locationName]);
  const [searchByHotel, setSearchByHotel] = useState(inputs[hotelName]);
  const [areMandatoryInputsEmpty, setAreMandatoryInputsEmpty] = useState(false);

  const onChange = (inputRelated: string) =>
    setFieldsValue({ [inputRelated]: undefined });

  const handleRequiredRule = (data: InputData, disabled: boolean) => ({
    ...data,
    rules: data?.rules?.map((rule) => {
      if (typeof rule.required === "undefined") return rule;
      return { ...rule, required: disabled };
    }),
  });

  const validateSearch = (
    setFn: (value: InputData) => void,
    input: InputData,
    inputName: string
  ): void => {
    setFn(handleRequiredRule(input, false));
    validateFields([inputName]);
    setAreMandatoryInputsEmpty(false);
  };
  const validateEmptyInputs = (
    inputCity: InputData,
    inputHotel: InputData,
    inputName: string[],
    bool: boolean
  ): void => {
    setSearchByCity(handleRequiredRule(inputCity, bool));
    setSearchByHotel(handleRequiredRule(inputHotel, bool));
    validateFields(inputName);
    setAreMandatoryInputsEmpty(bool);
  };

  useEffect(() => {
    const hotelInputValue = getFieldValue(hotelName);
    const locationInputValue = getFieldValue(locationName);
    const emptyInputs =
      locationInputValue === undefined && hotelInputValue === undefined;
    if (hotelInputValue !== undefined)
      return validateSearch(
        setSearchByCity,
        inputs[locationName],
        locationName
      );

    if (locationInputValue !== undefined)
      return validateSearch(setSearchByHotel, inputs[hotelName], hotelName);

    if (emptyInputs && isFirstSubmit) {
      return validateEmptyInputs(
        inputs[locationName],
        inputs[hotelName],
        [locationName, hotelName],
        false
      );
    } else {
      return validateEmptyInputs(
        inputs[locationName],
        inputs[hotelName],
        [locationName, hotelName],
        true
      );
    }
  }, [
    formValues,
    getFieldValue,
    hotelName,
    locationName,
    inputs,
    isFirstSubmit,
    validateFields,
  ]);

  return (
    <>
      <Space size={"middle"} align="baseline">
        <div className="search-input-wrapper">
          <Space size={"middle"} align="baseline">
            <Col>
              <div className="abt-search-input_width">
                <FormItem inputName={locationName} inputData={searchBycity}>
                  <SelectSearchAsyncOptions
                    formValues={formValues}
                    inputData={searchBycity}
                    inputName={locationName}
                    setFieldsValue={setFieldsValue}
                    cleanOnChange={onChange}
                    inputToCleanOnChange={hotelName}
                  />
                </FormItem>
              </div>
            </Col>
            <Col>
              <div className="abt-search-input_width_poi">
                <FormItem inputName={poiName} inputData={inputs[poiName]}>
                  <FetchSelect
                    formValues={formValues}
                    inputData={inputs[poiName]}
                    inputName={poiName}
                    getFieldValue={getFieldValue}
                    setFieldsValue={setFieldsValue}
                  />
                </FormItem>
              </div>
            </Col>
            <Col>
              <div className="abt-search-input_width_hotel">
                <FormItem inputName={hotelName} inputData={searchByHotel}>
                  <SelectSearchAsyncOptions
                    formValues={formValues}
                    inputData={searchByHotel}
                    inputName={hotelName}
                    setFieldsValue={setFieldsValue}
                  />
                </FormItem>
              </div>
            </Col>
          </Space>
        </div>
        <div>
          <Col>
            <div className="abt-search-input_width_distance">
              <FormItem
                inputName={distanceInputName}
                inputData={inputs[distanceInputName]}
              >
                <FetchSelect
                  formValues={formValues}
                  inputName={distanceInputName}
                  inputData={inputs[distanceInputName]}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                />
              </FormItem>
            </div>
          </Col>
        </div>
        <div>
          <Col>
            <div className="dates-input_width">
              <RangePicker
                inputName={datesInputName}
                inputData={inputs[datesInputName]}
                setFieldsValue={setFieldsValue}
                showTime={false}
              />
            </div>
          </Col>
        </div>
      </Space>
      {areMandatoryInputsEmpty && (
        <Col span={24}>
          <div className="alert-wrapper">
            <Alert message={searchBycity.alertMsg} type="error" showIcon />
          </div>
        </Col>
      )}
    </>
  );
};

export default SearchForm;
