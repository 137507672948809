import React, { FunctionComponent, useState, useEffect } from "react";
import { Drawer } from "@triporate/triporate-design-system";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { parseLocationSearch } from "../../../utils";
import { getSummary, itineraryRequest } from "../../../services/Summary";
import { FooterSummary, FinishTrip, Item as ItemType } from "./types";
import { useWindowMessagesHandler } from "../../../hooks";
import { PaymentsIFrameModalMessages } from "../../../constants";

type SummaryProps = {
  url?: string;
  itineraryUrl: string;
  showSBTBasket: boolean;
  setShowSBTBasket: (value: boolean) => void;
  orderCompleted: boolean;
  setOrderCompleted: (value: boolean) => void;
  setTtripCreated: (value: boolean) => void;
};

const SummaryDrawer: FunctionComponent<SummaryProps> = ({
  url,
  itineraryUrl,
  showSBTBasket,
  setShowSBTBasket,
  orderCompleted,
  setOrderCompleted,
  setTtripCreated,
}): JSX.Element => {
  const { search } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [item, setItem] = useState<ItemType>();
  const [header, setHeader] = useState<string>("");
  const [footer, setFooter] = useState<FooterSummary>();
  const [finishTrip, setFinishTrip] = useState<FinishTrip>();
  const [noItemsMessage, setNoItemsMessage] = useState<string>();
  const [offerId, setOfferId] = useState<string>();

  const handleFetch = async (url: string, offerId?: string) => {
    setLoading(true);
    const { data } = await getSummary(url, offerId);
    if (!data) return;
    const {
      item: resItem,
      header: resHeader,
      noItemsMessage: resNoItemsMessage,
      finishTrip: resFinishTrip,
      footer: resFooter,
    } = data;

    if (resItem) {
      setItem(resItem);
      setShowSBTBasket(true);
      setTtripCreated(true);
    }
    if (resHeader) setHeader(resHeader.text);
    if (resNoItemsMessage) setNoItemsMessage(resNoItemsMessage.text);
    if (resFinishTrip) setFinishTrip(resFinishTrip);

    if (resFooter) setFooter(resFooter);

    setLoading(false);
  };

  const sendItinerary = async (url: string, offerId?: string) => {
    return await itineraryRequest(url, offerId);
  };

  useEffect(() => {
    if (url) handleFetch(url, offerId);
  }, [url, offerId]);

  useEffect(() => {
    const id = parseLocationSearch(search, "offerId");
    if (id) setOfferId(id);
  }, [search]);

  const dashboardRedirect = () => {
    if (finishTrip?.dashboardURL)
      window.location.href = finishTrip.dashboardURL;
  };

  const handleRequest = () => {
    if (!finishTrip) return;
    setOrderCompleted(true);
    setHeader(finishTrip.title);
    setFooter(finishTrip.footer);
    sendItinerary(itineraryUrl, offerId);
  };

  const onClose = () => {
    if (orderCompleted) dashboardRedirect();
    else setShowSBTBasket(false);
  };

  useWindowMessagesHandler([
    {
      message: PaymentsIFrameModalMessages.redirectToDashboard,
      messageHandler: () => {
        url && handleFetch(url, offerId);
      },
    },
    {
      message: PaymentsIFrameModalMessages.closePaymentsIframe,
      messageHandler: () => url && handleFetch(url, offerId),
    },
  ]);

  return (
    <Drawer
      className="ancillaries-drawer"
      title={<Header loading={loading} title={header} />}
      placement="right"
      closable={false}
      visible={showSBTBasket}
      onClose={onClose}
      key="right"
      footer={
        <Footer
          loading={loading}
          footer={footer}
          onClose={onClose}
          handleRequest={handleRequest}
        />
      }
    >
      <Body
        loading={loading}
        data={item}
        noItemsMessage={noItemsMessage}
        orderCompleted={orderCompleted}
        finishTrip={finishTrip}
      />
    </Drawer>
  );
};

export default SummaryDrawer;
