import React, { FunctionComponent } from "react";
import { Typography } from "@triporate/triporate-design-system";
import LoadingHeader from "./LoadingHeader";

const { Title } = Typography;

type HeaderProps = {
  title?: string;
  loading: boolean;
};

const Header: FunctionComponent<HeaderProps> = ({ loading, title }) => {
  if (loading) return <LoadingHeader />;

  if (!title) return <></>;

  return <Title level={4}>{title}</Title>;
};

export default Header;
