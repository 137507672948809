import React, { FunctionComponent } from "react";
import { useMappedState } from "redux-react-hook";

import { AuthState } from "../../../store/auth";
import Login from "./Login";

const AuthContainer: FunctionComponent = ({ children }): JSX.Element => {
  const mapState = (state: AuthState): boolean => state.auth.isAuthenticated;
  const isAuthenticated = useMappedState(mapState);

  return <>{isAuthenticated ? children : <Login />}</>;
};

export default AuthContainer;
