/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent, useEffect } from "react";
import { SelectedBaggagePrice, SelectedIdBag } from "../../../types";
import { Col, Select, Price } from "@triporate/triporate-design-system";
import {
  PriceSizeEnum,
  validateTextTypeString,
} from "../../../../../../../../utils";
import { getBaggageName } from "../../../../../../../../utils/baggage";

const { Option } = Select;

type BaggageByPassengerJourneyProps = {
  handleBaggeChange: (event: any, value: any, pax: number) => void;
  pax: number;
  baggage: any;
  ind: number;
  selectedBaggagePrice: SelectedBaggagePrice;
  selectedIdBag: SelectedIdBag;
};

const BaggageByPassengerJourney: FunctionComponent<
  BaggageByPassengerJourneyProps
> = ({
  handleBaggeChange,
  pax,
  baggage,
  ind,
  selectedBaggagePrice,
  selectedIdBag,
}) => {
  useEffect(() => {
    getBaggageName(`${ind}-${pax}`, selectedIdBag);
  }, [selectedIdBag]);

  return (
    <>
      <Col span={10}>
        <Select
          defaultValue={getBaggageName(`${ind}-${pax}`, selectedIdBag)}
          className="baggage-select"
          onChange={(e, value) => handleBaggeChange(e, value, pax)}
        >
          {baggage.options?.length > 0 &&
            baggage.options?.map((service: any, index: number) => (
              <Option
                value={service.name}
                data-value={service}
                key={`${ind}-${index}`}
                id={`${ind}-${pax}`}
                seg={baggage.segmentId}
              >
                {service.name}
              </Option>
            ))}
        </Select>
      </Col>

      <Col span={5} className="baggage-price-col" id={`${ind}-${pax}`}>
        {selectedBaggagePrice.length > 0 &&
          selectedBaggagePrice.map((serv, servInd) => (
            <>
              {serv.id === `${ind}-${pax}` && (
                <Price
                  money={serv.price.money}
                  status={validateTextTypeString("success")}
                  label={serv.price.label}
                  size={PriceSizeEnum["S"]}
                  key={`${ind}-${servInd}`}
                />
              )}
            </>
          ))}
      </Col>
    </>
  );
};

export default BaggageByPassengerJourney;
