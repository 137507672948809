import React, { FunctionComponent, useContext, useState } from "react";
import { Space, Button } from "@triporate/triporate-design-system";
import { ResidentCandidateModalInfo, SegmentId } from "../types";
import Context from "../Context";
import ResidentsModal from "../ResidentsModal";
import { getResidentsModal } from "../../../../../services/Results";

type BuyButtonProps = {
    selectedSegmentsIds: {
        [key: number]: SegmentId;
    };
    label: string;
    href: string;
    isResidentCandidate?: boolean;
    residentsHref?: string;
    residentsCode?: string;
    onClickEvent?: () => void;
};

const BuyButton: FunctionComponent<BuyButtonProps> = ({
    selectedSegmentsIds,
    label,
    href,
    residentsHref,
    residentsCode,
    isResidentCandidate = false,
    onClickEvent
}): JSX.Element => {

    const { onBuyButtonClick } = useContext(Context);

    const [isResidentsModal, setIsResidentsModal] = useState(false);
    const [residentsModalData, setResidentsModalData] =
        useState<ResidentCandidateModalInfo>();

    const handleClick = async (
        href: string,
        selectedSegmentsIds: { [key: number]: string }
    ) => {
        if (!isResidentCandidate) {

            if (onClickEvent) {

                onClickEvent();

            } else {

                onBuyButtonClick(href, selectedSegmentsIds, '0');

            }
        } else {
            if (residentsHref && residentsCode) {
                setIsResidentsModal(true);
                const url = `${residentsHref}?code=${residentsCode}`;
                const { data } = await getResidentsModal(url);
                if (data) setResidentsModalData(data);
            }
        }
    };

    return (
        <>
            <Space align="center" direction="vertical" className="space-witdh-100">
                <Button
                    type="primary"
                    ghost
                    onClick={() => handleClick(href, selectedSegmentsIds)}
                    id="buy-btn-discovery"
                >
                    {label}
                </Button>
            </Space>
            {isResidentsModal && (
                <ResidentsModal
                    segments={selectedSegmentsIds}
                    isResidentsModal={isResidentsModal}
                    setIsResidentsModal={setIsResidentsModal}
                    data={residentsModalData}
                    buyHref={href}
                    residentsCode={residentsCode}
                />
            )}
        </>
    );
};

export default BuyButton;
