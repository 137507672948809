import { Button } from "@triporate/triporate-design-system";
import React, { FunctionComponent } from "react";

type ShoppingBasket = {
  label: string;
  setShowSBTBasket: (value: boolean) => void;
};

const ShoppingBasket: FunctionComponent<ShoppingBasket> = ({
  label,
  setShowSBTBasket,
}): JSX.Element => {
  const handleClick = () => setShowSBTBasket(true);
  return (
    <Button className="ant-btn-primary basket-button" onClick={handleClick}>
      {label}
    </Button>
  );
};

export default ShoppingBasket;
