import React, { FunctionComponent, useState, useEffect } from "react";
import { InputNumber } from "@triporate/triporate-design-system";

import { validateStringPlaceholder, convertToInt } from "../../utils";
import { InputData, Fields } from "../../../../types";
import { FormItem } from "../../CommonComponents";

type PaxNumberInputProps = {
  inputName: string;
  inputData: InputData;
  setFieldsValue: (values: Fields) => void;
};

/**
 * Field for budgets or estimation trips
 */
const PaxNumberInput: FunctionComponent<PaxNumberInputProps> = ({
  inputName,
  inputData,
  setFieldsValue,
}): JSX.Element => {
  const { defaultValue, placeholder, min, max, unit } = inputData || {};
  const [travellersAmount, setTravellersAmount] = useState(
    convertToInt(defaultValue)
  );

  const formatter = (value?: number): string => {
    const formatValue = value && Math.ceil(value);
    return unit ? `${formatValue} ${unit}` : `${formatValue}`;
  };

  useEffect(() => {
    if (typeof defaultValue === "number") setTravellersAmount(defaultValue);
  }, [defaultValue, setTravellersAmount]);

  useEffect(() => {
    setFieldsValue({ [inputName]: travellersAmount });
  }, [travellersAmount, inputName, setFieldsValue]);

  return (
    <FormItem inputName={inputName} inputData={inputData}>
      <InputNumber
        placeholder={validateStringPlaceholder(placeholder)}
        max={max}
        min={min}
        className="custom-input-width"
        formatter={formatter}
      />
    </FormItem>
  );
};

export default PaxNumberInput;
