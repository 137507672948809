import { useEffect, useState } from "react";
import {
  FiltersConfig,
  FiltersTripTypeGroup,
  CurrentInputsData,
} from "../components/SearchForm/types";
import { adaptFilterConfigToTripType } from "../components/SearchForm/utils";

const useFiltersConfig = (
  currentInputsData: CurrentInputsData,
  type?: string
): [
  FiltersConfig,
  {
    setRawFiltersConfig: (data: FiltersConfig) => void;
    setFiltersTripTypeGroup: (data?: FiltersTripTypeGroup) => void;
    setFiltersConfigDictionary: (data: {
      [key: string]: FiltersConfig;
    }) => void;
  }
] => {
  const [filtersConfig, setFiltersConfig] = useState<FiltersConfig>([]);

  const [filtersTripTypeGroup, setFiltersTripTypeGroup] =
    useState<FiltersTripTypeGroup>();
  const [rawfiltersConfig, setRawFiltersConfig] = useState<FiltersConfig>([]);
  const [filtersConfigDictionary, setFiltersConfigDictionary] = useState<{
    [key: string]: FiltersConfig;
  }>({});

  useEffect(() => {
    if (!type) return;
    const currentFilter = filtersConfigDictionary[type];
    if (currentFilter) {
      setRawFiltersConfig(currentFilter);
    } else {
      setRawFiltersConfig([]);
    }
  }, [
    type,
    filtersConfigDictionary,
    setRawFiltersConfig,
    // setAppliedFilters
  ]);

  useEffect(() => {
    setFiltersConfig(
      adaptFilterConfigToTripType(
        rawfiltersConfig,
        currentInputsData,
        filtersTripTypeGroup
      )
    );
  }, [
    rawfiltersConfig,
    setFiltersConfig,
    currentInputsData,
    filtersTripTypeGroup,
  ]);

  return [
    filtersConfig,
    {
      setFiltersTripTypeGroup,
      setRawFiltersConfig,
      setFiltersConfigDictionary,
    },
  ];
};

export default useFiltersConfig;
