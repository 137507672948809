import React, { FunctionComponent } from "react";
import _ from "lodash";
import { Tabs } from "@triporate/triporate-design-system";
import "../AgentsBookingTool.scss";

const { TabPane } = Tabs;

type SideBarTabsProps = {
  tabs?: {
    tabName: string;
    key: string;
  }[];
  selectedTab: string;
  defaultKey: string;
  setSelectedTab: (tab: string) => void;
};

const SideBarTabs: FunctionComponent<SideBarTabsProps> = ({
  tabs,
  selectedTab,
  defaultKey,
  setSelectedTab,
}): JSX.Element => {
  const filterdTabs = _.reject(tabs, _.isEmpty);
  return (
    <Tabs
      defaultActiveKey={defaultKey}
      activeKey={selectedTab}
      onChange={setSelectedTab}
      centered
    >
      {filterdTabs?.map(({ tabName, key }) => (
        <TabPane key={key} tab={<span>{tabName}</span>} />
      ))}
    </Tabs>
  );
};

export default SideBarTabs;
