import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Col,
  Collapse,
  Divider,
  Row,
  Skeleton,
  Typography,
} from "@triporate/triporate-design-system";
import { getCarDetails } from "../../../../../../services/Results/getCarResults";
import {
  DataInfo,
  DetailsData,
} from "../../../../../../services/Results/getCarResults/type";

export type SolutionProps = {
  showDetails: boolean;
  url: string;
  solutionId?: string;
};

const { Panel } = Collapse;
const { Text, Paragraph } = Typography;

const DetailsContent: FunctionComponent<SolutionProps> = ({
  showDetails,
  url,
  solutionId,
}) => {
  const [detailsData, setDetailsData] = useState<DetailsData>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!url) return setDetailsData(undefined);
    if (!solutionId || detailsData) return;

    const handleFetch = async (url: string) => {
      setLoading(true);

      const body = JSON.stringify({
        solution: solutionId,
      });

      const { data } = await getCarDetails(url, body);
      if (data) setDetailsData(data);

      setLoading(false);
    };

    if (!detailsData && showDetails) handleFetch(url);
  }, [showDetails, detailsData, url, solutionId]);

  if (!showDetails) return <></>;
  return !detailsData || loading ? (
    <Row>
      <Col span={20}>
        <Skeleton paragraph={{ rows: 2 }} active />
      </Col>
    </Row>
  ) : (
    <>
      <Divider />
      <Collapse>
        {Object.values(detailsData).map((extraItem, key) => (
          <Panel header={extraItem.title} key={key}>
            {Array.isArray(extraItem.data) ? (
              extraItem.data.map((item: DataInfo) => (
                <>
                  <Text strong>{item.title?.toUpperCase()}</Text>
                  <Paragraph>{item.description}</Paragraph>
                </>
              ))
            ) : (
              <>
                <Text strong>{extraItem.data.title?.toUpperCase()}</Text>
                <Paragraph>{extraItem.data.description}</Paragraph>
              </>
            )}
          </Panel>
        ))}
      </Collapse>
    </>
  );
};
export default DetailsContent;
