import React, { FunctionComponent } from "react";
import {
    Row,
    Col,
    Typography,
    Tooltip,
    SuitCaseIcon,
    Image,
    Tag,
} from "@triporate/triporate-design-system";

import { validateTextTypeString } from "../../../../../../utils";
import { SegmentDetails } from "../types";
import { lastTicketDateInfo } from "../../../../../../services/Results/getTransportResults/type";

const { Text } = Typography;

type TransportDetailsProps = {
    data: SegmentDetails;
    provider?: string;
    loadingCanTravelAgentUse?: boolean;
    canTravelAgentUse?: boolean;
    lastTicketDate: lastTicketDateInfo | undefined;
};

const TransportDetails: FunctionComponent<TransportDetailsProps> = ({
    data,
    provider,
    canTravelAgentUse,
    loadingCanTravelAgentUse,
    lastTicketDate,
}): JSX.Element => (
    <Row justify="space-between" align="middle">
        <Col span={4}>
            <Text>{data.col1.text}</Text>
        </Col>
        <Col offset={1} span={4}>
            <Row>
                <Col span={24}>
                    <Text
                        strong
                        type={validateTextTypeString(data.col2.text1.type)}
                    >
                        {data.col2.text1.text}
                    </Text>
                </Col>
                {data.col2.text2.text && (
                    <Col span={24}>
                        <Tooltip
                            placement="top"
                            title={data.col2.text2.tooltip}
                        >
                            <Text>{data.col2.text2.text}</Text>
                        </Tooltip>
                    </Col>
                )}
                {data.col2.text3.text && (
                    <Col span={24}>
                        <Tooltip
                            placement="top"
                            title={data.col2.text3.tooltip}
                        >
                            <Text ellipsis={true}>{data.col2.text3.text}</Text>
                        </Tooltip>
                    </Col>
                )}
            </Row>
        </Col>
        <Col span={4}>
            <Row>
                <Col>
                    <Text strong>{data.col3.text1}</Text>
                </Col>
                <Col>
                    <Text>{data.col3.text2}</Text>
                </Col>
            </Row>
        </Col>
        <Col span={3}>
            <SuitCaseIcon {...data.col4} size="xl" />
        </Col>
        {(loadingCanTravelAgentUse || canTravelAgentUse) && (
            <Col span={3}>
                <Image src={provider} width={50} preview={false} />
            </Col>
        )}

        <Col span={2}>
            {canTravelAgentUse && lastTicketDate && lastTicketDate.date && (
                <Tooltip placement="top" title={lastTicketDate.tooltip}>
                    <Tag className="lastTicketDate">{lastTicketDate?.date}</Tag>
                </Tooltip>
            )}
        </Col>
    </Row>
);

export default TransportDetails;
