import React, { FunctionComponent } from "react";
import {
  Tabs,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";
import { TabsData } from "../types";

const { TabPane } = Tabs;

type AncillariesTabsProps = {
  data?: TabsData;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
};

const AncillariesTabs: FunctionComponent<AncillariesTabsProps> = ({
  data,
  selectedTab,
  setSelectedTab,
}) => (
  <Tabs
    defaultActiveKey="fares"
    activeKey={selectedTab}
    onTabClick={setSelectedTab}
  >
    {data?.map(({ title, icon, key }) => (
      <TabPane
        key={key}
        tab={
          <span>
            <Icons icon={validateIconType(icon)} />
            {title}
          </span>
        }
      />
    ))}
  </Tabs>
);

export default AncillariesTabs;
