import React, { FunctionComponent } from "react";
import LoadingBody from "./LoadingBody";
import { FinishTrip, Item } from "../types";
import SummaryItems from "./SummaryContent/SummaryItems";
import SummaryNoItems from "./SummaryContent/SummaryNoItems";
import SummaryFinishTrip from "./SummaryContent/SummaryFinishTrip";

type BodyProps = {
  loading: boolean;
  data?: Item;
  noItemsMessage?: string;
  orderCompleted: boolean;
  finishTrip?: FinishTrip;
};

const Body: FunctionComponent<BodyProps> = ({
  loading,
  data,
  noItemsMessage,
  finishTrip,
  orderCompleted,
}) => {
  if (loading) return <LoadingBody />;

  return (
    <>
      {orderCompleted ? (
        <SummaryFinishTrip finishTrip={finishTrip} />
      ) : data ? (
        <SummaryItems item={data} />
      ) : (
        <SummaryNoItems text={noItemsMessage} />
      )}
    </>
  );
};

export default Body;
