import { useRef, RefObject } from "react";

const useScrollDivIntoView = (): [RefObject<HTMLDivElement>, () => void] => {
  const ref = useRef<HTMLDivElement>(null);
  const executeScroll = () => ref?.current?.scrollIntoView();

  return [ref, executeScroll];
};

export default useScrollDivIntoView;
