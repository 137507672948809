import React, { FunctionComponent, useEffect } from "react";
import { InputData, Fields } from "../../../../types";
import { FormItem, SelectSearchAsyncOptions } from "../../CommonComponents";

type DirectionInputsProps = {
  cityInputName: string;
  cityMasterInputName?: string;
  officeInputHour: string;
  inputs: { [key: string]: InputData };
  formValues: { [key: string]: Fields };
  setFieldsValue: (values: Fields) => void;
  setQuerySearch?: (values: string) => void;
};

const DirectionInputs: FunctionComponent<DirectionInputsProps> = ({
  cityInputName,
  cityMasterInputName,
  inputs,
  formValues,
  setFieldsValue,
  setQuerySearch,
}): JSX.Element => {
  const masterInputValue =
    cityMasterInputName && formValues[cityMasterInputName];
  useEffect(() => {
    if (masterInputValue) setFieldsValue({ [cityInputName]: masterInputValue });
  }, [masterInputValue, cityInputName, setFieldsValue]);

  return (
    <FormItem inputName={cityInputName} inputData={inputs[cityInputName]}>
      <SelectSearchAsyncOptions
        formValues={formValues}
        inputData={inputs[cityInputName]}
        inputName={cityInputName}
        setFieldsValue={setFieldsValue}
        className="custom-car-city-input"
        setQuerySearch={setQuerySearch}
      />
    </FormItem>
  );
};

export default DirectionInputs;
