import React, { FunctionComponent, useContext } from "react";
import {
    Row,
    Col,
    Typography,
    Price,
    Icons,
    validateIconType,
    Tag,
} from "@triporate/triporate-design-system";
import { validateTextTypeString } from "../../../../../../utils";
import { PaymentInfoData } from "../types";
import "./PaymentInfo.scss";
import { ErrorPricing } from "../ErrorPricing/ErrorPricing";
import Context from "../../../TransportResultsV3/Context";

const { Text, Title } = Typography;

type PaymentInfoProps = {
    data: PaymentInfoData;
    isRefreshing?: boolean;
};

const PaymentInfo: FunctionComponent<PaymentInfoProps> = ({
    data,
    isRefreshing = false,
}) => {
    const { isErrorPricing, onRefreshPricingButtonClick } = useContext(Context);

    return (
        <>
            <Row className="paymentInfo_row">
                <Col span={14}>
                    <Title level={5}>
                        <Icons icon={validateIconType(data.passengers.icon)} />
                        {data.passengers.text}
                    </Title>

                    {data.passengers.list.map((passenger, key) => (
                        <Col key={key} span={24}>
                            <Text>{passenger}</Text>
                        </Col>
                    ))}
                </Col>
                <Col span={10} className="paymentInfo-price_col">
                    <Price
                        money={{ ...data.price.money, currency: "EUR" }}
                        status={validateTextTypeString(data.price.status)}
                        label={data.price.label}
                        note={data.price.note}
                    />
                    {isErrorPricing && (
                        <ErrorPricing
                            onClickButton={onRefreshPricingButtonClick}
                            isRefreshing={isRefreshing}
                        />
                    )}
                </Col>
            </Row>
            {data.tld && (
                <Row className="paymentInfo-tld_row">
                    <Col span={24}>
                        <Tag>{data.tld}</Tag>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default PaymentInfo;
