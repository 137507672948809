import { FetchService } from "../../FetchService";

type PaymentsData = {
  tripNumber: string;
  offerId: string;
  paymentPage: string;
  dashboardURL: string;
};

interface Response {
  error?: boolean;
  data?: PaymentsData;
}

const generateMockData = () => ({
  tripNumber: "102331",
  offerId: "3e807c13-67b0-469e-9dfc-709af56e3730",
  paymentPage: "https://ant.design/components/modal/",
  dashboardURL: "http://localhost:3000/company/trips",
});

export async function getPayments(
  url: string,
  body: string
): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return { data: generateMockData() };

  const { data, error } = await fetchService.post(url, body);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
