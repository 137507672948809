import { FetchService } from "../../FetchService";
import { hotelsInputs, flightsInputs, trainInputs } from "./inputsMockData";
import { Response } from "./types";

const handleMockData = (key: string): Response => {
  let data;
  let error = false;
  switch (true) {
    case key === "hotel-form":
      data = hotelsInputs;
      break;
    case key === "flight-form":
      data = flightsInputs;
      break;
    case key === "train-form":
      data = trainInputs;
      break;
    default:
      error = true;
  }

  return { data, error };
};

export async function getInputsData(url: string): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData(url);
  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
