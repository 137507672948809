import React from "react";
import {
  Row,
  Col,
  Skeleton,
  TripoCard,
} from "@triporate/triporate-design-system";

const Option = (): JSX.Element => {
  return (
    <TripoCard shadow>
      <Row align="middle" gutter={0}>
        <Col span={3}>
          <Skeleton.Image />
        </Col>
        <Col span={18}>
          <Skeleton paragraph={{ rows: 1 }} active />
        </Col>
        <Col span={3}>
          <Skeleton.Button active />
        </Col>
      </Row>
    </TripoCard>
  );
};

export default Option;
