import React, { FunctionComponent } from "react";
import {
    Col,
    Row,
    FareTable,
    Alert,
    Button,
} from "@triporate/triporate-design-system";
import FareConditions from "./FareConditions";
import { FaresData, SelectedFare } from "../../types";

import "./FareFamilies.scss";

type FareFamiliesProps = {
    data: FaresData;
    selectedFare: SelectedFare;
    setSelectedFare: (value: SelectedFare) => void;
};

const FareFamilies: FunctionComponent<FareFamiliesProps> = ({
    data,
    selectedFare,
    setSelectedFare,
}): JSX.Element => {
    const handleNextButtonClick = () => {};

    return (
        <Row>
            <Col className="fares-container" span={24}>
                {data.alert && (
                    <div className="fare_alert">
                        <Alert message={data.alert} type="warning" showIcon />
                    </div>
                )}
                <FareTable
                    data={data}
                    selectedFare={selectedFare}
                    setSelectedFare={setSelectedFare}
                />
            </Col>
        </Row>
    );
};

export default FareFamilies;
