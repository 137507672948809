import moment, { Moment } from "moment";
import { InputData } from "../../../types";

export const isValidDate = (
  dates: Moment[],
  inputData?: InputData
): Moment[] | undefined => {
  const [fromDate, toDate] = dates;
  const validDates = fromDate.isValid() && toDate.isValid();

  if (!validDates) return;
  if (fromDate < moment() || toDate < fromDate) return;

  const sameDateAllowed = inputData?.validatorRule?.allowSelectSameDay;
  if (sameDateAllowed !== undefined && !sameDateAllowed && toDate <= fromDate)
    return;

  return [fromDate, toDate];
};
