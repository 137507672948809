import React, { FunctionComponent } from "react";
import { Form } from "@triporate/triporate-design-system";
import ItemLabel from "./ItemLabel";
import { InputData } from "../../../../types";
import moment, { Moment } from "moment";

type ColLayout = { span?: number; offset?: number };

type FormItemProps = {
  inputName: string;
  inputData: InputData;
  children: JSX.Element;
  labelCol?: ColLayout;
  wrapperCol?: ColLayout;
  className?: string;
};

const FormItem: FunctionComponent<FormItemProps> = ({
  inputName,
  inputData,
  children,
  ...props
}): JSX.Element => {
  //check if this input has a customValidation
  const validate = async (_: unknown, value: unknown): Promise<void> => {
    const isDateRange =
      Array.isArray(value) && value.every((val) => val instanceof moment);

    if (!value && inputData?.validatorRule?.isRequired?.value) {
      return Promise.reject(
        new Error(inputData?.validatorRule?.isRequired?.message)
      );
    }

    //checking dates
    if (isDateRange && !inputData?.validatorRule?.allowSelectSameDay?.value) {
      const dates = value as Moment[];
      if (moment(dates?.[0]).isSame(dates?.[1]))
        return Promise.reject(
          new Error(inputData?.validatorRule?.allowSelectSameDay?.message)
        );
    }

    return Promise.resolve();
  };

  return (
    <Form.Item
      {...props}
      name={inputName}
      colon={false}
      rules={
        inputData?.validatorRule ? [{ validator: validate }] : inputData?.rules
      }
    >
      {children}
    </Form.Item>
  );
};

export default FormItem;
