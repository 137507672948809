import React, { FunctionComponent } from "react";
import { Row, Col } from "@triporate/triporate-design-system";

import BoughtAlert from "./BoughtAlert";
import "./Item.scss";

import {
  Item as ItemType,
  BoughtAlert as BoughtAlertType,
} from "../../../../types";
import TripSummary from "./TripSummary";

type ItemProps = {
  data: ItemType;
};

const Item: FunctionComponent<ItemProps> = ({ data }): JSX.Element => {
  return (
    <Row gutter={[0, 32]}>
      <Col span={24}>
        <TripSummary data={data} />
      </Col>

      <Col span={24}>
        <Row gutter={[0, 8]}>
          {data.row7.map((boughtItem: BoughtAlertType, key: number) => (
            <Col span={24} key={key}>
              <BoughtAlert data={boughtItem} />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default Item;
