/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  FiltersProvider,
  TagsOrderSection,
} from "@triporate/triporate-design-system";
import { AppliedOrder } from "@triporate/triporate-design-system/dist/components/utils/FiltersUI/type";
import { useBeforeunload } from "react-beforeunload";
import { useHistory, useLocation } from "react-router-dom";

import { SearchForm, Results, Header } from "../../components";
import { getSelfBookingToolData } from "../../services/SelfBookingTool";
import { useAppliedFilters, useFiltersConfig } from "../../hooks";
import { RequestData, Response as ResponseType, SearchFormType, WarningInformation } from "./types";
import {
  CurrentInputsData,
  InputData,
  OrderConfig,
  SubmitData,
  FormData,
} from "../../components/SearchForm/types";
import { getInputsData } from "../../services/SearchForm";
import SummaryDrawer from "../../components/SelfBookingTool/SummaryDrawer";
import { addTagManager } from "../../utils/addTagManager";
import "../../components/SelfBookingTool/SelfBookingTool.scss";
import { TravellersFormIFrame } from "../../components/FormIFrames";
import SideBar from "../../components/AgentBookingTool/SideBar";
import { parseLocationSearch } from "../../utils";

const { Content } = Layout;

const SelfBookingTool = (): ReactElement => {
  const { search } = useLocation();
  const history = useHistory();
  const offerId = parseLocationSearch(search, "offerId");

  const [appliedFilters, setAppliedFilters] = useAppliedFilters();
  const [appliedOrder, setAppliedOrder] = useState<AppliedOrder>();
  const [showBasket, setShowBasket] = useState(false);
  const [dataInfo, setData] = useState<ResponseType["data"]>();
  const [orderCompleted, setOrderCompleted] = useState(false);
  const [tripCreated, setTtripCreated] = useState(false);
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [warningInformation, setWarningInformation] = useState<WarningInformation>();
  const [searchFormType, setSearchFormType] = useState<SearchFormType>(); // gfsdfg
  const [searchFormRequestData, setSearchFormRequestData] =
    useState<RequestData>();

  const [inputsInfo, setInputs] = useState<{ [key: string]: InputData }>();
  const [currentInputsData, setCurrentInputsData] = useState<CurrentInputsData>(
    {
      tripType: "1",
      fieldsAmount: 1,
    }
  );
  const [travellersFormIFrameIsVisible, setTravellersFormIFrameIsVisible] =
    useState(false);
  const [newTravellerButton, setNewTravellerButton] = useState<SubmitData>();
  const [newTravellerIdCreated, setNewTravellerIdCreated] = useState("");
  const [submitInfo, setSubmit] = useState<SubmitData>();
  const [inputsDictionary, setInputsDictionary] = useState<{
    [key: string]: {
      inputs: { [key: string]: InputData };
      newTraveller: SubmitData;
      submit: SubmitData;
    };
  }>({});
  const [filterBtn, setFilterBtn] = useState("");

  const [
    filtersConfig,
    {
      setFiltersTripTypeGroup,
      setRawFiltersConfig,
      setFiltersConfigDictionary,
    },
  ] = useFiltersConfig(currentInputsData, searchFormType?.type);

  const [orderConfig, setOrderConfig] = useState<OrderConfig>();

  useEffect(() => {
    const handleFetch = async ({ type, url }: SearchFormType) => {
      const { data } = await getInputsData(url);
      if (!data) return;
      setInputsDictionary((prevState) => ({ ...prevState, [type]: data }));
      handleStatesUpdate(data, type);
    };

    const handleStatesUpdate = (data: FormData, type: string) => {
      const { submit, inputs, filters, order, newTraveller, warningInformation, } = data;
      setSubmit(submit);
      setWarningInformation(warningInformation);
      setNewTravellerButton(newTraveller);
      setInputs(inputs);
      setOrderConfig(order?.config);
      if (!filters) return;
      setFiltersTripTypeGroup(filters?.tripTypeGroup);
      setRawFiltersConfig(filters.config);
      setFilterBtn(filters.filterBtn);
      setFiltersConfigDictionary({ [type]: filters.config });
    };

    if (searchFormType?.type && inputsDictionary[searchFormType.type])
      handleStatesUpdate(
        inputsDictionary[searchFormType.type],
        searchFormType.type
      );
    else if (searchFormType) handleFetch(searchFormType);
  }, [
    searchFormType,
    inputsDictionary,
    setFiltersTripTypeGroup,
    setFiltersConfigDictionary,
    setRawFiltersConfig,
  ]);

  useEffect(() => {
    let isMounted = true;
    const handleFetch = async () => {
      const { data } = await getSelfBookingToolData(offerId);
      if (data && isMounted) {
        setData(data);
        addTagManager(data?.header.company.name);
      }
    };
    if (!dataInfo) handleFetch();
    return () => {
      isMounted = false;
    };
  }, [offerId]);

  useEffect(() => {
    if (dataInfo?.offerId) {
      history.push({
        search: `?offerId=${dataInfo?.offerId}`,
      });
    }
  }, [dataInfo?.offerId]);

  const handleOnHeaderItemChange = (searchFormType: SearchFormType) => {
    setSearchFormRequestData(undefined);
    setSearchFormType(searchFormType);
  };

  const closeTravellerFormIFrame = () =>
    setTravellersFormIFrameIsVisible(false);

  const showTravellerFormIFrame = () => setTravellersFormIFrameIsVisible(true);

  useEffect(() => {
    setNewTravellerIdCreated("");
    setAppliedOrder(undefined);
    setIsAfterSearch(false)
  }, [searchFormType]);

  const handleBeforeunload = () => {
    if (offerId && !orderCompleted) {
      setTimeout(() => {
        setShowBasket(true);
      }, 1500);
      return dataInfo?.header.textModalLogoutWithoutSaving;
    }
  };

  // show browser modal when logout
  useBeforeunload(handleBeforeunload);

  return (
    <Layout>
      <Header
        data={dataInfo?.header}
        onHeaderItemChange={handleOnHeaderItemChange}
        initialSelectedKeyKey={dataInfo?.header.options[0].type}
        setShowSBTBasket={setShowBasket}
        tripCreated={tripCreated}
      />
      <Content>
        <FiltersProvider
          config={filtersConfig}
          applyFilters={setAppliedFilters}
          flushAllFlag={searchFormType?.type + currentInputsData.tripType}
          orderConfig={orderConfig}
          applyOrder={setAppliedOrder}
          currentInputsData={currentInputsData}
        >
          <div className="searchForm-container sbt">
            <Row>
              <Col span={24}>
                <SearchForm
                  formType={searchFormType}
                  handleSumbit={setSearchFormRequestData}
                  setAppliedFilters={setAppliedFilters}
                  setAppliedOrder={setAppliedOrder}
                  inputs={inputsInfo}
                  addNewTraveller={newTravellerButton}
                  showTravellerFormIFrame={showTravellerFormIFrame}
                  newTravellerIdCreated={newTravellerIdCreated}
                  submit={submitInfo}
                  setCurrentInputsData={setCurrentInputsData}
                  warningInfo={warningInformation}
                  isAfterSearch={isAfterSearch}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col sm={21} md={6} xl={5} offset={1} className="content-col">
              <SideBar tabs={dataInfo?.sideBar} filtersConfig={filtersConfig} />
            </Col>
            <Col sm={21} md={15} xl={17} className="content-col space">
              <Row>
                <Col span={24}>
                  <TagsOrderSection filterBtn={filterBtn} />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Results
                    appliedFilters={appliedFilters}
                    searchFormRequestData={searchFormRequestData}
                    inputsType={searchFormType?.type}
                    appliedOrder={appliedOrder}
                    setIsAfterSearch={setIsAfterSearch}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={1} />
            <SummaryDrawer
              url={dataInfo?.summary.href}
              itineraryUrl={`${dataInfo?.header.basket?.itinerary?.href}`}
              showSBTBasket={showBasket}
              setShowSBTBasket={setShowBasket}
              orderCompleted={orderCompleted}
              setOrderCompleted={setOrderCompleted}
              setTtripCreated={setTtripCreated}
            />
          </Row>
        </FiltersProvider>
        {newTravellerButton && (
          <TravellersFormIFrame
            src={newTravellerButton.href}
            visible={travellersFormIFrameIsVisible}
            closeModal={closeTravellerFormIFrame}
            setNewTravellerIdCreated={setNewTravellerIdCreated}
          />
        )}
      </Content>
    </Layout>
  );
};

export default SelfBookingTool;
