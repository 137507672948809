import { State, initialState, ActionTypes, SETCONFIG } from "./types";

export const configReducer = (
  state = initialState,
  action: ActionTypes
): State => {
  switch (action.type) {
    case SETCONFIG:
      return action.payload;
    default:
      return state;
  }
};
