import React, { FunctionComponent, useState, useEffect } from "react";
import { Skeleton, Space } from "@triporate/triporate-design-system";

import { getArticles } from "../../../services/Articles";
import { ArtcilesData } from "./types";
import Article from "./Article";

import "./Articles.scss";

type ArticlesProps = {
  url?: string;
};

const Articles: FunctionComponent<ArticlesProps> = ({ url }): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [articlesData, setArticles] = useState<ArtcilesData>();
  const [expand, setEllipsis] = useState(true);
  const [articleId, setArticleId] = useState(0);

  const handleFetch = async (urlStr: string) => {
    setLoading(true);
    const { data } = await getArticles(urlStr);
    if (data) {
      setArticles(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (url) handleFetch(url);
  }, [url]);

  const handleArticleExpand = (id: number) => {
    if (articleId !== id) setArticleId(id);
    if (articleId === id) {
      setEllipsis(!expand);
      setArticleId(0);
    }
  };
  return (
    <div className="client-messages-container">
      {loading ? (
        <Skeleton paragraph={{ rows: 2 }} active />
      ) : (
        <div className="client-messages-body">
          <Space direction="vertical" className="space-width">
            {articlesData?.articles?.map((articleData) => (
              <Article
                key={articleData.id}
                data={articleData}
                expandedArticleId={articleId}
                handleArticleExpand={handleArticleExpand}
              />
            ))}
          </Space>
        </div>
      )}
    </div>
  );
};

export default Articles;
