/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Checkbox,
  Typography,
  Price,
  Space,
} from "@triporate/triporate-design-system";
import {
  PriceSizeEnum,
  validateTextTypeString,
} from "../../../../../../../../utils";
import { SelectedPriority, SelectedId } from "../../../types";
import { Extra } from "../../../../../../../../services/Results/getTransportAncillaries/types";

const { Text } = Typography;
type PriorityBoardingProps = {
  selectedPriority: SelectedPriority;
  setSelectedPriority: (value: SelectedPriority) => void;
  priorityBoardingData: any;
  pax: number;
  selectedId: SelectedId;
  setSelectedId: (value: SelectedId) => void;
};

const PriorityBoarding: FunctionComponent<PriorityBoardingProps> = ({
  selectedPriority,
  setSelectedPriority,
  priorityBoardingData,
  pax,
  selectedId,
  setSelectedId,
}) => {
  const handlePriority = (
    e: any,
    segmentId: string,
    priority: unknown,
    passenger: number,
    id: string
  ) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const PriorityAdded = {
        solutionId: e.target.value,
        journey: e.target.value,
        price: e.target["data-value"].price,
        priorityData: priority,
        pax: passenger,
        id,
      };

      setSelectedPriority([...selectedPriority, PriorityAdded]);
      setSelectedId([...selectedId, id]);
    } else {
      _.remove(
        selectedPriority,
        (seg) => seg.journey === segmentId && seg.pax === pax
      );
      _.remove(selectedId, (seg) => seg === id);
      setSelectedPriority([...selectedPriority]);
      setSelectedId([...selectedId]);
    }
  };
  return (
    <Col span={24}>
      {priorityBoardingData.map(
        (priorityBoardingOpt: Extra, priorityInd: number) => (
          <Space
            direction="vertical"
            size="middle"
            className="space-witdh-100"
            key={priorityInd}
          >
            <Row
              align="middle"
              className="priority-boarding-row"
              justify="space-between"
              key={priorityInd}
            >
              {priorityBoardingOpt.options.map(
                (priorityOpt: any, priorityOptInd: number) => {
                  const id = `${priorityInd}-${pax}`;
                  return (
                    <>
                      <Col span={5} key={`${priorityInd}-${priorityOptInd}`}>
                        <Checkbox
                          value={priorityBoardingOpt.journeyId}
                          data-value={priorityOpt.data}
                          onChange={(e) =>
                            handlePriority(
                              e,
                              priorityBoardingOpt.journeyId,
                              priorityOpt.data,
                              pax,
                              id
                            )
                          }
                          checked={selectedId.includes(id)}
                        >
                          <Text>
                            {priorityInd === 0
                              ? priorityBoardingOpt.segmentOutbound
                              : priorityBoardingOpt.segmentReturn}
                          </Text>
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Text>{priorityOpt.name}</Text>
                      </Col>
                      <Col span={11} className="priority-boarding-price">
                        <Price
                          money={priorityOpt.price.money}
                          status={validateTextTypeString("success")}
                          label={priorityOpt.price.label}
                          size={PriceSizeEnum["S"]}
                        />
                      </Col>
                    </>
                  );
                }
              )}
            </Row>
          </Space>
        )
      )}
    </Col>
  );
};

export default PriorityBoarding;
