import { Button, Modal } from "@triporate/triporate-design-system";
import { useTranslation } from "react-i18next";

interface Props {
    isModalOpen: boolean;
    onClickButton: () => void;
}

export const IncompatibleScheduleModal = ({
    isModalOpen,
    onClickButton,
}: Props) => {
    const { t } = useTranslation();
    return (
        <Modal
            visible={isModalOpen}
            closable={false}
            footer={[
                <Button onClick={onClickButton}>
                    {t("ButtonTitleIncompatibleScheduleModal")}
                </Button>,
            ]}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <h3>{t("TitleIncompatibleScheduleModal")}</h3>
                <p>{t("SubtitleIncompatibleScheduleModal")}</p>
            </div>
        </Modal>
    );
};
