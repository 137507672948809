import React, { FunctionComponent, useState, useEffect } from "react";
import { Row, Col } from "@triporate/triporate-design-system";

import { SegmentsProps, SegmentId } from "./types";
import Segment from "./Segment";

const Segments: FunctionComponent<SegmentsProps> = ({
  segments,
  index,
  setSelectedSegment,
  ...props
}) => {
  const [selectedRadio, setSelectedRadio] = useState<SegmentId>(
    segments[0]?.optionId
  );

  useEffect(() => {
    setSelectedSegment(index, selectedRadio);
  }, [setSelectedSegment, index, selectedRadio]);

  useEffect(() => {
    setSelectedRadio(segments[0]?.optionId);
  }, [segments]);

  return (
    <Row gutter={[0, 20]}>
      {segments.map((segment, key) => (
        <Col key={key} span={24}>
          <Segment
            {...props}
            segment={segment}
            selectedRadio={selectedRadio}
            setSelectedRadio={setSelectedRadio}
          />
        </Col>
      ))}
    </Row>
  );
};
export default Segments;
