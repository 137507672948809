/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  SelectedBaggage,
  SelectedBaggagePrice,
  SelectedIdBag,
} from "../components/Results/ResultsSwitch/TransportResults/Ancillaries/types";

export const baggageExist = (
  arr: SelectedBaggage,
  seg: string,
  pax: number
) => {
  return arr.find((service) => service.journey === seg && service.pax === pax);
};

export const baggagePriceExist = (arr: SelectedBaggagePrice, id: string) => {
  return arr.find((baggage) => baggage.id === id);
};

export const baggageIdExist = (arr: SelectedIdBag, id: string) => {
  return arr.find((bag) => bag.id === id);
};

export const getBaggageName = (id: string, arr: SelectedIdBag): string => {
  const sele = baggageIdExist(arr, id);
  if (sele) {
    return sele.name;
  } else {
    return "No maletas extras";
  }
};
