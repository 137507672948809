import React, { FunctionComponent, useState, useEffect } from "react";
import { Tooltip, Button } from "@triporate/triporate-design-system";
import AuthToken from "../../../services/FetchService/AuthToken";
import { OldDiscoveryRedirect } from "../type";

type OldDiscoveryRedirectBtnProps = {
  type: string;
  data: OldDiscoveryRedirect;
};

const OldDiscoveryRedirectBtn: FunctionComponent<
  OldDiscoveryRedirectBtnProps
> = ({ type, data }) => {
  const { href, validTypes, param, query, tooltip, content } = data;
  const [showBtn, setShowBtn] = useState(false);
  const [token, setToken] = useState<string>();

  useEffect(() => {
    const authTokenService = new AuthToken();
    const authToken = authTokenService.getToken();
    if (authToken) {
      setToken(authToken);
    }
  }, []);

  useEffect(() => {
    setShowBtn(validTypes.includes(type));
  }, [type, validTypes]);

  if (!showBtn || !token) {
    return <></>;
  }

  const handleClick = () => {
    const formatedHref = href.replace(param, type).replace(query, token);
    window.open(formatedHref, "_blank");
  };

  return (
    <Tooltip title={tooltip}>
      <Button
        type="primary"
        onClick={handleClick}
        className="old-discovery-redirect"
      >
        {content}
      </Button>
    </Tooltip>
  );
};

export default OldDiscoveryRedirectBtn;
