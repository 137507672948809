import React, { FunctionComponent } from "react";
import {
    Row,
    Col,
    Price,
    Tooltip,
    Tag,
    Image,
} from "@triporate/triporate-design-system";

import { validateTextTypeString } from "../../../../../utils";
import { BuyButtonData, SegmentId } from "../types";

import BuyButton from "../BuyButton";
import { useRoles, useCanIUseByRole } from "../../../../../hooks";
import { lastTicketDateInfo } from "../../../../../services/Results/getTransportResults/type";

type BuyInfoProps = {
    selectedSegmentsIds: {
        [key: number]: SegmentId;
    };
    data: BuyButtonData;
    provider: string;
    lastTicketDate: lastTicketDateInfo;
    isResidentCandidate?: boolean;
    label?: string;
};

const BuyInfo: FunctionComponent<BuyInfoProps> = ({
    selectedSegmentsIds,
    data,
    provider,
    lastTicketDate,
    isResidentCandidate,
    label,
}) => {
    const [roles] = useRoles();
    const [canTravelManagerUse, loadingCanTravelManagerUse] = useCanIUseByRole(
        roles.travelManager
    );
    const [canTravellerUse, loadingCanTravellerUse] = useCanIUseByRole(
        roles.traveller
    );
    const [canTravelAgentUse, loadingCanTravelAgentUse] = useCanIUseByRole(
        roles.agent
    );

    const canDisplayInSBT =
        loadingCanTravelManagerUse ||
        canTravelManagerUse ||
        loadingCanTravellerUse ||
        canTravellerUse;

    return (
        <>
            <Row align="top" gutter={[0, 8]} style={{ textAlign: "center" }}>
                {(loadingCanTravelAgentUse || canTravelAgentUse) && (
                    <Col span={24}>
                        {provider === "" && <span>{label}</span>}
                        <Image src={provider} width={80} preview={false} />
                    </Col>
                )}
                <Col span={24}>
                    <Price
                        money={{ ...data.col1.price.money, currency: "EUR" }}
                        status={validateTextTypeString(data.col1.price.icon)}
                        label={data.col1.price.label}
                    />
                </Col>
                <Col span={24}>
                    {canTravelAgentUse && (
                        <Tooltip placement="top" title={lastTicketDate.tooltip}>
                            <Tag className="lastTicketDate">
                                {lastTicketDate.date}
                            </Tag>
                        </Tooltip>
                    )}
                </Col>

                <Col span={24}>
                    {canDisplayInSBT && (
                        <BuyButton
                            selectedSegmentsIds={selectedSegmentsIds}
                            label={"Comprar"}
                            href={data.col2.href}
                            isResidentCandidate={isResidentCandidate}
                            residentsHref={data.col3?.residentsHref}
                            residentsCode={data.col3?.residentsCode}
                        />
                    )}
                    {(loadingCanTravelAgentUse || canTravelAgentUse) && (
                        <BuyButton
                            selectedSegmentsIds={selectedSegmentsIds}
                            label={"Agregar"}
                            href={data.col2.href}
                            isResidentCandidate={isResidentCandidate}
                            residentsHref={data.col3?.residentsHref}
                            residentsCode={data.col3?.residentsCode}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default BuyInfo;
