import React, { FunctionComponent } from "react";
import { Space } from "@triporate/triporate-design-system";
import { Item as ItemType } from "../../../types";
import Item from "./Item";

type SummaryItemsProps = {
  item: ItemType;
};

const SummaryItems: FunctionComponent<SummaryItemsProps> = ({
  item,
}): JSX.Element => {
  return (
    <Space align="center" direction="vertical">
      <Item data={item} />
    </Space>
  );
};

export default SummaryItems;
