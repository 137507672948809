import React, { FunctionComponent, useEffect, useState } from "react";
import { InputData, Fields } from "../../../../types";
import { FormItem } from "..";
import { DatePicker } from "@triporate/triporate-design-system";
import { validateArrayOfStringsPlaceholder } from "../../utils";
import moment, { Moment } from "moment";
import { isValidDate } from "../../utils/validateDates";

const { RangePicker } = DatePicker;

type RangePickerProps = {
  inputName: string;
  inputData: InputData;
  setFieldsValue: (values: Fields) => void;
  showTime?: boolean;
};

const RangePickerComponent: FunctionComponent<RangePickerProps> = ({
  inputName,
  inputData,
  setFieldsValue,
  showTime,
}): JSX.Element => {
  const { defaultValue, placeholder } = inputData || {};
  const [tripDates, setTripDates] = useState<Moment[]>();

  useEffect(() => {
    if (Array.isArray(defaultValue)) {
      const [fromDate, toDate] = defaultValue;

      setTripDates(isValidDate([moment(fromDate), moment(toDate)], inputData));
    }
  }, [defaultValue, inputData]);

  useEffect(() => {
    setFieldsValue({ [inputName]: tripDates });
  }, [tripDates, inputName, setFieldsValue]);

  return (
    <FormItem inputName={inputName} inputData={inputData}>
      <RangePicker
        placeholder={validateArrayOfStringsPlaceholder(placeholder)}
        disabledDate={(current) => {
          if (!current) return false;
          return current < moment().subtract(1, "day");
        }}
        className="custom-input-width"
        showTime={
          showTime && {
            defaultValue: [moment("10:00", "HH:mm"), moment("10:00", "HH:mm")],
          }
        }
        format={showTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"}
      />
    </FormItem>
  );
};

export default RangePickerComponent;
