import { store } from "../store";
import { handleSetRole, Roles } from "../store/role";

export const handleSetRoleDispatch = (role?: string): void => {
  const isValidRole = role && role in Roles;
  if (isValidRole) {
    const validRole = role as keyof typeof Roles;
    store.dispatch(handleSetRole(validRole));
  } else {
    store.dispatch(handleSetRole(Roles.invalid));
  }
};
