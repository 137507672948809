import { handleSetRoleDispatch } from "../../utils";
import { FetchService } from "../FetchService";
import mockData from "./agentBookingToolMockData";
import { AgentBookingToolData } from "./types";

interface Response {
  error?: boolean;
  data?: AgentBookingToolData;
}

const generateMockData = () => mockData;

export async function getAgentBookingToolData(
  tripId: string,
  product: string
): Promise<Response> {
  const fetchService = new FetchService();

  if (fetchService.useFakeApi) {
    const data = generateMockData();
    handleSetRoleDispatch(data?.role);

    return { data };
  }

  if (!fetchService.baseUrl) return { error: true };
  const { data, error } = await fetchService.get(
    fetchService.baseUrl + `?tripId=${tripId}&product=${product}&mode=ABT`
  );

  if (!error) {
    const castedData = data as Response["data"];
    handleSetRoleDispatch(castedData?.role);
    return { data: castedData };
  }

  return { error };
}
