import React, { FunctionComponent, useContext } from "react";
import { Button, Modal, Typography } from "@triporate/triporate-design-system";
import Context from "../Context";
import { ResidentCandidateModalInfo } from "../types";
import "./ResidentsModal.scss";
const { Paragraph, Text } = Typography;

type ResidentsModalProps = {
  segments: {
    [key: number]: string;
  };
  isResidentsModal: boolean;
  setIsResidentsModal: (value: boolean) => void;
  buyHref: string;
  residentsCode?: string;
  data?: ResidentCandidateModalInfo;
};

const ResidentsModal: FunctionComponent<ResidentsModalProps> = ({
  segments,
  isResidentsModal,
  setIsResidentsModal,
  data,
  buyHref,
  residentsCode,
}): JSX.Element => {
  const { onBuyButtonClick } = useContext(Context);

  const handleCloseModal = () => {
    onBuyButtonClick(buyHref, segments);
    setIsResidentsModal(!isResidentsModal);
  };

  const handleDiscountAgree = () => {
    onBuyButtonClick(buyHref, segments, residentsCode);
    setIsResidentsModal(!isResidentsModal);
  };

  return (
    <Modal
      maskClosable={false}
      closable={false}
      className="residentCandidate"
      visible={isResidentsModal}
      onCancel={handleCloseModal}
      onOk={handleDiscountAgree}
      footer={[
        <Button key="link" onClick={handleCloseModal}>
          {data?.btnNo}
        </Button>,
        <Button key="back" type="primary" onClick={handleDiscountAgree}>
          {data?.btnYes}
        </Button>,
      ]}
    >
      <Paragraph strong>{data?.title}</Paragraph>
      <Paragraph>
        <Text>{data?.text} </Text>
        <Text strong> {data?.codeName} </Text>
        <Text>? </Text>
      </Paragraph>
      <Paragraph>
        <Text>{data?.note} </Text>
      </Paragraph>
    </Modal>
  );
};

export default ResidentsModal;
