export class ClientConfigApiUrl {
  private windowHost: string;
  private localhost = "localhost";
  private apiPort = 9000;
  private apiSubdomain = "api";
  private clientConfigUri = "/v2/client-config";

  public constructor(windowHost: string) {
    this.windowHost = windowHost;
  }

  private computeApiHost(mySubDomain: string, targetSubDomain: string) {
    return this.windowHost.replace(mySubDomain, targetSubDomain);
  }

  private computeApiLocalHost() {
    return `${this.localhost}:${this.apiPort}`;
  }

  public getUrl(
    mySubDomain: string,
    options?: { targetSubDomain?: string; uri?: string }
  ) {
    const uri = options?.uri || this.clientConfigUri;
    const targetSubDomain = options?.targetSubDomain || this.apiSubdomain;

    if (this.windowHost.includes(this.localhost)) {
      const apiLocalHost = this.computeApiLocalHost();
      return `http://${apiLocalHost}${uri}/${this.windowHost}`;
    }
    const apiHost = this.computeApiHost(mySubDomain, targetSubDomain);
    return `https://${apiHost}${uri}/${this.windowHost}`;
  }
}
