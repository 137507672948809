import { FetchService } from "../FetchService";

type Option = {
  label: string;
  value: string;
  tooltip?: string;
  disabled?: boolean;
};

type OptionsGroup = {
  groupName?: string;
  options: Option[];
};

interface Response {
  error?: boolean;
  data?: OptionsGroup[];
}

const handleMockData = async (
  url: string,
  searchValue: string
): Promise<Response> => {
  const fetchService = new FetchService();

  const { data } = await fetchService.get(`${url}${searchValue}`);

  let formatedData: Option[] = [];
  if (url.includes("/address")) {
    const castedData = data as { description: string; place_id: string }[];
    formatedData = castedData.map((location) => ({
      label: location.description,
      value: location.place_id,
    }));
  } else {
    const castedData = data as {
      name: string;
      geo: { lat: number; lng: number };
    }[];
    formatedData = castedData.map((location) => ({
      label: location.name,
      value: `${location.geo.lat},${location.geo.lng}`,
    }));
  }

  return { data: [{ options: formatedData }] };
};

export async function getSelectSearchAsyncOptions(
  url: string,
  searchValue: string
): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData(url, searchValue);

  const { data, error } = await fetchService.get(`${url}${searchValue}`);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
