/* eslint-disable max-lines */
import React, { FunctionComponent } from "react";
import {
  Button,
  Col,
  GeneralFilters,
  Row,
  Tag,
  Typography,
  Space,
} from "@triporate/triporate-design-system";
import { InputData, FormValues, Fields, SubmitData } from "../../../../types";
import { HotelsInputFields } from "../../../constants";
import {
  FormItem,
  FetchSelect,
  RangePicker,
  PaxNumberInput,
  SelectSearchAsyncOptions,
} from "../../CommonComponents";
import PoiAddressInputs from "./PoiAddressInputs";
import RoomsInput from "./RoomsInput";
import { getSelectSearchAsyncOptions } from "../../../../../../services/SearchForm";
import SubmitButton from "../../../SubmitButton";
const { Text } = Typography;

type HotelInputsProps = {
  isABT: boolean;
  inputs: { [key: string]: InputData };
  formValues: FormValues;
  isFirstSubmit: boolean;
  addNewTraveller?: SubmitData;
  showTravellerFormIFrame?: () => void;
  newTravellerIdCreated?: string;
  getFieldValue: (inputName: string) => unknown;
  setFieldsValue: (values: Fields) => void;
  isEstimate?: boolean;
  estimateLabel?: string;
  validateFields: (value: string[]) => void;
  label: string;
  onSubmit: () => void;
};

const HotelInputs: FunctionComponent<HotelInputsProps> = ({
  isABT,
  inputs,
  formValues,
  isFirstSubmit,
  addNewTraveller,
  showTravellerFormIFrame,
  newTravellerIdCreated,
  getFieldValue,
  setFieldsValue,
  isEstimate,
  estimateLabel,
  validateFields,
  label,
  onSubmit,
}): JSX.Element => {
  return (
    <>
      <Col span={24}>
        <Row className="form-top-row hotels">
          <Col className="searchInput-hotels_top">
            {isEstimate && (
              <Tag color="cyan" className="tag-hotels">
                {estimateLabel}
              </Tag>
            )}
          </Col>
          <Col span={20} className="searchInput-hotels_top filters">
            <GeneralFilters
              getSelectSearchAsyncOptions={getSelectSearchAsyncOptions}
            />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <div className="form-midlle-div">
          <Row>
            {isABT ? (
              <PoiAddressInputs
                poiName={HotelsInputFields.poi}
                hotelName={HotelsInputFields.hotel}
                locationName={HotelsInputFields.location}
                inputs={inputs}
                formValues={formValues}
                isFirstSubmit={isFirstSubmit}
                getFieldValue={getFieldValue}
                setFieldsValue={setFieldsValue}
                validateFields={validateFields}
                distanceInputName={HotelsInputFields.distance}
                datesInputName={HotelsInputFields.dates}
              />
            ) : (
              <div className="sbt-search-row">
                <Space size={"middle"} align="baseline">
                  <div className="sbt-search-inputs">
                    <Space size={"middle"} align="baseline">
                      <Col>
                        <div className="sbt-search-width">
                          <FormItem
                            inputName={HotelsInputFields.smartSearch}
                            inputData={inputs[HotelsInputFields.smartSearch]}
                          >
                            <SelectSearchAsyncOptions
                              formValues={formValues}
                              inputData={inputs[HotelsInputFields.smartSearch]}
                              inputName={HotelsInputFields.smartSearch}
                              setFieldsValue={setFieldsValue}
                            />
                          </FormItem>
                        </div>
                      </Col>
                      <Col>
                        <FormItem
                          inputName={HotelsInputFields.distance}
                          inputData={inputs[HotelsInputFields.distance]}
                        >
                          <FetchSelect
                            formValues={formValues}
                            inputData={inputs[HotelsInputFields.distance]}
                            inputName={HotelsInputFields.distance}
                            getFieldValue={getFieldValue}
                            setFieldsValue={setFieldsValue}
                          />
                        </FormItem>
                      </Col>
                    </Space>
                  </div>
                  <div>
                    <Col>
                      <div className="dates-input_width">
                        <RangePicker
                          inputName={HotelsInputFields.dates}
                          inputData={inputs[HotelsInputFields.dates]}
                          setFieldsValue={setFieldsValue}
                          showTime={false}
                        />
                      </div>
                    </Col>
                  </div>
                </Space>
              </div>
            )}
          </Row>
        </div>
      </Col>

      <Col span={24}>
        <div className="form-midlle-div">
          <Space size={"middle"} align="baseline">
            {inputs.usersEstimate?.isEstimate ? (
              <div className="searchInput_estimate inputNumber-width">
                <Text>{inputs.usersEstimate?.label}</Text>
                <PaxNumberInput
                  inputName={HotelsInputFields.usersEstimate}
                  inputData={inputs[HotelsInputFields.usersEstimate]}
                  setFieldsValue={setFieldsValue}
                />
              </div>
            ) : (
              <div className="travellers-input_width">
                <FormItem
                  inputName={HotelsInputFields.users}
                  inputData={inputs[HotelsInputFields.users]}
                >
                  <FetchSelect
                    inputName={HotelsInputFields.users}
                    inputData={inputs[HotelsInputFields.users]}
                    formValues={formValues}
                    setFieldsValue={setFieldsValue}
                    getFieldValue={getFieldValue}
                    addNewOption={newTravellerIdCreated}
                  />
                </FormItem>
              </div>
            )}

            {addNewTraveller && (
              <Button onClick={showTravellerFormIFrame}>
                {addNewTraveller.label}
              </Button>
            )}

            <div className="inputNumber-width">
              <RoomsInput
                inputName={HotelsInputFields.rooms}
                inputData={inputs[HotelsInputFields.rooms]}
                formValues={formValues}
                setFieldsValue={setFieldsValue}
                travellersFieldName={
                  inputs.usersEstimate?.isEstimate
                    ? HotelsInputFields.usersEstimate
                    : HotelsInputFields.users
                }
              />
            </div>

            <div className={isABT ? "submit-column mb" : "submit-column"}>
              <SubmitButton label={label} onSubmit={onSubmit} />
            </div>
          </Space>
        </div>
      </Col>
    </>
  );
};
export default HotelInputs;
