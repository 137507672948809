import React, { FunctionComponent } from "react";
import { Modal } from "@triporate/triporate-design-system";
import OfficeInfo from "./OfficeInfo";
import { CarOfficeInfo } from "../InputGroupSwitch/InputGroups/CarInputs/types";

type InfoModalProps = {
  closeModal: () => void;
  isVisible: boolean;
  data?: CarOfficeInfo;
};

const InfoModal: FunctionComponent<InfoModalProps> = ({
  closeModal,
  isVisible,
  data,
}): JSX.Element => {
  if (!data) return <></>;
  return (
    <Modal
      closable={false}
      visible={isVisible}
      onOk={closeModal}
      okText="Cerrar"
      width={700}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <OfficeInfo data={data} />
    </Modal>
  );
};

export default InfoModal;
