/* eslint-disable no-underscore-dangle */
import { createStore, combineReducers, compose } from "redux";
import { authReducer } from "./auth";
import { roleReducer } from "./role";
import { configReducer } from "./config";

const rootReducer = combineReducers({
  auth: authReducer,
  role: roleReducer,
  config: configReducer,
});

export const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export type RootState = ReturnType<typeof rootReducer>;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: () => typeof compose;
  }
}
