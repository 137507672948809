/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Typography,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";
import BaggageByPassenger from "./BaggageByPassenger";
import BaggageByPassengerJourney from "./BaggageByPassengerJourney";
import {
  SelectedBaggage,
  SelectedBaggagePrice,
  SelectedIdBag,
} from "../../../types";
import {
  baggageExist,
  baggageIdExist,
  baggagePriceExist,
} from "../../../../../../../../utils";

const { Text } = Typography;

type BaggageProps = {
  selectedBaggage: SelectedBaggage;
  setSelectedBaggage: (value: SelectedBaggage) => void;
  baggageData: any;
  pax: number;
  selectedBaggagePrice: SelectedBaggagePrice;
  setSelectedBaggagePrice: (value: SelectedBaggagePrice) => void;
  selectedIdBag: SelectedIdBag;
  setSelectedIdBag: (value: SelectedIdBag) => void;
};

const Baggage: FunctionComponent<BaggageProps> = ({
  selectedBaggage,
  setSelectedBaggage,
  baggageData,
  pax,
  selectedBaggagePrice,
  setSelectedBaggagePrice,
  selectedIdBag,
  setSelectedIdBag,
}) => {
  const handleBaggeChange = (e: any, value: any, passenger: number) => {
    const { seg, id } = value;
    const baggageSelectedData = value["data-value"].data;
    const priceObj = value["data-value"].price;
    const baseSeg = Array.isArray(seg) ? seg[0] : seg;
    const segRef =
      !_.isEmpty(baggageSelectedData) || undefined
        ? baggageSelectedData.journey_references[0]
        : baseSeg;
    const nameRef =
      !_.isEmpty(baggageSelectedData) || undefined
        ? baggageSelectedData.short_name
        : "NB";

    const existBaggage = baggageExist(selectedBaggage, segRef, passenger);
    const existBaggagePrice = baggagePriceExist(selectedBaggagePrice, id);
    const existBaggageId = baggageIdExist(selectedIdBag, id);

    const baggagePriceObj = {
      seg: segRef,
      price: priceObj,
      id,
      name: nameRef,
    };

    const baggegObj = {
      solutionId: segRef,
      journey: segRef,
      price: baggageSelectedData ? baggageSelectedData.total : 0,
      baggageData: baggageSelectedData ? baggageSelectedData : {},
      pax: passenger,
      id,
    };

    const baggegIdObj = {
      id,
      name: baggageSelectedData
        ? baggageSelectedData.short_name
        : "No maletas extras",
    };

    if (nameRef === "NB" && existBaggage && existBaggagePrice) {
      _.remove(selectedBaggage, existBaggage);
      _.remove(selectedBaggagePrice, existBaggagePrice);
      _.remove(selectedIdBag, existBaggageId);
      setSelectedBaggage([...selectedBaggage]);
      setSelectedIdBag([...selectedIdBag]);
      setSelectedBaggagePrice([...selectedBaggagePrice]);
    } else if (!existBaggage && !existBaggagePrice) {
      setSelectedBaggage([...selectedBaggage, baggegObj]);
      setSelectedBaggagePrice([...selectedBaggagePrice, baggagePriceObj]);
      setSelectedIdBag([...selectedIdBag, baggegIdObj]);
    } else {
      _.remove(selectedBaggage, existBaggage);
      _.remove(selectedBaggagePrice, existBaggagePrice);
      _.remove(selectedIdBag, existBaggageId);
      setSelectedBaggage([...selectedBaggage, baggegObj]);
      setSelectedBaggagePrice([...selectedBaggagePrice, baggagePriceObj]);
      setSelectedIdBag([...selectedIdBag, baggegIdObj]);
    }
  };
  const hasBaggage = baggageData && baggageData.length > 0;

  return (
    <Col span={24}>
      {hasBaggage && baggageData[0].label === "ByPassenger" ? (
        <Row
          align="middle"
          className="priority-boarding-row"
          justify="space-between"
        >
          <Col span={9}>
            {baggageData[0] && (
              <Text>
                <Icons icon={validateIconType("arrowRight")} />
                {baggageData[0].segmentOutbound}
              </Text>
            )}
            {baggageData[1] && (
              <div>
                <Text>
                  <Icons icon={validateIconType("arrowLeft")} />
                  {baggageData[1].segmentReturn}
                </Text>
              </div>
            )}
          </Col>
          <BaggageByPassenger
            baggage={baggageData}
            handleBaggeChange={handleBaggeChange}
            pax={pax}
            ind={0}
            selectedBaggagePrice={selectedBaggagePrice}
            selectedIdBag={selectedIdBag}
          />
        </Row>
      ) : (
        <>
          {hasBaggage &&
            baggageData.map((baggageOpt: any, priorityInd: number) => {
              return (
                <Row
                  align="middle"
                  className="priority-boarding-row"
                  justify="space-between"
                  key={priorityInd}
                >
                  <Col span={9}>
                    {baggageOpt.segmentOutbound && (
                      <Text>
                        <Icons icon={validateIconType("arrowRight")} />
                        {baggageOpt.segmentOutbound}
                      </Text>
                    )}

                    {baggageOpt.segmentReturn && (
                      <Text>
                        <Icons icon={validateIconType("arrowLeft")} />
                        {baggageOpt.segmentReturn}
                      </Text>
                    )}
                  </Col>
                  <BaggageByPassengerJourney
                    handleBaggeChange={handleBaggeChange}
                    pax={pax}
                    baggage={baggageOpt}
                    ind={priorityInd}
                    selectedBaggagePrice={selectedBaggagePrice}
                    selectedIdBag={selectedIdBag}
                  />
                </Row>
              );
            })}
        </>
      )}
    </Col>
  );
};

export default Baggage;
