import React, { FunctionComponent } from "react";
import {
  Row,
  Col,
  Typography,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";
import { TrainJourneyData } from "../types";

const { Text } = Typography;

type JourneyAncilliariesProps = {
  data?: TrainJourneyData["ancilliaries"];
};

const JourneyAncilliaries: FunctionComponent<JourneyAncilliariesProps> = ({
  data,
}) => {
  return (
    <Row>
      {data?.map(({ icon, text }, key) => (
        <Col key={key} span={24}>
          <Text>
            <Icons icon={validateIconType(icon)} />
            {text}
          </Text>
        </Col>
      ))}
    </Row>
  );
};

export default JourneyAncilliaries;
