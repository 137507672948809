import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Row,
  Col,
  Icons,
  validateIconType,
  Typography,
  Input,
  Space,
} from "@triporate/triporate-design-system";

const { Text, Paragraph } = Typography;
const { TextArea } = Input;

import "./HotelInfo.scss";
import { HotelData } from "../../../../../../services/Results/getConfirmationDrawerData/types";
import { SummaryNotes } from "../../types";
import { ExtraInfoData } from "../types";

type HotelInfoProps = {
  data?: HotelData;
  handleHotelNotes: (data: SummaryNotes) => void;
};

const HotelInfo: FunctionComponent<HotelInfoProps> = ({
  data,
  handleHotelNotes,
}) => {
  const { field1 } = data?.notesForHotel as ExtraInfoData;

  const [textData, setTextData] = useState({
    [field1.name]: field1.value,
  });

  useEffect(() => {
    if (handleHotelNotes && textData) handleHotelNotes(textData);
  }, [textData, handleHotelNotes]);

  useEffect(() => {
    setTextData({
      [field1.name]: field1.value,
    });
  }, [field1]);

  const handleTextUpdate = (key: string, text: string) => {
    setTextData((prevState) => ({ ...prevState, [key]: text }));
  };

  return (
    <>
      <Row>
        <Col span={13}>
          <Space size="small" direction="vertical" style={{ width: "100%" }}>
            <Row gutter={[0, 24]}>
              <Col span={13}>
                <Icons icon={validateIconType(data?.hotel.icon.class)} />
                <Text>{data?.hotel.name}</Text>
              </Col>
              <Col span={11}>
                <>
                  <Icons
                    icon={validateIconType(data?.destination.icon.class)}
                  />
                  <Text>{data?.destination.hotelAddress}</Text>
                </>
              </Col>
            </Row>
            <Row gutter={[0, 24]}>
              <Col span={13}>
                <Icons icon={validateIconType(data?.dates.icon.class)} />
                <Text>{data?.dates.date}</Text>
              </Col>
              <Col span={11}>
                <Icons icon={validateIconType(data?.duration.icon.class)} />
                <Text>{data?.duration.days}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 24]}>
              <Col span={13}>
                <Icons icon={validateIconType(data?.room.icon.class)} />
                <Text>
                  {data?.room.roomQty} x {data?.room.roomType}
                </Text>
              </Col>
              <Col span={11}>
                <Icons icon={validateIconType(data?.regime.icon.class)} />
                <Text>{data?.regime.regimeType}</Text>
              </Col>
            </Row>
            <Row gutter={[0, 24]}>
              <Col span={13}>
                <Icons icon={validateIconType(data?.cancellation.icon.class)} />
                <Text>{data?.cancellation.cancellationType}</Text>
                <Paragraph>
                  <Text>{data?.cancellation.cancellationDate}</Text>
                </Paragraph>
              </Col>
            </Row>
          </Space>
        </Col>
        <Col span={11}>
          <label htmlFor={data?.notesForHotel.field1.name}>
            <Text strong>{data?.notesForHotel.field1.label}</Text>
          </label>
          <TextArea
            autoSize={{ minRows: 4 }}
            id={field1.name}
            showCount
            maxLength={field1.maxLength || 100}
            value={textData[field1.name]}
            placeholder={field1.placeholder}
            onChange={(value) =>
              handleTextUpdate(field1.name, value.target.value)
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default HotelInfo;
