import React, { FunctionComponent } from "react";
import {
  Row,
  Col,
  Typography,
  Space,
  Price,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";
import CancellationDate from "./CancellationDate";
import { validateTextTypeString } from "../../../../../../utils";
import BuyButton from "../../BuyButton";
import { OtherSolution as OtherSolutionType } from "../../types";
import { useCanIUseByRole, useRoles } from "../../../../../../hooks";

const { Text, Title } = Typography;

type OtherSolutionContentProps = {
  index: number;
  option: OtherSolutionType;
  groupName: string;
};

const OtherSolutionContent: FunctionComponent<OtherSolutionContentProps> = ({
  option,
  groupName,
  index,
}): JSX.Element => {
  const [roles] = useRoles();
  const [canTravelManagerUse, loadingCanTravelManagerUse] = useCanIUseByRole(
    roles.travelManager
  );
  const [canTravellerUse, loadingCanTravellerUse] = useCanIUseByRole(
    roles.traveller
  );
  const [canTravelAgentUse, loadingCanTravelAgentUse] = useCanIUseByRole(
    roles.agent
  );

  const canDisplayInSBT =
    loadingCanTravelManagerUse ||
    canTravelManagerUse ||
    loadingCanTravellerUse ||
    canTravellerUse;
  return (
    <Row align="middle" className="option-container">
      <Col span={5}>
        {index === 0 && (
          <Title ellipsis={{ tooltip: true }} level={5}>
            {groupName}
          </Title>
        )}
      </Col>
      <Col span={3}>
        <Row justify="center">
          <Col>
            <Icons
              icon={validateIconType(option.col2.quantity.pax.icon.class)}
            />
            <Text ellipsis={{ tooltip: true }}>
              {option.col2.quantity.pax.number}
            </Text>
            <Icons
              icon={validateIconType(option.col2.quantity.rooms.icon.class)}
            />
            <Text ellipsis={{ tooltip: true }}>
              {option.col2.quantity.rooms.number}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        <Text ellipsis={{ tooltip: true }} strong>
          {option.col1.text}
        </Text>
      </Col>
      <Col span={4}>
        <Row>
          <Col span={24}>
            <Text
              ellipsis={{ tooltip: true }}
              type={validateTextTypeString(option.col3.cancellation.type)} // INNER CANCEL
              className="cancellation-title"
              strong
            >
              {option.col3.cancellation.text}
              {option.col3.cancellation.endpoint && (
                <CancellationDate
                  url={option.col3.cancellation.endpoint}
                  icon={option.col3.cancellation.icon?.class || ""}
                />
              )}
            </Text>
          </Col>
        </Row>
      </Col>

      <Col span={3}>
        <Price
          money={{ ...option.col4.price.money, currency: "EUR" }}
          status={validateTextTypeString(option.col4.price.status)} // INNER PRICE
          label={option.col4.price.label}
          // TP-3755: REVIEW
          //policy={option.col4.price.policy?.text}
        />
      </Col>
      <Col span={3}>
        <Space align="center" direction="vertical" className="space-witdh-100">
          {canDisplayInSBT && <BuyButton {...option.col5} />}
          {(loadingCanTravelAgentUse || canTravelAgentUse) && (
            <BuyButton {...option.col5} />
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default OtherSolutionContent;
