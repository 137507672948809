import TagManager from "react-gtm-module";
export const addTagManager = (name: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "sbt_init",
      slug: name.toLowerCase(),
    },
    dataLayerName: "AgentBookingTool",
  });
};
