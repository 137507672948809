import React, { FunctionComponent } from "react";
import {
  Row,
  Col,
  Image,
  Typography,
} from "@triporate/triporate-design-system";
import { TrainJourneyData } from "../types";

import "./TrainJourneySegment.scss";

type JourneySegmentProps = {
  data?: TrainJourneyData["segment"];
};

const { Text, Title } = Typography;

const JourneySegment: FunctionComponent<JourneySegmentProps> = ({ data }) => {
  if (!data) return <></>;

  return (
    <Row className="segment_row">
      <Col span={5}>
        <Row>
          <Col>
            <Image height={40} src={data.src} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Text>{data.transportNumber}</Text>
          </Col>
        </Row>
      </Col>
      <Col span={19}>
        <Row>
          <Col span={7} className="departure_col">
            <Title level={3}>{data.departure.text}</Title>
            <Text>{data.departure.time}</Text>
          </Col>
          <Col span={10} className="separation_col">
            <div className="separation_div" />
          </Col>
          <Col span={7} className="destination_col">
            <Title level={3}>{data.arrival.text}</Title>

            <Text>{data.arrival.time}</Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default JourneySegment;
