/* eslint-disable max-lines */
import React, { FunctionComponent } from "react";
import {
    Row,
    Col,
    Typography,
    Badge,
    Tooltip,
} from "@triporate/triporate-design-system";

import { SegmentJourney } from "../types";

const { Text, Paragraph } = Typography;

type JourneyInfoProps = {
    data: SegmentJourney;
};

const JourneyInfo: FunctionComponent<JourneyInfoProps> = ({
    data,
}): JSX.Element => (
    <Row className="journey-info">
        <Col span={8} className="centered-col">
            <Tooltip placement="top" title={data.col2.tooltip}>
                <Badge
                    count={data.col2.count}
                    offset={[12, 0]}
                    size="small"
                    className="days-count"
                >
                    <Text strong className="centered-text">
                        {data.col2.text}
                    </Text>
                </Badge>
            </Tooltip>
        </Col>
        <Col span={16} className="centered-col">
            <Paragraph
                className="paragraph-mb-0"
                ellipsis={{
                    rows: 2,
                    expandable: false,
                    tooltip: true,
                }}
            >
                {data.col3.text}
            </Paragraph>
        </Col>
    </Row>
);

export default JourneyInfo;
