import {
  FiltersConfig,
  FiltersTripTypeGroup,
  CurrentInputsData,
} from "./types";

export enum FilterType {
  bySolution = "bySolution", // Only 1 filter input by flight
  byJourney = "byJourney", // filters by journeys
}

function formatTag(text: string, replaceText: string): string {
  return text.replace("{type}", replaceText);
}

function handleTrip(rawfiltersConfig: FiltersConfig) {
  return rawfiltersConfig.map((config) => ({
    ...config,
    tag: config.tag,
    path: config.path,
    group: config.category,
  }));
}

function handleOneWayTrip(
  rawfiltersConfig: FiltersConfig,
  filtersTripTypeGroup: FiltersTripTypeGroup
) {
  const group = filtersTripTypeGroup.departure;
  return rawfiltersConfig.map(({ ...config }) => ({
    ...config,
    label: formatTag(config.label, group),
    tag: formatTag(config.tag, group),
    path: config.path.replace("{type}", "0"),
    group,
  }));
}

function handleTwoWayTrip(
  rawfiltersConfig: FiltersConfig,
  filtersTripTypeGroup: FiltersTripTypeGroup
) {
  const group1 = filtersTripTypeGroup.departure;
  const group2 = filtersTripTypeGroup.return;
  const ida = rawfiltersConfig.map((config) => {
    if (config.filterGroup)
      return {
        ...config,
        id: config.id,
        label: formatTag(config.label, group1),
        tag: formatTag(config.tag, group1),
        group: group1,
        path: config.path.replace("{type}", "0"),
      };
    return {
      ...config,
      id: `${config.id}_${group1}`,
      label: formatTag(config.label, group1),
      tag: formatTag(config.tag, group1),
      group: group1,
      path: config.path.replace("{type}", "0"),
    };
  });

  const vuelta: any[] = [];
  rawfiltersConfig.forEach((config) => {
    if (config.filterType !== FilterType.bySolution) {
      if (config.filterGroup) return vuelta;
      vuelta.push({
        ...config,
        id: `${config.id}_${group2}`,
        label: formatTag(config.label, group2),
        tag: formatTag(config.tag, group2),
        group: group2,
        path: config.path.replace("{type}", "1"),
      });
    } else return vuelta;
  });
  return [...ida, ...vuelta];
}

function handleMultipleTrips(
  rawfiltersConfig: FiltersConfig,
  filtersTripTypeGroup: FiltersTripTypeGroup,
  fieldsAmount: number
) {
  const multidestino: FiltersConfig = [];
  for (let i = 0; i < fieldsAmount; i++) {
    const group = `${filtersTripTypeGroup.multiDestination} ${i + 1}:`;
    // To be reviewed when Multicity option is activated
    const trayecto: any[] = [];
    rawfiltersConfig.forEach(({ ...config }) => {
      // PENDING:({ autocompletedById, ...config }) para cuando se active busuqeda multicity
      if (config.filterType !== FilterType.bySolution)
        return {
          ...config,
          id: `${config.id}_${i}`,
          label: formatTag(config.label, group),
          tag: formatTag(config.tag, group),
          group,
          path: config.path.replace("{type}", `${i}`),
        };
      else return;
    });
    multidestino.push(...trayecto);
  }
  return multidestino;
}

export function adaptFilterConfigToTripType(
  rawfiltersConfig: FiltersConfig,
  currentInputsData: CurrentInputsData,
  filtersTripTypeGroup?: FiltersTripTypeGroup
): FiltersConfig {
  const { tripType, fieldsAmount } = currentInputsData;

  if (!filtersTripTypeGroup) return handleTrip(rawfiltersConfig);

  switch (tripType) {
    case "1":
      return handleOneWayTrip(rawfiltersConfig, filtersTripTypeGroup);
    case "2":
      return handleTwoWayTrip(rawfiltersConfig, filtersTripTypeGroup);
    case "3":
      return handleMultipleTrips(
        rawfiltersConfig,
        filtersTripTypeGroup,
        fieldsAmount
      );
    default:
      return [];
  }
}
