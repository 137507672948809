import React, { FunctionComponent } from "react";
import { Typography } from "@triporate/triporate-design-system";
import { HeaderData } from "../types";
import LoadingHeader from "./LoadingHeader";

const { Title } = Typography;

type HeaderProps = {
  data?: HeaderData;
  loading: boolean;
};

const Header: FunctionComponent<HeaderProps> = ({ loading, data }) => {
  if (loading) return <LoadingHeader />;

  if (!data) return <></>;

  return <Title level={4}>{data.title}</Title>;
};

export default Header;
