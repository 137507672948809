import { handleSetRoleDispatch } from "../../utils";
import { FetchService } from "../FetchService";
import mockData from "./selfBookingToolMockData";
import { SelfBookingToolData } from "./types";

interface Response {
  error?: boolean;
  data?: SelfBookingToolData;
}

const generateMockData = () => mockData;

export async function getSelfBookingToolData(
  offerId?: string
): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) {
    const data = generateMockData();
    handleSetRoleDispatch(data?.role);
    return { data };
  }
  if (!fetchService.baseUrl) return { error: true };
  let query = `?mode=SBT`;
  if (offerId) query += `&offerId=${offerId}`;
  const { data, error } = await fetchService.get(fetchService.baseUrl + query);

  if (!error) {
    const castedData = data as Response["data"];
    handleSetRoleDispatch(castedData?.role);
    return { data: castedData };
  }

  return { error };
}
