/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent, useEffect } from "react";
import { Col, Select, Price } from "@triporate/triporate-design-system";
import {
  PriceSizeEnum,
  validateTextTypeString,
} from "../../../../../../../../utils";
import { SelectedBaggagePrice, SelectedIdBag } from "../../../types";
import { getBaggageName } from "../../../../../../../../utils/baggage";
const { Option } = Select;

type BaggageByPassengerProps = {
  handleBaggeChange: (event: any, value: any, pax: number) => void;
  pax: number;
  baggage: any;
  selectedBaggagePrice: SelectedBaggagePrice;
  ind: number;
  selectedIdBag: SelectedIdBag;
};

const BaggageByPassenger: FunctionComponent<BaggageByPassengerProps> = ({
  handleBaggeChange,
  pax,
  baggage,
  selectedBaggagePrice,
  ind,
  selectedIdBag,
}) => {
  useEffect(() => {
    getBaggageName(`${ind}-${pax}`, selectedIdBag);
  }, [selectedIdBag]);

  return (
    <>
      {baggage.map((service: any, index: number) => (
        <>
          <Col span={10} key={index}>
            {index === 0 && (
              <Select
                defaultValue={getBaggageName(`${ind}-${pax}`, selectedIdBag)}
                className="baggage-select"
                onChange={(e, value) => handleBaggeChange(e, value, pax)}
              >
                {service.options.map((opt: any) => (
                  <Option
                    value={opt.name}
                    data-value={opt}
                    key={`${ind}-${index}`}
                    id={`${ind}-${pax}`}
                    seg={service.segmentId}
                  >
                    {opt.name}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
          {index === 0 && (
            <Col span={5} className="baggage-price-col" id={`${ind}-${pax}`}>
              {selectedBaggagePrice.length > 0 &&
                selectedBaggagePrice.map((serv, servInd) => (
                  <>
                    {serv.id === `${index}-${pax}` && (
                      <Price
                        money={serv.price.money}
                        status={validateTextTypeString("success")}
                        label={serv.price.label}
                        size={PriceSizeEnum["S"]}
                        key={`${ind}-${servInd}`}
                      />
                    )}
                  </>
                ))}
            </Col>
          )}
        </>
      ))}
    </>
  );
};

export default BaggageByPassenger;
