import React, { FunctionComponent, useContext } from "react";
import { Space, Button } from "@triporate/triporate-design-system";

import Context from "../Context";

type BuyButtonProps = {
  label: string;
  href: string;
  showSummary?: boolean;
  solutionId?: string;
};

const BuyButton: FunctionComponent<BuyButtonProps> = ({
  label,
  ...props
}): JSX.Element => {
  const { onBuyButtonClick } = useContext(Context);
  return (
    <Space align="center" direction="vertical" className="space-witdh-100">
      <Button type="primary" ghost onClick={() => onBuyButtonClick(props)}>
        {label}
      </Button>
    </Space>
  );
};

export default BuyButton;
