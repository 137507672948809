import React, { FunctionComponent, useState, useEffect } from "react";
import { InputNumber } from "@triporate/triporate-design-system";

import { validateStringPlaceholder, convertToInt } from "../../utils";
import { InputData, FormValues, Fields } from "../../../../types";
import { FormItem } from "../../CommonComponents";

type SearchFormProps = {
  inputName: string;
  inputData: InputData;
  formValues: FormValues;
  setFieldsValue: (values: Fields) => void;
  travellersFieldName: string;
};

const SearchForm: FunctionComponent<SearchFormProps> = ({
  inputName,
  inputData,
  formValues,
  setFieldsValue,
  travellersFieldName,
}): JSX.Element => {
  const { defaultValue, placeholder, min, unit } = inputData || {};
  const travellersCurrentAmount =
    formValues[travellersFieldName]?.length || formValues[travellersFieldName];
  const [travellersAmount, setTravellersAmount] = useState(
    convertToInt(defaultValue)
  );

  const formatter = (value?: number): string => {
    const formatValue = value && Math.ceil(value);
    return unit ? `${formatValue} ${unit}` : `${formatValue}`;
  };

  useEffect(() => {
    if (typeof travellersCurrentAmount === "number")
      setTravellersAmount(travellersCurrentAmount);
  }, [travellersFieldName, travellersCurrentAmount, setTravellersAmount]);

  useEffect(() => {
    setFieldsValue({ [inputName]: travellersAmount });
  }, [travellersAmount, inputName, setFieldsValue]);

  return (
    <FormItem inputName={inputName} inputData={inputData}>
      <InputNumber
        placeholder={validateStringPlaceholder(placeholder)}
        max={travellersAmount}
        min={min}
        formatter={formatter}
        className="custom-input-width"
      />
    </FormItem>
  );
};

export default SearchForm;
