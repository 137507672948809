import React, { FunctionComponent } from "react";
import { Row } from "@triporate/triporate-design-system";

import { usePagination } from "../../../../../hooks";
import NextResults from "../../NextResults";
import { ResultsData } from "../types";
import Solution from "./Solution";

type SolutionsProps = {
  solutions?: ResultsData["results"];
};

const Solutions: FunctionComponent<SolutionsProps> = ({ solutions }) => {
  const [visibleSolutions, handleNextPage] = usePagination(solutions);

  return (
    <Row gutter={[0, 20]}>
      {visibleSolutions?.map((data, key) => (
        <Solution key={key} data={data} />
      ))}
      <NextResults
        showSpinner={solutions?.length !== visibleSolutions?.length}
        nextPage={handleNextPage}
      />
    </Row>
  );
};

export default Solutions;
