import initialOptions from "./initialOptions.json";
import options from "./options.json";

export default {
  results: initialOptions.map((initialOption) => ({
    initialOption,
    options,
    noMoreOptionsMessage: "",
  })),
  noResults: {
    apiMsg: "",
    filtersMsg: "",
  },
};
