import React, { FunctionComponent, useState, useEffect } from "react";
import {
  Collapse,
  Row,
  Col,
  FareConditions as FareConditionsDesign,
} from "@triporate/triporate-design-system";
import { getFareConditions } from "../../../../../../../../../services/Results";
import LoadingBody from "../../../LoadingBody";
import { FaresConditionsRequestRefs } from "../types";
import { FareConditionsData } from "./types";

const { Panel } = Collapse;

type FareConditionsProps = {
  requestData: FaresConditionsRequestRefs;
  selectedFareSolutionId: string;
};
const FareConditions: FunctionComponent<FareConditionsProps> = ({
  requestData,
  selectedFareSolutionId,
}) => {
  const PANEL_OPEN = "open";
  const PANEL_CLOSE = "close";
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState<
    typeof PANEL_OPEN | typeof PANEL_CLOSE
  >(PANEL_CLOSE);
  const [conditionsDictionary, setConditionsDictionary] = useState<{
    [solutionId: string]: FareConditionsData;
  }>({});
  const [conditions, setConditions] = useState<FareConditionsData>();

  useEffect(() => {
    const handleFetch = async (url: string) => {
      setLoading(true);
      const { data } = await getFareConditions(url);
      if (data)
        setConditionsDictionary((prevState) => ({
          ...prevState,
          [selectedFareSolutionId]: data,
        }));
      setConditions(data);
      setLoading(false);
    };

    if (!selectedFareSolutionId || activeKey === PANEL_CLOSE) {
      return;
    } else if (conditionsDictionary[selectedFareSolutionId]) {
      setConditions(conditionsDictionary[selectedFareSolutionId]);
    } else {
      handleFetch(requestData[selectedFareSolutionId]);
    }
  }, [
    activeKey,
    selectedFareSolutionId,
    requestData,
    conditionsDictionary,
    setConditionsDictionary,
  ]);

  return (
    <div className="fares-conditions-row">
      <Collapse
        accordion
        activeKey={activeKey}
        onChange={() =>
          setActiveKey((prevState) =>
            prevState === PANEL_OPEN ? PANEL_CLOSE : PANEL_OPEN
          )
        }
      >
        <Panel header="Fare conditions" key={PANEL_OPEN}>
          <Row className="scrollable-container">
            <Col span={24}>
              {loading ? (
                <LoadingBody />
              ) : (
                <FareConditionsDesign
                  conditions={conditions}
                  selectedFareSolutionId=""
                />
              )}
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};

export default FareConditions;
