import React from "react";

type ContextState = {
  onBuyButtonClick: (options: {
    href: string;
    showSummary?: boolean | undefined;
    solutionId?: string | undefined;
  }) => void;
};

const Context = React.createContext<ContextState>({
  onBuyButtonClick: () => undefined,
});

export default Context;
