import React, {
    FunctionComponent,
    useCallback,
    useState,
    useEffect,
} from "react";
import { Row, Col, TripoCard } from "@triporate/triporate-design-system";

import Journeys from "../Journeys";
import BuyInfo from "./BuyInfo";

import { SolutionData, SegmentId } from "../types";

type SolutionProps = {
    solution: SolutionData;
};

const Solution: FunctionComponent<SolutionProps> = ({ solution }) => {
    const [selectedSegmentsIds, setSelectedSegmentsIds] = useState<{
        [key: number]: SegmentId;
    }>({});

    return (
        <Col span={24}>
            <TripoCard
                hoverable
                shadow
                bodyStyle={{ padding: 0, height: "fit-content" }}
            >
                <Col span={24}>
                    <Row className="transport-solution">
                        <Col className="transport-journey" span={21}>
                            <Journeys
                                journeys={solution.journeys}
                                provider={solution.provider?.src}
                                lastTicketDate={solution?.lastTicketDate}
                            />
                        </Col>
                        <Col className="transport-buy-button" span={3}>
                            <BuyInfo
                                segmentId={solution.journeys[0][0].optionId}
                                data={solution.buyButton}
                                provider={solution.provider?.src}
                                selectedSegmentsIds={selectedSegmentsIds}
                                lastTicketDate={solution?.lastTicketDate}
                                isResidentCandidate={
                                    solution.isResidentCandidate
                                }
                            />
                        </Col>
                    </Row>
                </Col>
            </TripoCard>
            {/* <pre>
                {JSON.stringify(solution, null, 2)}
            </pre> */}
        </Col>
    );
};

export default Solution;
