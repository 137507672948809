import React, { FunctionComponent, useEffect, useState } from "react";
import { Typography } from "@triporate/triporate-design-system";

const { Text } = Typography;

type TextWithPhoneLinkProps = {
  text: string;
  optionalText?: string;
  pattern: string;
  value: string;
};

const TextWithPhoneLink: FunctionComponent<TextWithPhoneLinkProps> = ({
  text,
  optionalText,
  pattern,
  value,
}) => {
  const [textPart1, setTextPart1] = useState("");
  const [textPart2, setTextPart2] = useState("");
  const [aValue, setAValue] = useState("");

  useEffect(() => {
    const [part1, part2] = text.split(pattern);
    const formatedValue = `tel:${value.split(" ").join("")}`;

    setTextPart1(part1);
    setTextPart2(part2);
    setAValue(formatedValue);
  }, [text, pattern, value, setTextPart1, setTextPart2, setAValue]);

  return (
    <>
      {optionalText && (
        <>
          <Text>{optionalText}</Text>
          <br />
        </>
      )}
      <Text>
        {textPart1}
        <a href={aValue}>{value}</a>
        {textPart2}
      </Text>
    </>
  );
};

export default TextWithPhoneLink;
