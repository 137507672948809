import { useMemo, useState } from "react";
import debounce from "lodash/debounce";
import { AppliedFilters } from "../components/commonTypes";

const useAppliedFilters = (): [
  AppliedFilters[],
  (v: AppliedFilters[]) => void
] => {
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilters[]>([]);
  const debounceTimeout = 1000;

  const debouncedSetter = useMemo(() => {
    return debounce(setAppliedFilters, debounceTimeout);
  }, []);

  return [appliedFilters, debouncedSetter];
};

export default useAppliedFilters;
