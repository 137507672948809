import { FetchService } from "../../FetchService";
import { carInfo, trainInfo, hotelInfo } from "./inputsMockData";
import { ConfirmationDrawerData } from "./types";

interface Response {
  error?: boolean;
  data?: ConfirmationDrawerData;
}

const handleMockData = (key: string): Response => {
  let data;
  let error = false;
  switch (true) {
    case key === "car":
      data = carInfo;
      break;
    case key === "train":
      data = trainInfo;
      break;
    case key === "hotel":
      data = hotelInfo;
      break;
    default:
      error = true;
  }

  return { data, error };
};

export async function getConfirmationDrawerData(
  url: string,
  body: string
): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData(url);

  const { data, error } = await fetchService.post(url, body);
  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
