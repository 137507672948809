import { FetchService } from "../FetchService";
import data from "./getArticles.json";
import { Article } from "./types";

interface Response {
  error?: boolean;
  data?: {
    title: string;
    articles: Article[];
  };
}

const handleMockData = (tripId?: string): Response => {
  if (tripId) return { data };
  return {
    data: {
      title: "Ticket info",
      articles: [],
    },
  };
};

export async function getArticles(url: string): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData();

  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
