import { FetchService } from "../FetchService";
import data from "./getSummary.json";
import { Summary } from "./types";

interface Response {
  error?: boolean;
  data?: Summary;
}

const handleMockData = (tripId?: string): Response => {
  const { header, item, footer, finishTrip, noItemsMessage } = data;
  if (tripId) return { data: { header, item, footer, finishTrip } };
  return { data: { header, noItemsMessage, footer } };
};

export async function getSummary(
  url: string,
  tripId?: string
): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData(tripId);

  const { data, error } = await fetchService.get(`${url}${tripId || ""}`);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
