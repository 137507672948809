import { FetchService } from "../../FetchService";
import mockData from "./ancillaries.json";
import { AncillariesData } from "./types";

interface Response {
  error?: boolean;
  data?: AncillariesData;
}

const generateMockData = () => mockData;

export async function getTransportAncillaries(
  url: string,
  body: string
): Promise<Response> {
  const fetchService = new FetchService();
  /*   if (fetchService.useFakeApi) return { data: generateMockData() }; */

  const { data, error } = await fetchService.post(url, body);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
