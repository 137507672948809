import React, { FunctionComponent, useState } from "react";

import { Form } from "@triporate/triporate-design-system";
import Inputs from "./Inputs";
import InputsHandlers from "./InputsHandlers";
import { InputData, FormValues, Fields } from "../../../../../types";

type MultipleInputsProps = {
  tripTypeName: string;
  departureName: string;
  returnName: string;
  datesName: string;
  departureData: InputData;
  returnData: InputData;
  datesData: InputData;
  formValues: FormValues;
  getFieldValue: (inputName: string) => unknown;
  setFieldsValue: (values: Fields) => void;
  setFieldsAmount?: (amount: number) => void;
};

const MultipleInputs: FunctionComponent<MultipleInputsProps> = ({
  tripTypeName,
  getFieldValue,
  setFieldsAmount = () => undefined,
  ...props
}) => {
  const isMultiTrip = props.formValues[tripTypeName]?.value === "3";
  const isRoundTrip = props.formValues[tripTypeName]?.value === "2";
  const [inputListName] = useState("segments");

  return (
    <Form.List name={inputListName}>
      {(fields, { add, remove }) => (
        <>
          <InputsHandlers
            isMultiTrip={isMultiTrip}
            fieldsAmount={fields.length}
            setFieldsAmount={setFieldsAmount}
            add={add}
            remove={remove}
          />
          {fields.map(({ name, key, ...restField }) => (
            <Inputs
              {...props}
              {...restField}
              isMultiTrip={isMultiTrip}
              isRoundTrip={isRoundTrip}
              key={name}
              name={name}
              inputListName={inputListName}
              fieldKey={key}
              add={add}
              remove={remove}
              getFieldValue={getFieldValue}
            />
          ))}
        </>
      )}
    </Form.List>
  );
};

export default MultipleInputs;
