import { parseLocationSearch } from "../../../utils";
import { FetchService } from "../../FetchService";
import mockData from "./mockData";
import { ResultsData, NoResults } from "./type";

interface Response {
  error?: boolean;
  data?: {
    results: ResultsData;
    noResults: NoResults;
  };
}

const generateMockData = () => mockData;

export async function getHotelResults(
  url: string,
  body: string
): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return { data: generateMockData() };

  const tripId = parseLocationSearch(window.location.href, "tripId");

  if (tripId) {
    const parsedBody = JSON.parse(body);
    parsedBody.tripId = tripId;
    body = JSON.stringify(parsedBody);
  }

  const { data, error } = await fetchService.post(url, body);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
