import React, { FunctionComponent } from "react";
import {
  Col,
  Divider,
  Icons,
  Row,
  TripoCard,
  validateIconType,
  Typography,
} from "@triporate/triporate-design-system";

import {
  BestSolution as BestSolutionType,
  OtherSolutions as OtherSolutionsType,
} from "../types";
import BestSolution from "./BestSolution";
import OtherSolutions from "./OtherSolutions";

type SolutionProps = {
  bestSolution: BestSolutionType;
  otherSolutions: OtherSolutionsType[];
  noMoreOptionsMessage: string;
  showOtherSolutions: boolean;
  handleShowDetails: () => void;
};

const Solution: FunctionComponent<SolutionProps> = ({
  bestSolution,
  otherSolutions,
  showOtherSolutions,
  noMoreOptionsMessage,
  handleShowDetails,
}) => (
  <Col span={24}>
    <TripoCard hoverable shadow>
      <BestSolution
        data={bestSolution}
        showOtherSolutions={showOtherSolutions}
        handleShowDetails={handleShowDetails}
        canScroll={!!otherSolutions.length}
      />
      {showOtherSolutions && otherSolutions.length !== 0 && (
        <OtherSolutions data={otherSolutions} />
      )}

      {showOtherSolutions && otherSolutions.length === 0 && (
        <Row>
          <Divider orientation="left" plain></Divider>
          <Col span={24}>
            <Row style={{ justifyContent: "center" }}>
              <Icons icon={validateIconType("info")} />
              <Typography>{noMoreOptionsMessage}</Typography>
            </Row>
          </Col>
        </Row>
      )}
    </TripoCard>
  </Col>
);

export default Solution;
