export const handleMandatoryParamQuery = (
  {
    baseUrl,
    param,
    value,
  }: {
    baseUrl: string | undefined;
    param: string | undefined;
    value: string | undefined;
  },
  alsoSearchByQuery?: boolean
): string | void => {
  if (!baseUrl) return;
  if (!param) return baseUrl;
  if (alsoSearchByQuery && param && !value) return baseUrl;
  if (!value) return;

  return `${baseUrl}${param}=${value}`;
};
