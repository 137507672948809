import React, { FunctionComponent } from "react";
import { Row, Col, Typography } from "@triporate/triporate-design-system";
import { HourglassOutlined } from "@ant-design/icons";

const { Text } = Typography;

type PendingAlertProps = {
  message?: string;
};

const PendingAlert: FunctionComponent<PendingAlertProps> = ({
  message,
}): JSX.Element => (
  <Row justify="space-between" align="bottom">
    <Col>
      <Text strong>{message}</Text>
    </Col>
    <Col>
      <HourglassOutlined className="pending-icon" />
    </Col>
  </Row>
);

export default PendingAlert;
