/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState, useEffect } from "react";
import { Typography, Price } from "@triporate/triporate-design-system";
import { validateTextTypeString } from "../../../../../../../utils";
import {
    PriceData,
    SelectedFare,
    SelectedPriority,
    SelectedBaggage,
} from "../types";
import "./AncillariesTotalPrice.scss";

const { Title } = Typography;

enum PriceSizeEnum {
    "S" = 5,
    "M" = 4,
    "L" = 3,
}

type AncillariesTotalPriceProps = {
    data: PriceData;
    selectedFare: SelectedFare;
    selectedPriority: SelectedPriority;
    selectedBaggage: SelectedBaggage;
};

const AncillariesTotalPrice: FunctionComponent<AncillariesTotalPriceProps> = ({
    data,
    selectedFare,
    selectedPriority,
    selectedBaggage,
}) => {
    const { total, unitary, passengers } = data;
    const [basicPrice, setBasicPrice] = useState(0);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [baggagePrice, setBaggagePrice] = useState<number>(0);
    const [priorityPrice, setPriorityPrice] = useState<number>(0);
    const [unitaryAmount, setUnitaryAmount] = useState(0);

    useEffect(() => {
        setBasicPrice(selectedFare.price);
        setTotalAmount(selectedFare.price);
        setUnitaryAmount(
            Number((selectedFare.price / passengers.count).toFixed(2))
        );
    }, [selectedFare, totalAmount]);

    useEffect(() => {
        let sum = 0;
        if (selectedPriority.length) {
            for (const selected of selectedPriority) {
                sum += selected.price;
            }
            setPriorityPrice(sum);
        }
        setPriorityPrice(sum);
    }, [selectedPriority]);

    useEffect(() => {
        let sum = 0;
        if (selectedBaggage.length > 0) {
            for (const selected of selectedBaggage) {
                if (selected.price === undefined) selected.price = 0;
                sum += selected.price;
            }
            setBaggagePrice(sum);
        }
        setBaggagePrice(sum);
    }, [selectedBaggage]);

    useEffect(() => {
        const sumPrices = basicPrice + baggagePrice + priorityPrice;
        setTotalAmount(basicPrice + baggagePrice + priorityPrice);
        setUnitaryAmount(Number((sumPrices / passengers.count).toFixed(2)));
    }, [totalAmount, baggagePrice, priorityPrice]);
    return (
        <div className="ancillaries-price-container">
            <div>
                <div className="ancillaries-price">
                    <Title level={4}>{total.title}</Title>
                    <Price
                        money={{
                            amount: totalAmount,
                            currency: total.currency,
                        }}
                        status={validateTextTypeString("success")}
                        size={PriceSizeEnum.L}
                    />
                </div>
                <div className="ancillaries-price">
                    ~
                    <Price
                        money={{
                            amount: unitaryAmount,
                            currency: unitary.currency,
                        }}
                        status={validateTextTypeString("success")}
                        size={PriceSizeEnum.S}
                    />
                    <span>{unitary.label}</span>
                </div>
            </div>
        </div>
    );
};

export default AncillariesTotalPrice;
