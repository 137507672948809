/* eslint-disable max-lines */
import React, { FunctionComponent, useState } from "react";
import {
  Button,
  Col,
  Tag,
  Typography,
  Row,
  Space,
} from "@triporate/triporate-design-system";
import { InputData, FormValues, Fields, SubmitData } from "../../../../types";
import { CarsInputFields } from "../../../constants";
import {
  FormItem,
  FetchSelect,
  RangePicker,
  PaxNumberInput,
} from "../../CommonComponents";
import DirectionInputs from "./DirectionInputs";
import "./CarInputs.scss";
import { getOfficeInfo } from "../../../../../../services/SearchForm/getOfficeInfo";
import { CarOfficeInfo } from "./types";
import InfoModal from "../../../InfoModal";
import OfficeInfoBtn from "./OfficeInfoBtn";
import SubmitButton from "../../../SubmitButton";
const { Text } = Typography;

type CarInputsProps = {
  inputs: { [key: string]: InputData };
  formValues: FormValues;
  addNewTraveller?: SubmitData;
  showTravellerFormIFrame?: () => void;
  getFieldValue: (inputName: string) => unknown;
  setFieldsValue: (values: Fields) => void;
  isEstimate?: boolean;
  estimateLabel?: string;
  newTravellerIdCreated?: string;
  isABT: boolean;
  label: string;
  onSubmit: () => void;
};

const CarInputs: FunctionComponent<CarInputsProps> = ({
  inputs,
  formValues,
  addNewTraveller,
  showTravellerFormIFrame,
  getFieldValue,
  setFieldsValue,
  isEstimate,
  estimateLabel,
  newTravellerIdCreated,
  isABT,
  label,
  onSubmit,
}): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);
  const [officeInfo, setOfficeInfo] = useState<CarOfficeInfo>();
  const [querySearch, setQuerySearch] = useState("");
  const showModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  const handleFetch = async (office: string, url: string | undefined) => {
    if (url) {
      const { data } = await getOfficeInfo(url, { query: querySearch, office });
      if (!data) return;
      setOfficeInfo(data);
      showModal();
    }
  };
  const { usersEstimate } = inputs;
  return (
    <>
      <Col span={24}>
        <Row
          className={
            isEstimate ? "form-top-row cars isEstimate" : "form-top-row cars"
          }
        >
          <Col span={24} className="searchInput-cars_top">
            {isEstimate && <Tag color="cyan">{estimateLabel}</Tag>}
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <div className="searchInput-cars-container" style={{ display: "flex" }}>
          <Space size={"middle"} align="baseline">
            <div className="div_1" style={{ display: "flex" }}>
              <Space size={"middle"} align="baseline">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "350px" }}>
                    <DirectionInputs
                      cityInputName={CarsInputFields.pickupCity}
                      officeInputHour={CarsInputFields.pickupHour}
                      inputs={inputs}
                      formValues={formValues}
                      setFieldsValue={setFieldsValue}
                      setQuerySearch={setQuerySearch}
                    />
                  </div>

                  {inputs?.[CarsInputFields.pickupCity]?.inputInfo && (
                    <OfficeInfoBtn
                      value={formValues?.[CarsInputFields.pickupCity]?.value}
                      handleFetch={handleFetch}
                      url={inputs?.[CarsInputFields.pickupCity]?.inputInfo?.url}
                      icon={
                        inputs?.[CarsInputFields.pickupCity]?.inputInfo?.icon
                      }
                    />
                  )}
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "350px" }}>
                    <DirectionInputs
                      cityInputName={CarsInputFields.returnCity}
                      cityMasterInputName={CarsInputFields.pickupCity}
                      officeInputHour={CarsInputFields.returnHour}
                      inputs={inputs}
                      formValues={formValues}
                      setFieldsValue={setFieldsValue}
                      setQuerySearch={setQuerySearch}
                    />
                  </div>

                  {inputs?.[CarsInputFields.returnCity]?.inputInfo && (
                    <OfficeInfoBtn
                      value={formValues?.[CarsInputFields.returnCity]?.value}
                      handleFetch={handleFetch}
                      url={inputs?.[CarsInputFields.returnCity]?.inputInfo?.url}
                      icon={
                        inputs?.[CarsInputFields.returnCity]?.inputInfo?.icon
                      }
                    />
                  )}
                </div>
                {/*       </Space> */}
              </Space>
            </div>
            <div className="div_2">
              <Col>
                <div style={{ width: "340px" }}>
                  <RangePicker
                    inputData={inputs[CarsInputFields.dates]}
                    inputName={CarsInputFields.dates}
                    setFieldsValue={setFieldsValue}
                    showTime={true}
                  />
                </div>
              </Col>
            </div>
          </Space>
        </div>
      </Col>

      <Col span={24}>
        <div className="form-midlle-div">
          <Space size={"middle"} align="baseline">
            {usersEstimate?.isEstimate ? (
              <div className="inputNumber-width">
                <Text>{usersEstimate?.label}</Text>
                <PaxNumberInput
                  inputName={CarsInputFields.usersEstimate}
                  inputData={inputs[CarsInputFields.usersEstimate]}
                  setFieldsValue={setFieldsValue}
                />
              </div>
            ) : (
              <div style={{ width: "350px" }}>
                <FormItem
                  inputName={CarsInputFields.users}
                  inputData={inputs[CarsInputFields.users]}
                >
                  <FetchSelect
                    inputName={CarsInputFields.users}
                    inputData={inputs[CarsInputFields.users]}
                    formValues={formValues}
                    getFieldValue={getFieldValue}
                    setFieldsValue={setFieldsValue}
                    className="custom-car-driver-input"
                    addNewOption={newTravellerIdCreated}
                  />
                </FormItem>
              </div>
            )}

            {addNewTraveller && (
              <Button onClick={showTravellerFormIFrame}>
                {addNewTraveller.label}
              </Button>
            )}

            <div className={isABT ? "submit-column mb" : "submit-column"}>
              <SubmitButton label={label} onSubmit={onSubmit} />
            </div>
          </Space>
        </div>
      </Col>

      <InfoModal
        closeModal={closeModal}
        isVisible={isVisible}
        data={officeInfo}
      />
    </>
  );
};

export default CarInputs;
