import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Icons,
  validateIconType,
  Typography,
  Image,
} from "@triporate/triporate-design-system";

const { Text } = Typography;

import "./HotelImageList.scss";
import { SummaryNotes } from "../../../types";

interface Images {
  label: string;
  icon: string;
  imageList: string[];
  imageListIcon: string;
}

type HotelImageListProps = {
  data?: Images;
  handleSelectedImage: (data: SummaryNotes) => void;
};

const HotelImageList: FunctionComponent<HotelImageListProps> = ({
  data,
  handleSelectedImage,
}) => {
  const [itemSelected, setImageSrc] = useState<SummaryNotes>({});
  const [containerSelected, setContainerSelected] = useState("");

  const handleImageUpdate = (
    url: string,
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    handleContainerSelected(e.currentTarget.id);

    setImageSrc((prevState) => {
      if (prevState.image === url) return {};
      else return { ...prevState, ["image"]: url };
    });
  };

  const handleContainerSelected = (src: string) => {
    setContainerSelected(src);
    if (itemSelected.image === src) setContainerSelected("");
  };
  useEffect(() => {
    handleSelectedImage(itemSelected);
  }, [itemSelected, handleSelectedImage]);

  return (
    <>
      <Icons icon={validateIconType(data?.icon)} />
      <Text strong>{data?.label}</Text>
      <div className="hotelImage-list">
        {data?.imageList.map((image, ind) => (
          <div
            className="hotelImage-list_container"
            onClick={(e) => handleImageUpdate(image, e)}
            key={ind}
            id={image}
          >
            {containerSelected === image && (
              <div className="hotelImage-list_isImgSelected">
                <Icons icon={validateIconType(data?.imageListIcon)} />
              </div>
            )}

            <Image src={image} preview={false} width={140} height={90} />
          </div>
        ))}
      </div>
    </>
  );
};

export default HotelImageList;
