import { SelectedBaggage, SelectedPriority } from "./types";

export const setOptionAddons = (
  baggageArr: SelectedBaggage,
  priorityArr: SelectedPriority
): unknown => {
  const addonsArr: unknown[] = [];
  if (baggageArr.length > 0)
    baggageArr.forEach((bag) => addonsArr.push(bag.baggageData));

  if (priorityArr.length > 0)
    priorityArr.forEach((priority) => addonsArr.push(priority.priorityData));

  return addonsArr;
};
