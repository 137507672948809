import React from "react";
import { SegmentId } from "./types";

type ContextState = {
  onBuyButtonClick: (
    url: string,
    ids: {
      [key: number]: SegmentId;
    },
    code?: string
  ) => void;
};

const Context = React.createContext<ContextState>({
  onBuyButtonClick: () => undefined,
});

export default Context;
