export enum ValidInputGroups {
  hotel = "hotel",
  flight = "flight",
  car = "car",
}

export enum CommonInputFields {
  dates = "dates",
  users = "users",
  usersEstimate = "usersEstimate",
}

export enum CarsOnlyInputFields {
  pickupCity = "pickupCity",
  pickupOffice = "pickupOffice",
  pickupHour = "pickupHour",
  pickupMinute = "pickupMinute",
  returnCity = "returnCity",
  returnOffice = "returnOffice",
  returnHour = "returnHour",
  returnMinute = "returnMinute",
}

export const CarsInputFields = {
  ...CommonInputFields,
  ...CarsOnlyInputFields,
};

enum FlightsOnlyInputFields {
  tripType = "tripType",
  departure = "departure",
  return = "return",
}

export const FlightsInputFields = {
  ...CommonInputFields,
  ...FlightsOnlyInputFields,
};

enum HotelsOnlyInputFields {
  location = "location",
  rooms = "rooms",
  poi = "poi",
  hotel = "hotel",
  distance = "distance",
  smartSearch = "smartSearch",
}

export const HotelsInputFields = {
  ...CommonInputFields,
  ...HotelsOnlyInputFields,
};
