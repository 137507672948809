import { FC, useCallback, useContext } from "react";
import {
    Row,
    Col,
    TripoCard,
    Divider,
    Button,
    Space,
    Price,
    Image,
} from "@triporate/triporate-design-system";
import MainContent from "../Segments/MainContent";
import { useEffect, useState } from "react";
import { RequestData } from "../types";
import { parseLocationSearch } from "../../../../../utils";
import { useLocation } from "react-router-dom";
import Context from "../Context";
import { timeToMinutes } from "../../../../../utils/timeToMinutes";

type TravelSummaryProps = {
    solutions: any[];
    outTrip: any;
    returnTrip: any;
    searchFormRequestData?: RequestData;
    setSummaryRequestData: (data: any) => void;
};

const TravelSummaryComponent: FC<TravelSummaryProps> = ({
    solutions,
    outTrip,
    returnTrip,
    searchFormRequestData,
    setSummaryRequestData,
}) => {
    const { search } = useLocation();

    const { onOpenModal } = useContext(Context);

    const [summaryPrice, setSummaryPrice] = useState<number>(0);

    const [providerSelected, setProviderSelected] = useState<string>();

    useEffect(() => {
        const oPrice = (outTrip && outTrip.selectedFare.price) || 0;
        const rPrice = (returnTrip && returnTrip.selectedFare.price) || 0;

        setSummaryPrice(Number(oPrice) + Number(rPrice));
    }, [outTrip, returnTrip]);

    useEffect(() => {
        if (!outTrip) return;

        const solutionId0: string = outTrip.optionId;

        const solutionSelected = solutions.find(
            (item: any) => item.journeys[0][0].optionId == solutionId0
        );

        setProviderSelected(solutionSelected?.provider?.src);
    }, [outTrip]);

    const isIncompatibleSchedule = (): boolean => {
        if (searchFormRequestData) {
            const body = JSON.parse(searchFormRequestData.body);
            const outDate = body.segments[0].dates[0].split("T")[0];
            const returnDate = body.segments[0].dates[1].split("T")[0];

            // verify if departure time of the return trip is minor that the arrival time of the out trip.
            // verify if out date is equal that return date
            if (
                timeToMinutes(returnTrip.filterValues.departureTime) <
                    timeToMinutes(outTrip.filterValues.arrivalTime) &&
                outDate === returnDate
            ) {
                onOpenModal();
                return true;
            }
        }

        return false;
    };

    const handleRequestData = () => {
        if (isIncompatibleSchedule()) return;

        const href: string = outTrip.moreInfo.href.replace(
            "/transport/info",
            "/summary/product"
        );

        //OUT
        const { solutionId: solutionId0, fareBasis: outFareBasis } =
            outTrip.selectedFare;

        //RETURN
        const { solutionId: solutionId1, fareBasis: returnFareBasis } =
            returnTrip.selectedFare;

        const param = "&solutionId1";
        const url = `${href}${param}=${solutionId1}&tripId=${parseLocationSearch(
            search,
            "tripId"
        )}`;
        // const addons = setOptionAddons(selectedBaggage, selectedPriority);
        const urlParams = new URLSearchParams(url.split("?")[1]);

        const requestBody = {
            journeys: [outTrip.optionId, returnTrip.optionId],
            fareBasis: `${outFareBasis}-${returnFareBasis}`,
            solutionId: solutionId0,
            solutionId1,
            addons: [],
            token: urlParams.get("token"),
        };

        //RETURN
        const body = JSON.stringify(requestBody);

        setSummaryRequestData({ url, body });
    };
    return (
        <>
            <Row className="transport-solutions" gutter={[0, 24]}>
                <Col span={24}>
                    {(outTrip || returnTrip) && (
                        <TripoCard hoverable shadow bodyStyle={{ padding: 0 }}>
                            <Col span={24}>
                                <Row className="transport-solution">
                                    <Col
                                        className="transport-journey summary-segment"
                                        span={20}
                                    >
                                        {outTrip && (
                                            <Space
                                                direction="vertical"
                                                className="space-witdh-100"
                                            >
                                                <Row>
                                                    <Col span={20}>
                                                        <MainContent
                                                            segment={outTrip}
                                                        />
                                                    </Col>
                                                    <Col
                                                        className="seleted-fare-option"
                                                        span={4}
                                                    >
                                                        <p>
                                                            {outTrip.selectedFare.col1.map(
                                                                (item: any) =>
                                                                    item.fareName
                                                            )}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </Space>
                                        )}

                                        <Divider
                                            dashed={true}
                                            style={{ borderWidth: 2 }}
                                        />

                                        {returnTrip && (
                                            <Space
                                                direction="vertical"
                                                className="space-witdh-100"
                                            >
                                                <Row>
                                                    <Col span={20}>
                                                        <MainContent
                                                            segment={returnTrip}
                                                        />
                                                    </Col>
                                                    <Col
                                                        className="seleted-fare-option"
                                                        span={4}
                                                    >
                                                        <p>
                                                            {returnTrip.selectedFare.col1.map(
                                                                (item: any) =>
                                                                    item.fareName
                                                            )}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </Space>
                                        )}
                                    </Col>

                                    {/* Right side of row summary */}
                                    {
                                        <Col
                                            className="transport-buy-button"
                                            span={4}
                                        >
                                            <>
                                                <Row
                                                    align="top"
                                                    gutter={[0, 8]}
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <Col span={24}>
                                                        <Image
                                                            src={
                                                                providerSelected
                                                            }
                                                            width={80}
                                                            preview={false}
                                                        />
                                                    </Col>

                                                    <Col span={24}>
                                                        <Price
                                                            money={{
                                                                amount: summaryPrice,
                                                                currency: "EUR",
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col span={24}>
                                                        <Button
                                                            size="large"
                                                            type="primary"
                                                            id="buy-btn-discovery"
                                                            onClick={
                                                                handleRequestData
                                                            }
                                                            disabled={
                                                                !outTrip ||
                                                                !returnTrip
                                                            }
                                                        >
                                                            Reservar
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                        </TripoCard>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default TravelSummaryComponent;
