import React, { FunctionComponent } from "react";
import { Row, Col } from "@triporate/triporate-design-system";
import { AppliedOrder } from "@triporate/triporate-design-system/dist/components/utils/FiltersUI/type";
import Form from "./Form";
import { InputData, SubmitData, DirtyFields, CurrentInputsData } from "./types";
import { WarningInformation } from "../../pages/SelfBookingTool/types";
import SkeletonInputs from "./Form/SkeletonInputs";
import { AppliedFilters } from "../commonTypes";
import "./SearchForm.scss";

type FormType = {
  type: string;
  url: string;
};

type SearchFormProps = {
  isABT?: boolean;
  isEstimate?: boolean;
  estimateLabel?: string;
  formType?: FormType;
  handleSumbit: (data: { url: string; body: string }) => void;
  searchIsDirty?: (data: DirtyFields) => void;
  setAppliedFilters?: (appliedFilters: AppliedFilters[]) => void;
  setAppliedOrder?: (appliedOrder: AppliedOrder) => void;
  inputs?: { [key: string]: InputData };
  addNewTraveller?: SubmitData;
  showTravellerFormIFrame?: () => void;
  newTravellerIdCreated?: string;
  submit?: SubmitData;
  setCurrentInputsData: (currentInputsData: CurrentInputsData) => void;
  warningInfo?: WarningInformation
  isAfterSearch?: boolean
};

const SearchForm: FunctionComponent<SearchFormProps> = ({
  isABT,
  isEstimate,
  estimateLabel,
  formType,
  handleSumbit,
  searchIsDirty,
  inputs,
  addNewTraveller,
  showTravellerFormIFrame,
  newTravellerIdCreated,
  submit,
  setCurrentInputsData = () => undefined,
  warningInfo,
  isAfterSearch
}): JSX.Element => {
  if (!inputs || !submit)
    return (
      <div className="searchForm-skeleton">
        <SkeletonInputs />
      </div>
    );

  return (
    <Row>
      <Col span={1} />
      <Col span={22}>
        <Form
          isABT={!!isABT}
          type={formType?.type}
          inputs={inputs}
          addNewTraveller={addNewTraveller}
          showTravellerFormIFrame={showTravellerFormIFrame}
          newTravellerIdCreated={newTravellerIdCreated}
          submit={submit}
          handleSumbit={handleSumbit}
          searchIsDirty={searchIsDirty}
          setCurrentInputsData={setCurrentInputsData}
          isEstimate={isEstimate}
          estimateLabel={estimateLabel}
          warningInfo={warningInfo}
          isAfterSearch={isAfterSearch}
        ></Form>
      </Col>
      <Col span={1} />
    </Row>
  );
};
export default SearchForm;
