export type Config = {
  loadedConfig: boolean;
  googleTagManagerId: string;
  apiHost: string;
  clientDomain: string;
  environment: string;
  loginClientUrl: string;
  googleMapsApiKey: string;
  universe: string;
  favicon: string;
  backendUrl: string;
};

export const initialConfig: Config = {
  loadedConfig: false,
  googleTagManagerId: "",
  apiHost: "",
  clientDomain: "",
  environment: "",
  loginClientUrl: "",
  googleMapsApiKey: "",
  universe: "",
  favicon: "",
  backendUrl: "",
};
