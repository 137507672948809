import React, { FunctionComponent, useState, useEffect } from "react";

import { DatePicker } from "@triporate/triporate-design-system";
import moment, { Moment } from "moment";
import { FormValues, Fields, InputData } from "../../../../../../types";
import { isValidDate } from "../../../../utils/validateDates";

const { RangePicker } = DatePicker;
type Value = Moment | [Moment, Moment];

type DateInputProps = {
  useRangepicker: boolean;
  inputListName: string;
  datesName: string;
  name: number;
  formValues: FormValues;
  setFieldsValue: (values: Fields) => void;
  datesData: InputData;
  value?: Value;
};

const DateInput: FunctionComponent<DateInputProps> = ({
  useRangepicker,
  inputListName,
  datesName,
  name,
  formValues,
  datesData,
  setFieldsValue,
  ...props
}) => {
  const [dateValueCopy, setDateValueCopy] = useState<Value>();
  const [rangeValueCopy, setRangeValueCopy] = useState<Value>();
  const [tripDates, setTripDates] = useState<Moment[] | Moment>();

  useEffect(() => {
    if (
      !datesData.defaultValue ||
      (Array.isArray(datesData.defaultValue) &&
        datesData.defaultValue[0] === null)
    )
      return;

    const [fromDate, toDate] = datesData.defaultValue as Moment[];
    if (useRangepicker) {
      setTripDates(isValidDate([moment(fromDate), moment(toDate)]));
    } else {
      setTripDates(moment(fromDate));
    }
  }, [datesData.defaultValue, useRangepicker]);

  const handleRangePickerValue = ({ value }: { value?: Value }) => {
    if (Array.isArray(value)) return value;
  };

  const handleDatePickerValue = ({ value }: { value?: Value }) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    return newValue;
  };

  const disablePastDates = (current: Moment) => {
    if (!current) return false;

    if (name === 0) return current < moment().subtract(1, "day");

    const previousDateIndex = name - 1;
    const previousDateValue =
      formValues[inputListName][previousDateIndex]?.[datesName];

    return current < previousDateValue;
  };

  useEffect(() => {
    if (name !== 0) return;

    let newValue;
    if (useRangepicker) newValue = rangeValueCopy;
    else newValue = dateValueCopy;

    setFieldsValue({
      [inputListName]: [
        {
          ...formValues[inputListName]?.[0],
          [datesName]: newValue ? newValue : tripDates,
        },
      ],
    });
  }, [
    useRangepicker,
    datesName,
    inputListName,
    name,
    setFieldsValue,
    tripDates,
  ]);

  const { value } = props;

  useEffect(() => {
    if (Array.isArray(value)) setRangeValueCopy(value);
    else if (value) setDateValueCopy(value);
  }, [value]);

  if (useRangepicker)
    return (
      <RangePicker
        {...props}
        style={{
          width: "100%",
        }}
        value={handleRangePickerValue(props)}
        disabledDate={disablePastDates}
      />
    );

  return (
    <DatePicker
      {...props}
      style={{
        width: "100%",
      }}
      value={handleDatePickerValue(props)}
      disabledDate={disablePastDates}
    />
  );
};

export default DateInput;
