import React, { FunctionComponent } from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

interface BillingFulfillmentFlagProps {
  billingFulfilled: boolean;
}

export const BillingFulfillmentFlag: FunctionComponent<
  BillingFulfillmentFlagProps
> = ({ billingFulfilled }) => {
  if (billingFulfilled) return <CheckCircleOutlined />;
  return <CloseCircleOutlined />;
};
