import React, { FunctionComponent, MouseEvent } from "react";
import { Row, Col, Image } from "@triporate/triporate-design-system";

import {
  useCanIUseByRole,
  useRoles,
  useScrollDivIntoView,
} from "../../../../../../hooks";
import BuyButton from "../../BuyButton";
import { BestSolution as BestSolutionType } from "../../types";
import BestSolutionContent from "./BestSolutionContent";
import "./BestSolution.scss";

type BestSolutionProps = {
  data: BestSolutionType;
  showOtherSolutions: boolean;
  canScroll: boolean;
  handleShowDetails: () => void;
};

const BestSolution: FunctionComponent<BestSolutionProps> = ({
  data,
  showOtherSolutions,
  canScroll,
  handleShowDetails,
}) => {
  const [rowRef, executeScroll] = useScrollDivIntoView();
  const [roles] = useRoles();
  const [canTravelManagerUse, loadingCanTravelManagerUse] = useCanIUseByRole(
    roles.travelManager
  );
  const [canTravellerUse, loadingCanTravellerUse] = useCanIUseByRole(
    roles.traveller
  );

  const [canTravelAgentUse, loadingCanTravelAgentUse] = useCanIUseByRole(
    roles.agent
  );
  const canDisplayInSBT =
    loadingCanTravelManagerUse ||
    canTravelManagerUse ||
    loadingCanTravellerUse ||
    canTravellerUse;

  return (
    <Row
      ref={rowRef}
      align="middle"
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;
        if (target?.innerText !== data.col6.label) {
          handleShowDetails();
          canScroll && executeScroll();
        }
      }}
      className="clickable-effect"
      gutter={0}
    >
      <Col span={3} className="custom-image-col">
        <Image width={110} src={data.col1.image} preview />
      </Col>
      <Col span={18}>
        <BestSolutionContent
          data={data}
          showOtherSolutions={showOtherSolutions}
        />
      </Col>
      <Col span={3}>
        {canDisplayInSBT && <BuyButton {...data.col6} />}
        {(loadingCanTravelAgentUse || canTravelAgentUse) && (
          <BuyButton {...data.col6} />
        )}
      </Col>
    </Row>
  );
};

export default BestSolution;
