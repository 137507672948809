import React, {
    FunctionComponent,
    useState,
    useEffect,
    useContext,
} from "react";
import { Drawer } from "@triporate/triporate-design-system";
import { getConfirmationDrawerData } from "../../../../services/Results";
import { ConfirmationDrawerData, SummaryNotes } from "./types";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import Context from "../TransportResultsV3/Context";

type RequestData = {
    url: string;
    body: string;
};

type ConfirmationDrawerProps = {
    requestData: RequestData | null;
    cleanRequestData: () => void;
    setPaymentsRequestData: (url: string, body: string) => void;
    cleanOnClose?: boolean;
    isOnRefresh?: boolean;
    setIsOnRefresh?: (value: boolean) => void;
};

const ConfirmationDrawer: FunctionComponent<ConfirmationDrawerProps> = ({
    requestData,
    cleanRequestData,
    setPaymentsRequestData,
    cleanOnClose = false,
    isOnRefresh = false,
    setIsOnRefresh,
}): JSX.Element => {
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [confirmationDrawerData, setConfirmationDrawerData] =
        useState<ConfirmationDrawerData>();
    const [summaryNotes, setSummaryNotes] = useState<SummaryNotes>({});
    const [hotelNotes, setHotelNotes] = useState<SummaryNotes>({});
    const [selectedImage, setSelectedImage] = useState<SummaryNotes>({});
    const [discountSolutionId, setDiscountSolutionId] = useState("");

    const { setIsErrorPricing } = useContext(Context);

    useEffect(() => {
        if (requestData) handleFetch(requestData);
    }, [requestData]);

    useEffect(() => {
        if (requestData && isOnRefresh) handleFetch(requestData);
    }, [isOnRefresh]);

    const handleFetch = async ({
        url,
        body,
    }: {
        url: string;
        body: string;
    }) => {
        setLoading(true);
        setVisible(true);

        const { data } = await getConfirmationDrawerData(url, body);
        /*    if (data) setConfirmationDrawerData(data); */
        if (data) {
            setConfirmationDrawerData(data);
            const discountSolution = data.body.paymentInfo.discountSolutionId;
            if (discountSolution) setDiscountSolutionId(discountSolution);
            setIsErrorPricing(data.body.paymentInfo.price.money.amount === 0);
        }

        setLoading(false);
        setIsOnRefresh && setIsOnRefresh(false);
    };

    const closeDrawer = (cleanData?: boolean) => {
        if (cleanData) {
            cleanRequestData();
        }
        setTimeout(() => {
            setVisible(false);
        });
    };

    const handleRequestData = () => {
        if (!confirmationDrawerData?.footer.btn2 || !requestData) return;
        const parsedBody = JSON.parse(requestData.body);
        const { href } = confirmationDrawerData?.footer.btn2;
        setPaymentsRequestData(
            href,
            JSON.stringify({
                ...parsedBody,
                ...summaryNotes,
                ...hotelNotes,
                ...selectedImage,
                discountSolutionId,
            })
        );
        closeDrawer(true);
    };

    return (
        <Drawer
            className="ancillaries-drawer"
            title={
                <Header
                    loading={loading}
                    data={confirmationDrawerData?.header}
                />
            }
            placement="right"
            closable={!loading && !confirmationDrawerData}
            visible={visible}
            onClose={() => closeDrawer(cleanOnClose)}
            mask
            maskClosable={false}
            maskStyle={{ background: "transparent" }}
            key="right"
            width={800}
            footer={
                <Footer
                    loading={loading}
                    data={confirmationDrawerData?.footer}
                    closeDrawer={() => closeDrawer(cleanOnClose)}
                    handleRequestData={handleRequestData}
                />
            }
        >
            <Body
                loading={loading}
                data={confirmationDrawerData?.body}
                handleNotes={setSummaryNotes}
                handleHotelNotes={setHotelNotes}
                handleSelectedImage={setSelectedImage}
            />
        </Drawer>
    );
};

export default ConfirmationDrawer;
