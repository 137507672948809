import React from "react";
import { SegmentId } from "./types";

export enum EShowMode {
    TO = "TO",
    FROM = "FROM",
}

type ContextState = {
    showOutwardOnly?: EShowMode;
    openedSolutions: string[];
    setOpenedSolutions: (openedSolutions: string[]) => void;
    onBuyButtonClick: (
        url: string,
        ids: {
            [key: number]: SegmentId;
        },
        segmentId: string,
        code?: string
    ) => void;
    onTripSelectedEvent: (tripSelected: any) => void;
    ancillariesRequestData: any;
    onOpenModal: () => void;
    isErrorPricing: boolean;
    setIsErrorPricing: (value: boolean) => void;
    onRefreshPricingButtonClick: () => void;
};

const Context = React.createContext<ContextState>({
    showOutwardOnly: EShowMode.TO,
    openedSolutions: [],
    setOpenedSolutions: () => undefined,
    onBuyButtonClick: () => undefined,
    onTripSelectedEvent: () => undefined,
    ancillariesRequestData: {},
    onOpenModal: () => undefined,
    isErrorPricing: false,
    setIsErrorPricing: () => undefined,
    onRefreshPricingButtonClick: () => undefined,
});

export default Context;
