import React, {
  useState,
  useEffect,
  FunctionComponent,
  useCallback,
} from "react";
import {
  IframeModal,
  usePanelNotification,
  EmbeddedTravellerFormMessageId,
} from "@triporate/triporate-design-system";
import { getTravellersFormSrc } from "../../services/travellers";
import { TravellersFormIFrame } from "../../services/travellers/types";

type TravellersFormProps = {
  visible: boolean;
  closeModal: () => void;
  src: string;
  setNewTravellerIdCreated: (id: string) => void;
};

const TravellersForm: FunctionComponent<TravellersFormProps> = ({
  visible,
  closeModal,
  src,
  setNewTravellerIdCreated,
}) => {
  const [enpointsTraveller, setEnpointsTraveller] =
    useState<TravellersFormIFrame>();
  const { successNotification, errorNotification } = usePanelNotification();

  const parsePayload = (payload: string) => {
    try {
      const divider = " - ";
      const splittedPayload = payload.split(divider);
      const data = splittedPayload[1];
      return JSON.parse(data);
    } catch (e) {
      return null;
    }
  };

  const onSuccess = async (payload: string) => {
    const parsedData = parsePayload(payload);

    successNotification({
      message: parsedData?.message,
      description: parsedData?.description,
    });

    setNewTravellerIdCreated(parsedData.zammadId);
    closeModal();
  };

  const onError = (payload: string) => {
    const parsedData = parsePayload(payload);
    errorNotification({
      message: parsedData?.message,
      description: parsedData?.description,
    });
  };

  useEffect(() => {
    const handleFetch = async () => {
      const { data } = await getTravellersFormSrc(src);
      if (data) setEnpointsTraveller(data);
    };
    handleFetch();
  }, [src]);

  const getSrc = useCallback(() => {
    const { createSrc, updateSrc } = enpointsTraveller || {};

    return createSrc || "";
  }, [enpointsTraveller]);

  if (!visible || !enpointsTraveller) return <></>;

  return (
    <IframeModal
      visible={visible}
      className={"iframe-modal-fitContent"}
      src={getSrc()}
      closeModal={closeModal}
      messageItem={[
        {
          messageId: EmbeddedTravellerFormMessageId.onSuccess,
          messageHandler: onSuccess,
        },
        {
          messageId: EmbeddedTravellerFormMessageId.onError,
          messageHandler: onError,
        },
      ]}
    />
  );
};

export default TravellersForm;
