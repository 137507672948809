import React, { FunctionComponent, useState } from "react";
import {
  Comment,
  Button,
  Typography,
} from "@triporate/triporate-design-system";
import moment from "moment";
import { ArtcileData } from "./types";

const { Text, Paragraph } = Typography;

type ArticleProps = {
  data?: ArtcileData;
  expandedArticleId: number;
  handleArticleExpand: (value: number) => void;
};

const Article: FunctionComponent<ArticleProps> = ({
  data,
  expandedArticleId,
  handleArticleExpand,
}): JSX.Element => {
  const [isEllipsis, setIsEllipsis] = useState(false);

  const getCommentClass = (sender: string | undefined) => {
    let className = "comment";
    if (sender === "Customer") className += " sender customer";
    else className += " sender other";
    return className;
  };

  const formatText = data?.body
    .replace(/\n{2,}/g, "~")
    .replace(/(\n+\s+\n+)/g, "~")
    .replace(/(\n+\s{2,})/g, " ")
    .replace(/(\.\n)/g, "~")
    .replace(/(\n\s+)/g, "~")
    .replace(/(~?\s+~?)/g, " ");

  const bodySentences = formatText?.split("~");

  const isExpanded = data?.id === expandedArticleId;

  return (
    <div className="comment-container">
      <div className={getCommentClass(data?.sender)}></div>
      {data && (
        <Comment
          className="comment"
          key={data?.id}
          author={
            <Text className="article-author">
              <i
                className="icon-envelope-regular"
                style={{ paddingRight: "5px" }}
              />
              {data?.from}
            </Text>
          }
          content={
            <>
              {bodySentences &&
                bodySentences.map((sentence, i) => (
                  <Paragraph
                    key={`sentence_${i}`}
                    ellipsis={
                      !isExpanded && {
                        rows: 5,
                        onEllipsis: setIsEllipsis,
                      }
                    }
                  >
                    {sentence}
                  </Paragraph>
                ))}

              {isEllipsis && data?.id && (
                <Button
                  type="link"
                  onClick={() => handleArticleExpand(data.id)}
                  className="comment_button"
                >
                  {isExpanded ? <p>Read less</p> : <p>Read more</p>}
                </Button>
              )}
            </>
          }
          datetime={moment(data?.created_at).format("DD/MM/YYYY")}
        />
      )}
    </div>
  );
};

export default Article;
