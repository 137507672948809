import React, { FunctionComponent } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import { SelfBookingTool, AgentBookingTool, ErrorPage } from "../pages";
import { useRoles, useCanIUseByRole } from "../hooks";

const RouterSwitch: FunctionComponent = () => {
  const [roles] = useRoles();
  const [canTravelManagerUse, loadingCanTravelManagerUse] = useCanIUseByRole(
    roles.travelManager
  );

  const [canTravellerUse, loadingCanTravellerUse] = useCanIUseByRole(
    roles.traveller
  );
  const [canTravelAgentUse, loadingCanTravelAgentUse] = useCanIUseByRole(
    roles.agent
  );

  const canDisplayInSBT =
    loadingCanTravelManagerUse ||
    canTravelManagerUse ||
    loadingCanTravellerUse ||
    canTravellerUse;

  return (
    // TP-3767
    <>
      <Switch>
        {(loadingCanTravelAgentUse || canTravelAgentUse) && (
          <Route path="/AgentBookingTool" component={AgentBookingTool} />
        )}
        {canDisplayInSBT && (
          <Route path="/SelfBookingTool" component={SelfBookingTool} />
        )}
        <Route path="/forbidden" render={() => <ErrorPage errorCode="403" />} />
        <Redirect from="*" to="/SelfBookingTool" />
      </Switch>
    </>
  );
};

export default RouterSwitch;
