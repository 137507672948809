import { useEffect, FC, useState, useContext } from "react";
import { Button, Skeleton } from "@triporate/triporate-design-system";
import { SelectedFare } from "../../Ancillaries/types";
import FareFamilies from "../../Ancillaries/Body/AncillariesContent/FareFamilies";
import "./transport.fares.style.css";

type ITransportFares = {
    fares: any;
    onFareSeletedEvent: (selectedFare: any) => void;
    closedFares: () => void;
};

const TransportFares: FC<ITransportFares> = ({
    onFareSeletedEvent,
    closedFares,
    fares,
}) => {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [selectedFare, setSelectedFare] = useState<SelectedFare>({
        solutionId: "",
        fareBasis: "",
        price: 0,
    });

    useEffect(() => {
        const { solutionId } = selectedFare;

        if (!solutionId.length) return;

        const selectedFareDetails = fares.content.find(
            (itemFare: any) => itemFare.solutionId == solutionId
        );

        onFareSeletedEvent({ ...selectedFare, ...selectedFareDetails });
    }, [selectedFare]);

    return (
        <div className="transport-fares-block">
            <>
                <Button onClick={() => closedFares()}>X</Button>
                {!fares || isLoading ? (
                    <Skeleton paragraph={{ rows: 2 }} active />
                ) : (
                    <FareFamilies
                        data={fares}
                        selectedFare={selectedFare}
                        setSelectedFare={setSelectedFare}
                    />
                )}
            </>
        </div>
    );
};

export default TransportFares;
