import React from "react";
import { Spin } from "@triporate/triporate-design-system";

import "./LoadingPayments.scss";

const LoadingPayments = (): JSX.Element => {
  return (
    <div className="loading">
      <Spin size="large" />
    </div>
  );
};

export default LoadingPayments;
