import React, { FunctionComponent } from "react";
import { useMappedState } from "redux-react-hook";
import TagManager from "react-gtm-module";

import { ConfigState } from "../../../store/config";

const TagManagerContainer: FunctionComponent = ({ children }): JSX.Element => {
  const mapState = (state: ConfigState): string =>
    state.config.googleTagManagerId;
  const googleTagManagerId = useMappedState(mapState);

  const defaultGTMcode = "GTM-WDLJLKJ";
  const tagManagerArgs = {
    gtmId: googleTagManagerId || defaultGTMcode,
    dataLayerName: "SelfBookingTool",
  };

  TagManager.initialize(tagManagerArgs);

  return <>{children}</>;
};

export default TagManagerContainer;
