import React, { FunctionComponent } from "react";
import { Typography, SuitCaseIcon } from "@triporate/triporate-design-system";

import { FareData } from "../../types";

const { Text } = Typography;

type LuggageProps = {
  iconData: FareData["col3"];
  data: FareData["col4"];
};

const Luggage: FunctionComponent<LuggageProps> = ({ iconData, data }) => (
  <>
    <Text className="icons-right-margin">
      <SuitCaseIcon {...iconData} />
    </Text>
    <Text className="text-right-margin" strong>
      {data.text1}
    </Text>
    <Text className="text-right-margin">{data.text2}</Text>
    <Text strong>{data.text3}</Text> <Text>{data.text4}</Text>
  </>
);

export default Luggage;
