import React, { FunctionComponent } from "react";
import { Typography } from "@triporate/triporate-design-system";
import TextWithPhoneLink from "./TextWithPhoneLink";
import { ContactInfo as ContactInfoProps } from "./type";

const { Text, Paragraph } = Typography;

const ContactInfo: FunctionComponent<ContactInfoProps> = ({
  phone,
  schedule,
}): JSX.Element => (
  <Paragraph>
    <TextWithPhoneLink {...phone} />
    {
      /*
      <br />
      <Text>{schedule.text}</Text> 
      <ul>
        {schedule.items.map((item, key) => (
          <li key={key}>
            <Text>{item}</Text>
          </li>
        ))}
      </ul>
      */
    }
  </Paragraph>
);

export default ContactInfo;
