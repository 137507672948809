import React, { FunctionComponent } from "react";
import { Row, Col, Price, Image } from "@triporate/triporate-design-system";

import { validateTextTypeString } from "../../../../../../../utils";
import { BestSolution as BestSolutionType } from "../../../types";
import BestSolutionDetails from "./BestSolutionDetails";
import BestSolutionInfo from "./BestSolutionInfo";
import "./BestSolutionContent.scss";
import { useCanIUseByRole, useRoles } from "../../../../../../../hooks";

type BestSolutionContentProps = {
  data: BestSolutionType;
  showOtherSolutions: boolean;
};

const BestSolutionContent: FunctionComponent<BestSolutionContentProps> = ({
  data,
  showOtherSolutions,
}): JSX.Element => {
  const [roles] = useRoles();
  const [canTravelAgentUse, loadingCanTravelAgentUse] = useCanIUseByRole(
    roles.agent
  );
  return (
    <Row align="middle" gutter={0}>
      <Col span={11}>
        <BestSolutionDetails
          data={data}
          showOtherSolutions={showOtherSolutions}
        />
      </Col>
      <Col span={6}>
        <BestSolutionInfo data={data} />
      </Col>

      <Col span={3} style={{ textAlign: "center" }}>
        {(loadingCanTravelAgentUse || canTravelAgentUse) && (
          <Image
            src={data.col4.bestOption.providerSrc}
            width={60}
            preview={false}
          />
        )}
      </Col>

      <Col span={4}>
        <Price
          money={{ ...data.col5.price.money, currency: "EUR" }}
          status={validateTextTypeString(data.col5.price.status)}
          label={data.col5.price.label}
        />
      </Col>
    </Row>
  );
};

export default BestSolutionContent;
