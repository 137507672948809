import React, { FunctionComponent, useCallback } from "react";
import {
  usePanelNotification,
  EmbeddedTravellerFormMessageId,
} from "@triporate/triporate-design-system";

import "./BillingFulfillmentFormIFrame.css";
import IframeModal from "./IframeModal";

interface BillingFulfillmentFormIFrameProps {
  forceModal: boolean;
  visible: boolean;
  formSrc: string;
  hideCloseModalBtn: boolean;
  closeModal: () => void;
}

export const BillingFulfillmentFormIFrame: FunctionComponent<
  BillingFulfillmentFormIFrameProps
> = ({ forceModal, visible, formSrc, hideCloseModalBtn, closeModal }) => {
  const { successNotification, errorNotification } = usePanelNotification();

  const parsePayload = (payload: string) => {
    try {
      const divider = " - ";
      const splittedPayload = payload.split(divider);
      const data = splittedPayload[1];
      return JSON.parse(data);
    } catch (e) {
      return null;
    }
  };

  const onSuccess = async (payload: string) => {
    const parsedData = parsePayload(payload);
    successNotification({
      message: parsedData?.message,
      description: parsedData?.description,
    });
    closeModal();
  };

  const onError = (payload: string) => {
    const parsedData = parsePayload(payload);
    errorNotification({
      message: parsedData?.message,
      description: parsedData?.description,
    });
  };

  const getIframeModalClassName = useCallback(() => {
    const classNameList = ["no-mask-drawer"]; // iframe-modal
    if (hideCloseModalBtn) {
      classNameList.push("hide-close-btn");
    }
    return classNameList.join(" ");
  }, [hideCloseModalBtn]);

  if (!visible) return <></>;

  return (
    <IframeModal
      className={getIframeModalClassName()}
      visible={visible}
      src={formSrc}
      closable={!forceModal}
      closeModal={closeModal}
      messageItem={[
        {
          messageId: EmbeddedTravellerFormMessageId.onSuccess,
          messageHandler: onSuccess,
        },
        {
          messageId: EmbeddedTravellerFormMessageId.onError,
          messageHandler: onError,
        },
      ]}
    />
  );
};
