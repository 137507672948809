import { FetchService } from "../../FetchService";
import { mockDataDetails } from "./mockData";
import { DetailsData } from "./type";

interface Response {
  error?: boolean;
  data?: DetailsData;
}

const generateMockData = () => mockDataDetails;

export async function getCarDetails(
  url: string,
  body: string
): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return { data: generateMockData() };

  const { data, error } = await fetchService.post(url, body);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
