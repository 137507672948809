import React, {
    FunctionComponent,
    useContext,
    useState,
    useEffect,
} from "react";
import { Skeleton, Space } from "@triporate/triporate-design-system";

import { SegmentProps } from "./types";
import MainContent from "./MainContent";
import TransportFares from "./TranportFares/TransportFares";
import Context from "../Context";
import { getTransportAncillaries } from "../../../../../services/Results/getTransportAncillaries/getTransportAncillaries";
import { last } from "lodash";

const Segment: FunctionComponent<SegmentProps> = ({
    provider,
    lastTicketDate,
    ...props
}) => {
    const {
        openedSolutions,
        ancillariesRequestData,
        onTripSelectedEvent,
        setOpenedSolutions,
    } = useContext(Context);

    const [showFares, setShowFares] = useState<boolean>(false);

    const [fares, setFares] = useState<any>(false);

    const solutionId = props.segment.optionId.split("#")[0];

    useEffect(() => {
        const isOpen = openedSolutions.includes(props.segment.optionId);
        setShowFares(isOpen);
    }, [openedSolutions]);

    useEffect(() => {
        const handleFetch = async ({
            url,
            body,
        }: {
            url: string;
            body: string;
        }) => {
            const { data } = await getTransportAncillaries(url, body);

            const faresData: any = data;

            if (data) setFares({ ...faresData.body.fares });
        };

        if (
            ancillariesRequestData &&
            ancillariesRequestData.url.includes(solutionId)
        )
            handleFetch(ancillariesRequestData);
    }, [ancillariesRequestData]);

    const handleFareSelectedEvent = (selectedFare: any) => {
        onTripSelectedEvent({
            ...props.segment,
            selectedFare: { ...selectedFare },
        });
    };

    const handleClosedFares = () => {
        setOpenedSolutions(
            openedSolutions.filter(
                (solution) => solution !== props.segment.optionId
            )
        );
    };

    return (
        <>
            {/* {props.segment.optionId} */}
            <MainContent
                provider={provider}
                lastTicketDate={lastTicketDate}
                {...props}
            />
            {showFares && (
                <TransportFares
                    fares={fares}
                    onFareSeletedEvent={handleFareSelectedEvent}
                    closedFares={() => handleClosedFares()}
                />
            )}
        </>
    );
};

export default Segment;
