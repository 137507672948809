import { useState, useEffect } from "react";
import { Fields } from "../../types";
import { ValidInputGroups, CommonInputFields } from "../constants";

type Values = {
  value: string | number;
  [key: string]: unknown;
};

type ConfigOptions = {
  usersFormValue: Values[] | Values;
  setNewUsersValue: (value: Fields) => void;
  currentType?: string;
};

const CAR_TYPE = ValidInputGroups.car;
const USER_PROPERTY_NAME = CommonInputFields.users;

const useDriverAndPassengersOptionsHandler = ({
  usersFormValue,
  setNewUsersValue,
  currentType,
}: ConfigOptions): void => {
  const [multipleValuesCache, setMultipleValuesCache] = useState<Values[]>();
  const [singleValueCache, setSingleValueCache] = useState<Values>();

  const mergeAndGetUniqueValues = (
    valuesList: Values[],
    singleValue: Values
  ) => {
    const allV = [...valuesList, singleValue];
    const { list, dictionary } = allV.reduce<{
      list: string[];
      dictionary: { [key: string]: Values };
    }>(
      (prevValue, value) => {
        const id = value.value;
        return {
          list: [...prevValue.list, `${id}`],
          dictionary: { ...prevValue.dictionary, [id]: value },
        };
      },
      {
        list: [],
        dictionary: {},
      }
    );
    const uniqueV = list.filter(
      (value, index, self) => self.indexOf(value) === index
    );
    return uniqueV.map((id) => dictionary[id]);
  };

  useEffect(() => {
    if (!currentType) return;
    if (currentType !== CAR_TYPE && !Array.isArray(usersFormValue)) {
      setSingleValueCache(usersFormValue);
      let newValues = multipleValuesCache;

      if (newValues && usersFormValue)
        newValues = mergeAndGetUniqueValues(newValues, usersFormValue);
      else if (usersFormValue) newValues = [usersFormValue];
      setNewUsersValue({
        [USER_PROPERTY_NAME]: newValues,
      });
    }

    if (currentType === CAR_TYPE && Array.isArray(usersFormValue)) {
      setMultipleValuesCache(usersFormValue);
      if (singleValueCache)
        setNewUsersValue({
          [USER_PROPERTY_NAME]: singleValueCache,
        });
    }
  }, [
    currentType,
    usersFormValue,
    setNewUsersValue,
    multipleValuesCache,
    setMultipleValuesCache,
    singleValueCache,
    setSingleValueCache,
  ]);
};

export default useDriverAndPassengersOptionsHandler;
