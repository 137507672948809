import React from "react";
import Option from "./Option";
import { Row, Col } from "@triporate/triporate-design-system";

const LoadingOptionContent = (): JSX.Element => {
  return (
    <Row align="middle" gutter={[0, 24]}>
      <Col span={24}>
        <Option />
      </Col>
      <Col span={24}>
        <Option />
      </Col>
      <Col span={24}>
        <Option />
      </Col>
      <Col span={24}>
        <Option />
      </Col>
      <Col span={24}>
        <Option />
      </Col>
      <Col span={24}>
        <Option />
      </Col>
    </Row>
  );
};

export default LoadingOptionContent;
