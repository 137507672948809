import { FunctionComponent } from "react";
import { Row, Col } from "@triporate/triporate-design-system";

import { SegmentsProps, SegmentId } from "./types";
import Segment from "./Segment";

const Segments: FunctionComponent<SegmentsProps> = ({
    segments,
    provider,
    lastTicketDate,
}) => {
    return (
        <Row gutter={[0, 20]}>
            {segments.map((segment, key) => (
                <Col key={key} span={24}>
                    <Segment
                        segment={segment}
                        provider={provider}
                        lastTicketDate={lastTicketDate}
                    />
                </Col>
            ))}
        </Row>
    );
};
export default Segments;
