import React, { FunctionComponent } from "react";
import { Row, Col } from "@triporate/triporate-design-system";
import { CarJourneyData } from "../types";
import JourneyAncilliaries from "./CarJourneyAncilliaries";
import JourneyLocation from "./CarJourneyLocation";
import JourneyVehicle from "./CarJourneyVehicle";
import "./CarJourneyInfo.scss";

type CarJourneyInfoProps = {
  data?: CarJourneyData;
};

const CarJourneyInfo: FunctionComponent<CarJourneyInfoProps> = ({ data }) => {
  return (
    <Row className="car-journey-info-container">
      <Col span={18}>
        <JourneyVehicle data={data?.vehicle} />
      </Col>
      <Col offset={1} span={5}>
        <JourneyAncilliaries data={data?.ancilliaries} />
      </Col>
      <Col span={11}>
        <JourneyLocation data={data?.pickup} />
      </Col>
      <Col offset={2} span={11}>
        <JourneyLocation data={data?.return} />
      </Col>
    </Row>
  );
};

export default CarJourneyInfo;
