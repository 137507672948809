import React, { FunctionComponent } from "react";
import { Button } from "@triporate/triporate-design-system";
import { BillingFulfillmentFlag } from "./BillingFulfillmentFlag";

interface BillingFulfillmentBtnProps {
  showModal: () => void;
  billingFulfilled: boolean;
  btnContent: string;
}

export const BillingFulfillmentBtn: FunctionComponent<
  BillingFulfillmentBtnProps
> = ({ showModal, billingFulfilled, btnContent }) => {
  return (
    <Button
      shape="round"
      icon={<BillingFulfillmentFlag billingFulfilled={billingFulfilled} />}
      style={{
        backgroundColor: billingFulfilled
          ? "var(--success-info-color)"
          : "var(--error-color)",
        color: "var(--text-color-inverse)",
        fontWeight: 900,
      }}
      onClick={showModal}
    >
      {btnContent}
    </Button>
  );
};
