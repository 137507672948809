import { useEffect } from "react";

type MessageItem = {
  message: string;
  messageHandler: () => void;
};

function useWindowMessagesHandler(items: MessageItem[]): void {
  useEffect(() => {
    const handleIframeContentMessage = (event: MessageEvent) => {
      items.find(({ message }) => message === event.data)?.messageHandler?.();
    };

    window.addEventListener("message", handleIframeContentMessage);

    return () => {
      window.removeEventListener("message", handleIframeContentMessage);
    };
  }, [items]);
}

export default useWindowMessagesHandler;
