export type State = {
  isAuthenticated: boolean;
};

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const AUTHENTICATE = "AUTHENTICATE";

interface login {
  type: typeof LOGIN;
}

interface logout {
  type: typeof LOGOUT;
}

interface authenticate {
  type: typeof AUTHENTICATE;
  payload: boolean;
}

export type ActionTypes = login | logout | authenticate;
