import React, { FunctionComponent, useState } from "react";
import {
    Alert,
    Button,
    Col,
    GeneralFilters,
    Row,
    Space,
    Tag,
    Typography,
    Modal,
} from "@triporate/triporate-design-system";

import { InputData, FormValues, Fields, SubmitData } from "../../../../types";
import { FormItem, FetchSelect, PaxNumberInput } from "../../CommonComponents";
import MultipleInputs from "./MultipleInputs";

import { getSelectSearchAsyncOptions } from "../../../../../../services/SearchForm";
import SubmitButton from "../../../SubmitButton";
import { WarningInformation } from "../../../../../../pages/SelfBookingTool/types";
import { DownOutlined } from "@ant-design/icons";
const { Text } = Typography;
type TransportInputsProps = {
    isABT: boolean;
    inputs: { [key: string]: InputData };
    addNewTraveller?: SubmitData;
    showTravellerFormIFrame?: () => void;
    formValues: FormValues;
    getFieldValue: (inputName: string) => unknown;
    setFieldsValue: (values: Fields) => void;
    setFieldsAmount?: (amount: number) => void;
    isEstimate?: boolean;
    estimateLabel?: string;
    newTravellerIdCreated?: string;
    label: string;
    onSubmit: () => void;
    warningInfo?: WarningInformation;
    isAfterSearch?: boolean;
    isTrain?: boolean;
    onShowAirlinesEvent?: (show: boolean) => void
};

enum TransportsInputFields {
    tripType = "tripType",
    users = "users",
    departure = "departure",
    return = "return",
    dates = "dates",
    usersEstimate = "usersEstimate",
    providers = "providers",
}

const TransportInputs: FunctionComponent<TransportInputsProps> = ({
    inputs,
    addNewTraveller,
    showTravellerFormIFrame,
    formValues,
    getFieldValue,
    setFieldsValue,
    setFieldsAmount,
    isEstimate,
    estimateLabel,
    newTravellerIdCreated,
    label,
    onSubmit,
    isABT,
    warningInfo,
    isAfterSearch,
    isTrain = false,
    onShowAirlinesEvent
}): JSX.Element => {
    const { usersEstimate } = inputs;
    const [modalVisible, setModalVisible] = useState(false);
    const showWarning = !isABT && isAfterSearch;
    
    return (
        <>
            <Col span={24}>
                <Row className="form-top-row">
                    <Col>
                        {isEstimate && <Tag color="cyan">{estimateLabel}</Tag>}
                    </Col>
                    <Col span={3}>
                        <div className="tripType-wrapper">
                            <FormItem
                                inputName={TransportsInputFields.tripType}
                                inputData={
                                    inputs[TransportsInputFields.tripType]
                                }
                            >
                                <FetchSelect
                                    inputName={TransportsInputFields.tripType}
                                    inputData={
                                        inputs[TransportsInputFields.tripType]
                                    }
                                    formValues={formValues}
                                    getFieldValue={getFieldValue}
                                    setFieldsValue={setFieldsValue}
                                />
                            </FormItem>
                        </div>
                    </Col>

                    {!isTrain && (
                        <>
                            <Col span={4}>
                                <div className="providers-input-width">
                                    <FormItem
                                        inputName={
                                            TransportsInputFields.providers
                                        }
                                        inputData={
                                            inputs[
                                            TransportsInputFields.providers
                                            ]
                                        }
                                    >
                                        <FetchSelect
                                            inputName={
                                                TransportsInputFields.providers
                                            }
                                            inputData={
                                                inputs[
                                                TransportsInputFields
                                                    .providers
                                                ]
                                            }
                                            formValues={formValues}
                                            getFieldValue={getFieldValue}
                                            setFieldsValue={setFieldsValue}
                                        />
                                    </FormItem>
                                </div>
                            </Col>
                            <Col span={4} style={{ marginLeft: 35 }}>
                                <Button onClick={() => { onShowAirlinesEvent && onShowAirlinesEvent(true)}}>
                                    <Space>
                                        Aerolíneas
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Col>
                        </>
                    )}

                    <Col span={13}>
                        <GeneralFilters
                            getSelectSearchAsyncOptions={
                                getSelectSearchAsyncOptions
                            }
                        />
                    </Col>
                    {showWarning && (
                        <Col span={6}>
                            <Row justify="end" style={{ width: "100%" }}>
                                <Col>
                                    <Alert
                                        message={
                                            warningInfo &&
                                            warningInfo?.config.label
                                        }
                                        type="warning"
                                        showIcon
                                        onClick={() => setModalVisible(true)}
                                        style={{ cursor: "pointer" }}
                                    />
                                    <Modal
                                        visible={modalVisible}
                                        closable={false}
                                        onOk={() => setModalVisible(false)}
                                        okText="Cerrar"
                                        cancelButtonProps={{
                                            style: { display: "none" },
                                        }}
                                        width={600}
                                        className="header-button help-modal-button product-info-modal"
                                    >
                                        <Space
                                            align="start"
                                            direction="horizontal"
                                        >
                                            <div className="product-info">
                                                {warningInfo &&
                                                    warningInfo?.config.text}
                                            </div>
                                        </Space>
                                    </Modal>
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
            </Col>

            <Col span={24}>
                <MultipleInputs
                    departureName={TransportsInputFields.departure}
                    departureData={inputs[TransportsInputFields.departure]}
                    returnName={TransportsInputFields.return}
                    returnData={inputs[TransportsInputFields.return]}
                    datesName={TransportsInputFields.dates}
                    datesData={inputs[TransportsInputFields.dates]}
                    tripTypeName={TransportsInputFields.tripType}
                    formValues={formValues}
                    getFieldValue={getFieldValue}
                    setFieldsValue={setFieldsValue}
                    setFieldsAmount={setFieldsAmount}
                />
            </Col>

            <Col span={12}>
                <Space size={"middle"} align="baseline">
                    {usersEstimate?.isEstimate ? (
                        <div className="searchInput_estimate inputNumber-width">
                            <Text>{usersEstimate?.label}</Text>
                            <PaxNumberInput
                                inputName={TransportsInputFields.usersEstimate}
                                inputData={
                                    inputs[TransportsInputFields.usersEstimate]
                                }
                                setFieldsValue={setFieldsValue}
                            />
                        </div>
                    ) : (
                        <div className="travellers-input_width">
                            <FormItem
                                inputName={TransportsInputFields.users}
                                inputData={inputs[TransportsInputFields.users]}
                            >
                                <FetchSelect
                                    inputName={TransportsInputFields.users}
                                    inputData={
                                        inputs[TransportsInputFields.users]
                                    }
                                    formValues={formValues}
                                    getFieldValue={getFieldValue}
                                    setFieldsValue={setFieldsValue}
                                    addNewOption={newTravellerIdCreated}
                                />
                            </FormItem>
                        </div>
                    )}
                    {addNewTraveller && (
                        <Button onClick={showTravellerFormIFrame}>
                            {addNewTraveller.label}
                        </Button>
                    )}
                    <div
                        className={isABT ? "submit-column mb" : "submit-column"}
                    >
                        <SubmitButton label={label} onSubmit={onSubmit} />
                    </div>
                </Space>
            </Col>
        </>
    );
};

export default TransportInputs;
