import React, { FunctionComponent } from "react";
import {
  Row,
  Col,
  Typography,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";

import { FareData } from "../types";
import LuggageAndSeat from "./LuggageAndSeat";

const { Text } = Typography;

type FareDetailsProps = {
  data: FareData;
};

const FareDetails: FunctionComponent<FareDetailsProps> = ({ data }) => (
  <Row>
    <Col span={24}>
      <Row>
        <Col span={24}>
          <Text className="icons-right-margin">
            <Icons icon={validateIconType(data.col1.icon)} />
          </Text>
          <Text strong>{data.col2.text1}</Text> <Text>{data.col2.text2}</Text>
        </Col>
      </Row>

      <LuggageAndSeat
        luggageIcon={data.col3}
        luggage={data.col4}
        seat={data.col5}
      />

      <Row>
        <Col span={23}>
          <Text className="icons-right-margin">
            <Icons icon={validateIconType(data.col6.icon)} />
          </Text>

          <Text>{data.col6.text}</Text>
        </Col>
      </Row>
    </Col>
  </Row>
);

export default FareDetails;
