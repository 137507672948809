enum CustomTypes {
  successLight = "light-primary-color",
  secondaryLight = "light-secondary-color",
}

export const validateCustomTextTypeString = (
  value?: string
): string | undefined => {
  const className = CustomTypes[value as keyof typeof CustomTypes];
  if (className) return `custom-class-container ${className}`;
};
