import { State, ActionTypes, LOGIN, LOGOUT, AUTHENTICATE } from "./types";

const initialState: State = {
  isAuthenticated: false,
};

export const authReducer = (
  state = initialState,
  action: ActionTypes
): State => {
  switch (action.type) {
    case LOGIN:
      return {
        isAuthenticated: true,
      };
    case LOGOUT:
      return {
        isAuthenticated: false,
      };
    case AUTHENTICATE:
      return {
        isAuthenticated: action.payload,
      };
    default:
      return state;
  }
};
