import React, { FunctionComponent, useState } from "react";
import { Row } from "@triporate/triporate-design-system";

import { usePagination } from "../../../../../hooks";
import NextResults from "../../NextResults";
import { ResultsData } from "../types";
import Solution from "./Solution";

type SolutionsProps = {
  solutions?: ResultsData["results"];
};

const Solutions: FunctionComponent<SolutionsProps> = ({ solutions }) => {
  const [visibleSolutions, handleNextPage] = usePagination(solutions);
  const [opendedSolutionKey, setOpendedSolutionKey] = useState<number>();

  return (
    <Row gutter={[0, 20]}>
      {visibleSolutions?.map(
        ({ initialOption, options, noMoreOptionsMessage }, key) => (
          <Solution
            key={key}
            noMoreOptionsMessage={noMoreOptionsMessage}
            bestSolution={initialOption.details}
            otherSolutions={options}
            showOtherSolutions={opendedSolutionKey === key}
            handleShowDetails={() =>
              setOpendedSolutionKey((prevState) =>
                prevState === key ? undefined : key
              )
            }
          />
        )
      )}
      <NextResults
        showSpinner={solutions?.length !== visibleSolutions?.length}
        nextPage={handleNextPage}
      />
    </Row>
  );
};

export default Solutions;
