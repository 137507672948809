import React, { FunctionComponent, Fragment, useState, useEffect } from "react";
import { Select, Tooltip } from "@triporate/triporate-design-system";
import { handleMandatoryParamQuery } from "../../../../../../utils";
import { getSelectOptions } from "../../../../../../services/SearchForm";
import {
  OptionsGroup,
  Option as OptionType,
  InputData,
  FormValues,
  Fields,
} from "../../../../types";
import { validateSelectModeValue, getDefaultValue } from "./utils";

const { Option, OptGroup } = Select;

type FetchSelectProps = {
  inputData: InputData;
  inputName: string;
  className?: string;
  disabled?: boolean;
  formValues: FormValues;
  getFieldValue: (inputName: string) => unknown;
  setFieldsValue: (values: Fields) => void;
  addNewOption?: string;
};

const FetchSelect: FunctionComponent<FetchSelectProps> = ({
  inputName,
  inputData,
  formValues,
  getFieldValue,
  setFieldsValue,
  disabled,
  addNewOption,
  ...props
}): JSX.Element => {
  const {
    defaultValue: defaultValueProp,
    options: optionsUrl,
    placeholder,
    mode,
    showSearch,
    query,
  } = inputData || {};

  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState<
    OptionType | OptionType["value"] | OptionType[]
  >();

  const [optionsGroups, setOptionsGroups] = useState<OptionsGroup[]>([]);
  const formFieldReferenceName = query?.input;
  const formFieldReferenceValue =
    formFieldReferenceName && formValues[formFieldReferenceName]?.value;

  useEffect(() => {
    let isMounted = true;

    const updateInputData = (newOption: OptionType) => {
      const optionsList = getFieldValue(inputName) as OptionType[];
      if (optionsList) {
        optionsList.push(newOption);

        setFieldsValue({
          [inputName]: [...optionsList],
        });
      } else
        setFieldsValue({
          [inputName]: [newOption],
        });
    };

    const handleFetch = async (url: string) => {
      if (isMounted) setLoading(true);

      const { data } = await getSelectOptions(url);
      if (!data || !isMounted) return;

      setOptionsGroups(data);

      const newOption = data[0].options.find(
        (option) => option.value === addNewOption?.toString()
      );

      if (newOption) updateInputData(newOption);

      if (defaultValueProp) {
        setDefaultValue(getDefaultValue(data, defaultValueProp as string));
      }

      setLoading(false);
    };

    const url = handleMandatoryParamQuery({
      baseUrl: optionsUrl,
      param: query?.param,
      value:
        typeof formFieldReferenceValue === "string"
          ? formFieldReferenceValue
          : JSON.stringify(formFieldReferenceValue),
    });

    if (url && !disabled) handleFetch(url);

    return () => {
      isMounted = false;
    };
  }, [
    disabled,
    optionsUrl,
    defaultValueProp,
    formFieldReferenceValue,
    query?.param,
    addNewOption,
    getFieldValue,
    setFieldsValue,
    inputName,
  ]);

  useEffect(() => {
    if (defaultValue) setFieldsValue({ [inputName]: defaultValue });
  }, [defaultValue, inputName, setFieldsValue]);

  useEffect(() => {
    if (formFieldReferenceName) setFieldsValue({ [inputName]: undefined });
  }, [
    inputName,
    formFieldReferenceName,
    formFieldReferenceValue,
    setFieldsValue,
  ]);

  return (
    <Select
      {...props}
      labelInValue
      placeholder={placeholder}
      mode={validateSelectModeValue(mode)}
      showSearch={showSearch}
      filterOption={true}
      optionFilterProp="label"
      loading={loading}
      disabled={disabled}
      dropdownClassName="custom-select-dropdown-width"
      allowClear
    >
      {optionsGroups.map(
        ({ groupName, options }: OptionsGroup, groupKey: number) =>
          groupName ? (
            <OptGroup key={groupKey} label={groupName}>
              {options.map(({ label, value, tooltip }, optionKey: number) => (
                <Option key={optionKey} value={value} label={label}>
                  <Tooltip title={tooltip} placement="top">
                    {label}
                  </Tooltip>
                </Option>
              ))}
            </OptGroup>
          ) : (
            <Fragment key={groupKey}>
              {options.map((option, index) => (
                <Option
                  key={index}
                  value={option.value}
                  label={option.label}
                  disabled={option.disabled}
                >
                  <Tooltip title={option.tooltip} placement="top">
                    {option.label}
                  </Tooltip>
                </Option>
              ))}
            </Fragment>
          )
      )}
    </Select>
  );
};

export default FetchSelect;
