import React, { FunctionComponent } from "react";
import {
  Typography,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";
import { CarJourneyData } from "../types";

const { Title, Text } = Typography;

type CarJourneyLocationProps = {
  data?: CarJourneyData["pickup"] | CarJourneyData["return"];
};

const CarJourneyLocation: FunctionComponent<CarJourneyLocationProps> = ({
  data,
}) => (
  <div>
    <Title className="journey-section-title" level={4}>
      {data?.title}
    </Title>
    <Text className="journey-text-block">{data?.date}</Text>
    <Text className="journey-text-block">
      <Icons icon={validateIconType(data?.location.icon)} />
      {data?.location.text}
    </Text>
  </div>
);

export default CarJourneyLocation;
