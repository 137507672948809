import React, { FunctionComponent } from "react";
import {
  Row,
  Col,
  Tooltip,
  Typography,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";

import { validateTextTypeString } from "../../../../../../../utils";
import { BestSolution as BestSolutionType } from "../../../types";

const { Text, Title } = Typography;

type BestSolutionInfoProps = {
  data: BestSolutionType;
};

const BestSolutionInfo: FunctionComponent<BestSolutionInfoProps> = ({
  data,
}): JSX.Element => {
  return (
    <>
      <Row>
        <Col span={24}>
          <Title
            ellipsis={{ tooltip: true }}
            level={5}
            type={validateTextTypeString(data.col4.bestOption.type)}
            className="bestOption-cancelation-title"
          >
            {data.col4.bestOption.text}
            <Tooltip placement="top" title={data.col4.bestOption.regime?.text}>
              <Text className="regime-text">
                <Icons
                  icon={validateIconType(data.col4.bestOption.regime?.icon)} // BEST PRICE
                />
              </Text>
            </Tooltip>
          </Title>
        </Col>
      </Row>
      <Row>
        {data.col3.features.map((feature: string) => (
          <Col key={feature} span={24}>
            <Text
              className="best-solution-features"
              ellipsis={{ tooltip: true }}
            >
              {feature}
            </Text>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default BestSolutionInfo;
