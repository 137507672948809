/* eslint-disable max-lines */
import React, { ReactElement, useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  FiltersProvider,
  TagsOrderSection,
} from "@triporate/triporate-design-system";
import { useLocation } from "react-router-dom";
import { SearchForm, Results, Header } from "../../components";
import { getAgentBookingToolData } from "../../services/AgentBookingTool";
import { parseLocationSearch } from "../../utils";
import { useAppliedFilters, useFiltersConfig } from "../../hooks";
import { Response as ResponseType } from "./types";
import { AppliedOrder } from "@triporate/triporate-design-system/dist/components/utils/FiltersUI/type";
import SideBar from "../../components/AgentBookingTool/SideBar";
import {
  CurrentInputsData,
  InputData,
  OrderConfig,
  SubmitData,
  FormData,
} from "../../components/SearchForm/types";
import { getInputsData } from "../../services/SearchForm";
import { addTagManager } from "../../utils/addTagManager";
import { TravellersFormIFrame } from "../../components/FormIFrames";

const { Content } = Layout;
type RequestData = {
  url: string;
  body: string;
};

type SearchFormType = {
  type: string;
  url: string;
};

const AgentBookingTool = (): ReactElement => {
  const { search } = useLocation();
  const [appliedFilters, setAppliedFilters] = useAppliedFilters();
  const [ABTData, setABTData] = useState<ResponseType["data"]>();
  const [appliedOrder, setAppliedOrder] = useState<AppliedOrder>();
  const [tripId, setTripId] = useState<string>();
  const [product, setProduct] = useState<string>();
  const [searchFormType, setSearchFormType] = useState<SearchFormType>();
  const [searchFormRequestData, setSearchFormRequestData] =
    useState<RequestData>();
  const [inputsInfo, setInputs] = useState<{ [key: string]: InputData }>();
  const [currentInputsData, setCurrentInputsData] = useState<CurrentInputsData>(
    {
      tripType: "1",
      fieldsAmount: 1,
    }
  );
  const [submitInfo, setSubmit] = useState<SubmitData>();
  const [inputsDictionary, setInputsDictionary] = useState<{
    [key: string]: {
      inputs: { [key: string]: InputData };
      newTraveller: SubmitData;
      submit: SubmitData;
    };
  }>({});
  const [filterBtn, setFilterBtn] = useState("");

  const [
    filtersConfig,
    {
      setFiltersTripTypeGroup,
      setRawFiltersConfig,
      setFiltersConfigDictionary,
    },
  ] = useFiltersConfig(currentInputsData, searchFormType?.type);

  const [orderConfig, setOrderConfig] = useState<OrderConfig>();

  useEffect(() => {
    const handleFetch = async ({ type, url }: SearchFormType) => {
      const { data } = await getInputsData(url);
      if (!data) return;
      setInputsDictionary((prevState) => ({ ...prevState, [type]: data }));
      handleStatesUpdate(data, type);
    };

    const handleStatesUpdate = (data: FormData, type: string) => {
      const { submit, inputs, filters, order, newTraveller } = data;
      setSubmit(submit);
      setInputs(inputs);
      setOrderConfig(order?.config);
      if (!filters) return;
      setFiltersTripTypeGroup(filters?.tripTypeGroup);
      setRawFiltersConfig(filters.config);
      setFilterBtn(filters.filterBtn);
      setFiltersConfigDictionary({ [type]: filters.config });
    };

    if (searchFormType?.type && inputsDictionary[searchFormType.type])
      handleStatesUpdate(
        inputsDictionary[searchFormType.type],
        searchFormType.type
      );
    else if (searchFormType) handleFetch(searchFormType);
  }, [
    searchFormType,
    inputsDictionary,
    setFiltersTripTypeGroup,
    setFiltersConfigDictionary,
    setRawFiltersConfig,
  ]);

  const handleFormSetters = async (searchFormType: SearchFormType) => {
    setSearchFormRequestData(undefined);
    setSearchFormType(searchFormType);
  };

  const handleOnHeaderItemChange = ({ type, url }: SearchFormType) => {
    if (type === product) return;
    handleFormSetters({
      type,
      url: url + `&tripId=${tripId}`,
    });
    setProduct(type);
    setAppliedOrder(undefined);
  };

  const handleInitialOption = (
    data: NonNullable<ResponseType["data"]>,
    id: string,
    currentProduct: string
  ) => {
    const currentProductOption = data.header.options.find(
      ({ type }) => type === currentProduct
    );
    if (currentProductOption) {
      const { type, href } = currentProductOption;
      handleFormSetters({
        type,
        url: href + `&tripId=${id}`,
      });
    }
  };

  useEffect(() => {
    const tripIdQuery = parseLocationSearch(search, "tripId");
    const productQuery = parseLocationSearch(search, "product");
    if (tripIdQuery) setTripId(tripIdQuery);
    if (productQuery) setProduct(productQuery);
  }, [search]);

  useEffect(() => {
    if (ABTData) return;
    let isMounted = true;
    const handleFetch = async (id: string, currentProduct: string) => {
      const { data } = await getAgentBookingToolData(id, currentProduct);

      if (!data || !isMounted) return;
      setABTData(data);
      handleInitialOption(data, id, currentProduct);
      addTagManager(data.header.company.name);
    };

    if (tripId && product) handleFetch(tripId, product);
    return () => {
      isMounted = false;
    };
  }, [tripId, product, ABTData]);

  return (
    <Layout>
      <Header
        data={ABTData?.header}
        onHeaderItemChange={handleOnHeaderItemChange}
        initialSelectedKeyKey={product}
      />
      <Content>
        <FiltersProvider
          config={filtersConfig}
          applyFilters={setAppliedFilters}
          flushAllFlag={searchFormType?.type + currentInputsData.tripType}
          orderConfig={orderConfig}
          applyOrder={setAppliedOrder}
          currentInputsData={currentInputsData}
        >
          <div className="searchForm-container">
            <Row>
              <Col span={24}>
                <SearchForm
                  isABT={true}
                  isEstimate={ABTData?.header.isEstimate}
                  estimateLabel={ABTData?.header.estimate}
                  formType={searchFormType}
                  handleSumbit={setSearchFormRequestData}
                  setAppliedFilters={setAppliedFilters}
                  setAppliedOrder={setAppliedOrder}
                  inputs={inputsInfo}
                  submit={submitInfo}
                  setCurrentInputsData={setCurrentInputsData}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col sm={21} md={6} xl={5} offset={1} className="content-col">
              <SideBar
                filtersConfig={filtersConfig}
                tabs={ABTData?.sideBar}
                urlArticles={ABTData?.articles.href}
              />
            </Col>
            <Col sm={21} md={15} xl={17} className="content-col space">
              <Row>
                <Col span={24}>
                  <TagsOrderSection filterBtn={filterBtn} />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Results
                    appliedFilters={appliedFilters}
                    searchFormRequestData={searchFormRequestData}
                    inputsType={searchFormType?.type}
                    appliedOrder={appliedOrder}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={1} />
          </Row>
        </FiltersProvider>
      </Content>
    </Layout>
  );
};

export default AgentBookingTool;
