import React, { FunctionComponent } from "react";
import { Button } from "@triporate/triporate-design-system";

interface SubmitButtonProps {
  label: string;
  onSubmit: () => void;
}

const SubmitButton: FunctionComponent<SubmitButtonProps> = ({
  label,
  onSubmit,
}): JSX.Element => {
  return (
    <Button
      className="submit-button"
      type="primary"
      htmlType="submit"
      id="search-btn-discovery"
      onClick={onSubmit}
    >
      {label}
    </Button>
  );
};

export default SubmitButton;
