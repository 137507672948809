import { FunctionComponent, useState, useEffect } from "react";

import { ValidTypes } from "../../validTypes";
import { AppliedFilters } from "../../commonTypes";
import { AppliedOrder } from "@triporate/triporate-design-system/dist/components/utils/FiltersUI/type";

import TransportResultsV3 from "./TransportResultsV3/TransportResultsV3";
import TransportResults from "./TransportResults";

type RequestData = {
    url: string;
    body: string;
};

type ResultsSwitchProps = {
    type?: string;
    requestData?: RequestData;
    appliedFilters: AppliedFilters[];
    setPaymentsRequestData: (url: string, body: string) => void;
    appliedOrder?: AppliedOrder;
    setIsAfterSearch?: (isAfterSearch: boolean) => void;
};

const SubResultsSwitch: FunctionComponent<ResultsSwitchProps> = ({
    type,
    requestData,
    appliedFilters,
    setPaymentsRequestData,
    appliedOrder,
    setIsAfterSearch,
}) => {
    const [tripType, setTripType] = useState<string>("");

    useEffect(() => {
        if (!requestData) return;

        const decodetType: any = JSON.parse(requestData.body);

        setTripType(decodetType.tripType);
    }, [requestData]);

    switch (tripType) {
        case "1":
            return (
                <TransportResults
                    key={ValidTypes.train}
                    requestData={requestData}
                    appliedFilters={appliedFilters}
                    setPaymentsRequestData={setPaymentsRequestData}
                    setIsAfterSearch={setIsAfterSearch}
                />
            );
        case "2":
            return (
                <TransportResultsV3
                    key={ValidTypes.train}
                    requestData={requestData}
                    appliedFilters={appliedFilters}
                    setPaymentsRequestData={setPaymentsRequestData}
                    setIsAfterSearch={setIsAfterSearch}
                />
            );
        default:
            return <></>;
    }
};

export default SubResultsSwitch;
