import React, { FunctionComponent } from "react";
import { Space } from "@triporate/triporate-design-system";

import { SegmentProps } from "./types";
import MainContent from "./MainContent";
import DetailsContent from "./DetailsContent";

const Segment: FunctionComponent<SegmentProps> = ({ ...props }) => {
  return (
    <Space direction="vertical" className="space-witdh-100">
      <MainContent {...props} />
      <DetailsContent {...props} />
    </Space>
  );
};

export default Segment;
