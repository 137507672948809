import React, { FunctionComponent, useState, useEffect } from "react";
import {
  Typography,
  Menu,
  Image,
  Icons,
  validateIconType,
  Col,
} from "@triporate/triporate-design-system";
import { ABTHeaderData, SBTHeaderData } from "./type";
import HelpModal from "./HelpModal";
import ProductInfoModal from "./ProductInfoModal";
import { Logout } from "./Logout";
import OldDiscoveryRedirectBtn from "./OldDiscoveryRedirectBtn";
import LoadingHeader from "./LoadingHeader";
import "./Header.scss";
import ShoppingBasket from "./ShoppingBasket";
import { BillingFulfillment } from "../BillingFulfillment";
import { AuthorizationService } from "../../services/Authorization";

const { Title, Text } = Typography;

enum Modes {
  online = "online",
  offline = "offline",
}

type HeaderProps = {
  data?: ABTHeaderData | SBTHeaderData;
  setShowSBTBasket?: (value: boolean) => void;
  onHeaderItemChange: (formData: { type: string; url: string }) => void;
  initialSelectedKeyKey?: string;
  tripCreated?: boolean;
};

type Item = {
  type: string;
  url: string;
  modal?: { message: string };
};

type ItemsDictionary = { [key: string]: Item };

const Header: FunctionComponent<HeaderProps> = ({
  data,
  setShowSBTBasket,
  onHeaderItemChange,
  initialSelectedKeyKey,
  tripCreated,
}): JSX.Element => {
  const [selectedKey, setSelectedKey] = useState<string>("");
  const [itemsDictionary, setItemsDictionary] = useState<ItemsDictionary>();
  const [productInfo, setProductInfo] = useState<string>();
  useEffect(() => {
    if (initialSelectedKeyKey) {
      setSelectedKey(initialSelectedKeyKey);
    }
  }, [initialSelectedKeyKey]);

  useEffect(() => {
    if (data) {
      const { options } = data;
      setItemsDictionary(
        options.reduce(
          (dictionary, { href, type, modal }) => ({
            ...dictionary,
            [type]: { type, url: href, modal },
          }),
          {}
        )
      );
    }
  }, [data]);

  useEffect(() => {
    const item = itemsDictionary?.[selectedKey];
    if (item) onHeaderItemChange(item);
  }, [itemsDictionary, selectedKey, onHeaderItemChange]);

  useEffect(() => {
    if (!itemsDictionary) return;
    const productData = itemsDictionary[selectedKey];
    setProductInfo(productData?.modal?.message);
  }, [selectedKey, itemsDictionary]);

  const handleMenuOnClick = ({ key }: { key: string | number }): void => {
    const keyStr = `${key}`;
    setSelectedKey(keyStr);
  };
  const logout = () => {
    const AuthService = new AuthorizationService();
    AuthService.logout();
  };

  if (!data)
    return (
      <div className="header-menu header-menu_skeleton">
        <LoadingHeader />
      </div>
    );

  return (
    <div className="nav-wrapper">
      <Col span={16}>
        <Menu
          mode="horizontal"
          selectedKeys={[selectedKey]}
          onClick={handleMenuOnClick}
          className="header-menu"
        >
          {data?.mode === Modes.online && (
            <>
              <Menu.Item key="no-events-1" className="no-pointer-events">
                <div className="logo">
                  <Image
                    width={30}
                    src={data?.company.logo}
                    preview={false}
                    alt={data?.company.alt}
                  />
                </div>
              </Menu.Item>
              <Menu.Item key="no-events-2" className="no-pointer-events">
                <Text>{data?.label}</Text>
              </Menu.Item>
            </>
          )}
          {data?.mode === Modes.offline && (
            <>
              <Menu.Item key="no-events-3" className="no-pointer-events">
                {data?.company.agency && (
                  <div className="logo">
                    <Image
                      width={30}
                      src={data?.company.agency.logo}
                      preview={false}
                      alt={data?.company.agency.alt}
                    />
                  </div>
                )}
              </Menu.Item>
              <Menu.Item
                key="no-events-4"
                className="no-pointer-events trip-number-item"
              >
                <Title level={4} className="trip-number-item">
                  {data?.tripNumber?.label}{" "}
                  <Text>{data?.tripNumber?.number}</Text>
                </Title>
              </Menu.Item>
            </>
          )}
          {data?.options.map(({ type, label, icon }) => (
            <Menu.Item key={type} className="header-menu__item">
              <Icons icon={validateIconType(icon.class)} />
              {label}
            </Menu.Item>
          ))}
          {data?.mode === Modes.offline && data?.oldDiscoveryRedirect && (
            <OldDiscoveryRedirectBtn
              type={selectedKey}
              data={data?.oldDiscoveryRedirect}
            />
          )}
        </Menu>
      </Col>
      <Col span={8}>
        <div className="nav-top-div">
          <BillingFulfillment onlineMode={data?.mode === Modes.online} />
          {data?.mode === Modes.online && (
            <>
              {tripCreated && setShowSBTBasket && (
                <ShoppingBasket
                  label={data?.basket.basketLabel}
                  setShowSBTBasket={setShowSBTBasket}
                />
              )}

              <HelpModal data={data?.help} />

              <Logout data={data.logout.icon} handleLogout={logout} />
            </>
          )}
        </div>
      </Col>
    </div>
  );
};

export default Header;
