import React, { FunctionComponent } from "react";
import {
  Col,
  Row,
  Typography,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";

import { BestSolution as BestSolutionType } from "../../../types";

const { Title, Text, Link } = Typography;

type BestSolutionDetailsProps = {
  data: BestSolutionType;
  showOtherSolutions: boolean;
};

const BestSolutionDetails: FunctionComponent<BestSolutionDetailsProps> = ({
  data,
  showOtherSolutions,
}): JSX.Element => {
  const hotelCategoryIcons = (stars: number) => {
    const hotelStars = Number(stars) === undefined ? 0 : Number(stars);
    return Array.from(Array(hotelStars), (_, index) => (
      <Icons key={index} icon={validateIconType("star")} />
    ));
  };

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={14}>
            <Title
              className="title-with-tooltip"
              ellipsis={{ tooltip: true }}
              level={5}
            >
              {data.col2.title.text}
            </Title>
          </Col>
          <Col offset={1} span={7}>
            <Title level={5}>
              <span style={{ fontSize: "12px" }}>
                {hotelCategoryIcons(data.col2.category.number)}
              </span>
            </Title>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Icons icon={validateIconType(data.col2.distance.icon.class)} />
        <Text ellipsis={{ tooltip: true }}>{data.col2.distance.text} </Text>
      </Col>

      {showOtherSolutions ? (
        <Col span={24}>
          <Link href={data.col2.address.mapURL} target="_blank">
            <Icons icon={validateIconType(data.col2.address.icon.class)} />
            <Text ellipsis={{ tooltip: true }}>{data.col2.address.text}</Text>
          </Link>
        </Col>
      ) : null}
    </Row>
  );
};

export default BestSolutionDetails;
