import { FetchService } from "../services/FetchService";
import { store } from "../store";
import {
  handleSetConfig,
  ConfigState,
  configInitialState,
} from "../store/config";
import { ClientConfigApiUrl } from "./ClientConfigApiUrl";

const useLocalEnvConfig = process.env.REACT_APP_USE_LOCAL_ENV || "";
const googleTagManagerId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || "";
const apiHost = process.env.REACT_APP_API_HOST || "";
const clientDomain = process.env.REACT_APP_CLIENT_DOMAIN || "";
const environment = process.env.REACT_APP_ENVIRONMENT || "";
const loginClientUrl = process.env.REACT_APP_LOGIN_CLIENT_URL || "";
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY || "";
const universe = process.env.REACT_APP_UNIVERSE || "";
const favicon = process.env.REACT_APP_FAVICON || "";
const backendUrl = process.env.REACT_BACKEND_URL || "";

export class ConfigService {
  private useLocalEnvConfig = useLocalEnvConfig === "false";
  private defaultConfig = configInitialState;

  private async getConfigUrl(): Promise<string> {
    const clientConfigApiUrl = new ClientConfigApiUrl(window.location.host);
    return clientConfigApiUrl.getUrl("discovery");
  }

  private dispatchConfig(data: unknown) {
    const validConfig = data as ConfigState["config"];
    validConfig.loadedConfig = true;
    store.dispatch(handleSetConfig(validConfig));
  }

  private handleFakeApiConfig() {
    const defaultConfig = this.defaultConfig as ConfigState["config"];
    this.dispatchConfig(defaultConfig);
  }

  private handleLocalEnvConfig() {
    const envVariables = {
      googleTagManagerId,
      apiHost,
      clientDomain,
      environment,
      loginClientUrl,
      googleMapsApiKey,
      universe,
      favicon,
      backendUrl,
    };
    this.dispatchConfig(envVariables);
  }

  private async handleFetchedConfig(fetchService: FetchService) {
    const configUrl = await this.getConfigUrl();
    const { data } = await fetchService.get(configUrl);

    if (!data) {
      return;
    }

    const validConfig = data as ConfigState["config"];
    this.dispatchConfig(validConfig);
  }

  public async setConfig(): Promise<void> {
    const fetchService = new FetchService();
    if (fetchService.useFakeApi) {
      return this.handleFakeApiConfig();
    }

    if (this.useLocalEnvConfig) {
      return this.handleLocalEnvConfig();
    }

    this.handleFetchedConfig(fetchService);
  }
}
