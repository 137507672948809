import React, { FunctionComponent } from "react";
import { Row, Col, Typography } from "@triporate/triporate-design-system";

const { Title } = Typography;

type NoResultsMessageProps = {
  message?: string;
};

const NoResultsMessage: FunctionComponent<NoResultsMessageProps> = ({
  message = "No hay resultados",
}) => {
  return (
    <Row justify="center">
      <Col>
        <Title level={2}>{message}</Title>
      </Col>
    </Row>
  );
};

export default NoResultsMessage;
