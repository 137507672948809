import { ActionTypes, LOGIN, LOGOUT, AUTHENTICATE } from "./types";

export const login = (): ActionTypes => ({
  type: LOGIN,
});

export const logout = (): ActionTypes => ({
  type: LOGOUT,
});

export const handleAuthentication = (
  isAuthenticated: boolean
): ActionTypes => ({
  type: AUTHENTICATE,
  payload: isAuthenticated,
});
