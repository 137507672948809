import React, { FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppliedFilters } from "../../../commonTypes";
import { parseLocationSearch } from "../../../../utils";
import { getHotelResults } from "../../../../services/Results";
import LoadingResults from "../LoadingResults";
import NoResultsMessage from "../NoResultsMessage";
import { RequestData, ResultsData } from "./types";
import {
  FilterValues,
  ResultFiltered,
} from "../../../../services/Results/getHotelResults/type";
import Context from "./Context";
import Solutions from "./Solutions";
import ConfirmationDrawer from "../ConfirmationDrawer";
import { useFilterResults } from "@triporate/triporate-design-system";

type HotelResultsProps = {
  requestData?: RequestData;
  appliedFilters?: AppliedFilters[];
  appliedOrder?: AppliedFilters;
  setPaymentsRequestData: (url: string, body: string) => void;
};

const setFilterValues = (
  newFilterValues: FilterValues,
  defaultFilterValues: FilterValues
): FilterValues => {
  defaultFilterValues.regime = newFilterValues.regime;
  defaultFilterValues.fares = newFilterValues.fares;
  defaultFilterValues.price = newFilterValues.price;
  return defaultFilterValues;
};

const parseToInitialOption = (result: ResultFiltered): ResultFiltered => {
  const { initialOption, options } = result;
  const newInitialOption = options[0].options;
  Object.keys(initialOption.details).forEach((key) => {
    if (key === "col3")
      initialOption.details[key].features = [newInitialOption[0].col1.text];
    if (key === "col4") {
      const regime = initialOption.details[key].bestOption.regime;
      if (regime?.text) regime.text = newInitialOption[0].col1.text;
      initialOption.details[key].bestOption.regime = regime;
      initialOption.details[key].bestOption.text =
        newInitialOption[0].col3.cancellation.text;
      initialOption.details[key].bestOption.type =
        newInitialOption[0].col3.cancellation.type;
    }
    if (key === "col5")
      initialOption.details[key].price.money.amount =
        newInitialOption[0].col4.price.money.amount;
    if (key === "col6")
      initialOption.details[key].solutionId =
        newInitialOption[0].col5.solutionId;
  });
  initialOption.filterValues = setFilterValues(
    options[0].filterValues,
    initialOption.filterValues
  );
  return {
    initialOption,
    options,
    noMoreOptionsMessage: result.noMoreOptionsMessage,
  };
};

const HotelResults: FunctionComponent<HotelResultsProps> = ({
  requestData,
  appliedFilters = [],
  setPaymentsRequestData,
  appliedOrder,
}): JSX.Element => {
  const { search } = useLocation();

  const [searched, setSearched] = useState(false);
  const [results, setResults] = useState<ResultsData["results"]>([]);
  const [noResults, setNoResults] = useState<ResultsData["noResults"]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [summaryRequestData, setSummaryRequestData] =
    useState<RequestData | null>(null);

  useEffect(() => {
    const handleFetch = async ({ url, body }: RequestData) => {
      setResults([]);
      setSearched(true);
      setLoading(true);

      const { data } = await getHotelResults(url, body);
      if (data) {
        setResults(data.results);
        setNoResults(data.noResults);
      }

      setLoading(false);
    };

    if (requestData) handleFetch(requestData);
  }, [requestData]);

  const onBuyButtonClick = (options: {
    href: string;
    showSummary?: boolean | undefined;
    solutionId?: string | undefined;
  }) => {
    if (!options.href) return;
    const offerId = parseLocationSearch(search, "offerId");
    const tripId = parseLocationSearch(search, "tripId");

    const body = JSON.stringify({
      offerId,
      solutionId: options.solutionId,
      tripId,
    });

    if (options.showSummary) {
      setSummaryRequestData({ url: options.href, body });
    } else {
      setPaymentsRequestData(options.href, body);
    }
  };
  const [filteredResults, setAppliedFilters, setAppliedOrder] =
    useFilterResults(results);

  useEffect(() => {
    setAppliedFilters(appliedFilters);
  }, [appliedFilters, setAppliedFilters]);

  useEffect(() => {
    setAppliedOrder(appliedOrder);
  }, [appliedOrder, setAppliedOrder]);

  if (loading) return <LoadingResults />;
  if (searched && !results?.length)
    return <NoResultsMessage message={noResults?.apiMsg} />;
  if (searched && !filteredResults?.length)
    return <NoResultsMessage message={noResults?.filtersMsg} />;

  return (
    <Context.Provider value={{ onBuyButtonClick }}>
      <Solutions
        solutions={filteredResults.map((result) => {
          if (result.initialOption.isFilterPassed === false)
            return parseToInitialOption(result);
          return result;
        })}
      />

      <ConfirmationDrawer
        requestData={summaryRequestData}
        cleanRequestData={() => {
          setSummaryRequestData(null);
        }}
        cleanOnClose
        setPaymentsRequestData={setPaymentsRequestData}
      />
    </Context.Provider>
  );
};

export default HotelResults;
