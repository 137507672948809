import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Form as AntdForm, Row, Col, Modal } from "@triporate/triporate-design-system";

import {
  InputData,
  SubmitData,
  FormValues,
  Fields,
  DirtyFields,
  CurrentInputsData,
} from "../types";
import InputGroupSwitch from "./InputGroupSwitch";
import { useDriverAndPassengersOptionsHandler, formatBody } from "./utils";
import { ValidTypes } from "../../validTypes";
import { WarningInformation } from "../../../pages/SelfBookingTool/types";
import AirlinesFormComponents from "../AirlinesForm";

type FormProps = {
  isABT: boolean;
  type?: string;
  tripId?: string;
  inputs: { [key: string]: InputData };
  addNewTraveller?: SubmitData;
  showTravellerFormIFrame?: () => void;
  newTravellerIdCreated?: string;
  submit: SubmitData;
  handleSumbit: (data: { url: string; body: string }) => void;
  searchIsDirty?: (data: DirtyFields) => void;
  setCurrentInputsData: (data: CurrentInputsData) => void;
  isEstimate?: boolean;
  estimateLabel?: string;
  warningInfo?: WarningInformation
  isAfterSearch?: boolean
};

const Form: FunctionComponent<FormProps> = ({
  isABT,
  type,
  inputs,
  addNewTraveller,
  showTravellerFormIFrame,
  newTravellerIdCreated,
  submit,
  handleSumbit,
  searchIsDirty,
  setCurrentInputsData,
  isEstimate,
  estimateLabel,
  children,
  warningInfo,
  isAfterSearch
}): JSX.Element => {
  const [form] = AntdForm.useForm();
  const [formValues, setFormValues] = useState<FormValues>({});
  const [fieldsAmount, setFieldsAmount] = useState(1);
  const [isSearchDirty, setIsSearchDirty] = useState<DirtyFields>({
    isDirty: false,
    sumbit: {},
    dirty: {},
  });

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  const [modalAirlineForm, setModalAirlineForm] = React.useState<boolean>(false);
  const [airlineFormData, setAirlineFormData] = React.useState<any>();

  useEffect(() => setFormValues({}), [type]);

  useEffect(() => {
    if (formValues.tripType) {
      const tripType: string = formValues.tripType?.value;
      setCurrentInputsData({ tripType, fieldsAmount });
    }
  }, [formValues.tripType, fieldsAmount, setCurrentInputsData]);

  useEffect(() => {
    if (searchIsDirty) searchIsDirty(isSearchDirty);
  }, [searchIsDirty, isSearchDirty]);

  const onFinish = (formData: Fields) => {
    if (!submit || !type) return;

    let formatedBody: any = formatBody(formData, type);

    formatedBody = { ...formatedBody , ...airlineFormData}

    handleSumbit({ url: submit?.href, body: JSON.stringify(formatedBody) });

    setIsSearchDirty({
      isDirty: false,
      sumbit: formData,
      dirty: {},
    });
  };

  const onValuesChange = (newValue: FormValues, values: FormValues) => {
    const isFlightValue = "segments" in newValue;

    if (!isFlightValue) {
      if (isFirstRender) setIsFirstRender(false);
    }

    setFormValues(values);

    const [key, value] = Object.entries(newValue)[0];
    setIsSearchDirty((prevState) => ({
      ...prevState,
      isDirty: true,
      dirty: {
        ...prevState?.dirty,
        [key]: value,
      },
    }));
  };

  const setFieldsValue = useCallback(
    (values: Fields) => {
      const isFlightValue = "segments" in values;
      if (isFlightValue && !isFirstRender) setIsFirstRender(true);

      const inputValue = Object.values(values)[0];
      const propertyInput = Object.keys(values)[0];
      const isValidForm =
        type === ValidTypes.flight || type === ValidTypes.train;
      let newValues = { ...values };

      setFormValues((prevState) => {
        //search if this property is already existing in formValues and add new prop
        if (
          Array.isArray(prevState[propertyInput]) &&
          prevState[propertyInput][0] !== undefined &&
          isValidForm
        ) {
          const allValues = prevState[propertyInput];
          const firstValue = prevState[propertyInput][0];

          //get previous values and add new value for the same propertyInput
          const newValue = allValues.map((value: FormValues, i: number) => {
            //default values only load at first obj
            if (i === 0) return { ...firstValue, ...inputValue[0] };
            return value;
          });
          newValues = { [propertyInput]: newValue };
          return { ...prevState, [propertyInput]: newValue };
        }
        return { ...prevState, ...values };
      });

      form.setFieldsValue(newValues);
    },
    [form, type]
  );

  useDriverAndPassengersOptionsHandler({
    usersFormValue: formValues.users,
    setNewUsersValue: setFieldsValue,
    currentType: type,
  });

  const onSubmit = async () => {
    //if it's first render submit attempt
    if (!isSearchDirty.isDirty) setIsFirstRender(false);
  };

  return (
    <>  
    <AntdForm
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      form={form}
      hideRequiredMark
    >
      <Row gutter={[20, 2]}>
        <InputGroupSwitch
          isABT={isABT}
          formValues={formValues}
          setFieldsValue={setFieldsValue}
          setFieldsAmount={setFieldsAmount}
          type={type}
          inputs={inputs}
          addNewTraveller={addNewTraveller}
          showTravellerFormIFrame={showTravellerFormIFrame}
          newTravellerIdCreated={newTravellerIdCreated}
          getFieldValue={form.getFieldValue}
          isEstimate={isEstimate}
          estimateLabel={estimateLabel}
          isFirstSubmit={isFirstRender}
          validateFields={form.validateFields}
          label={submit.label}
          onSubmit={onSubmit}
          warningInfo={warningInfo}
          isAfterSearch={isAfterSearch}
          onShowAirlinesEvent={setModalAirlineForm}
        />
        <Col span={20}>{children}</Col>
      </Row>
      </AntdForm>

      <Modal
        title="Aerolíneas"
        width={800}
        closable={false}
        okText="Listo"
        visible={modalAirlineForm}
        onOk={() => setModalAirlineForm(false)}
        onCancel={() => setModalAirlineForm(false)}
        className="header-button help-modal-button product-info-modal"
      >
        <AirlinesFormComponents onChange={setAirlineFormData} />
      </Modal></>
  );
};

export default Form;
