import React, { FunctionComponent } from "react";
import { Row, Col } from "@triporate/triporate-design-system";
import Segments from "../Segments";

import { JourneyData, SegmentId } from "../types";

type SegmentIdsDictionary = {
  [key: number]: SegmentId;
};

type JourneysProps = {
  journeys: JourneyData[];
  opendedSegmentIdDictionary: SegmentIdsDictionary;
  setOpendedSegmentIdDictionary: (key: number, id?: SegmentId) => void;
  selectedSegmentsIds: SegmentIdsDictionary;
  setSelectedSegment: (key: number, id: SegmentId) => void;
};

const Journeys: FunctionComponent<JourneysProps> = ({
  journeys,
  opendedSegmentIdDictionary,
  setOpendedSegmentIdDictionary,
  ...props
}) => (
  <Row gutter={[0, 20]}>
    {journeys.map((segments, key) => (
      <Col className="journey-border" key={key} span={24}>
        <Segments
          {...props}
          opendedSegmentId={opendedSegmentIdDictionary[key]}
          setOpendedSegmentId={(id?: SegmentId) =>
            setOpendedSegmentIdDictionary(key, id)
          }
          index={key}
          segments={segments}
        />
      </Col>
    ))}
  </Row>
);

export default Journeys;
