import React, { FunctionComponent } from "react";
import {
  Row,
  Col,
  Image,
  ImageRibbon,
  Typography,
} from "@triporate/triporate-design-system";

import { CarJourneyData } from "../types";

const { Title, Text } = Typography;

type CarJourneyVehicleProps = {
  data?: CarJourneyData["vehicle"];
};

const CarJourneyVehicle: FunctionComponent<CarJourneyVehicleProps> = ({
  data,
}) => (
  <Row>
    <Col span={12}>
      <ImageRibbon width={50} src={data?.provider}>
        <Image
          className="journey-provider-logo"
          width={200}
          src={data?.image}
          preview={false}
        />
      </ImageRibbon>
    </Col>
    <Col span={12}>
      <Title className="journey-section-title" level={4}>
        {data?.title}
      </Title>
      <Text className="journey-text-block">{data?.subtitle}</Text>
      <Text className="journey-text-block" strong>
        {data?.text}
      </Text>
    </Col>
  </Row>
);

export default CarJourneyVehicle;
