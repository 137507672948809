import { useMappedState } from "redux-react-hook";
import { RoleState, Roles } from "../store/role";

type Loading = boolean;
type CanIUse = boolean;

function useCanIUseByRole(role: string): [CanIUse, Loading] {
  const mapState = (state: RoleState): string | undefined => state.role;
  const userRole = useMappedState(mapState);

  return [userRole === role, userRole === Roles.unset];
}

export default useCanIUseByRole;
