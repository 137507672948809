import { parseLocationSearch } from "../../../utils";
import { FetchService } from "../../FetchService";
import { flightResults, trainResults } from "./mockData";
import { ResultsData, NoResults } from "./type";

interface Response {
  error?: boolean;
  data?: {
    results: ResultsData;
    returnResults: ResultsData;
    noResults: NoResults;
  };
}

const handleMockData = (key: string): any => {
  let data;
  let error = false;
  switch (true) {
    case key.includes("flight-results"):
      data = flightResults;
      break;
    case key.includes("train-results"):
      data = trainResults;
      break;
    default:
      error = true;
  }

  return { data, error };
};

export async function getTransportResults(
  url: string,
  body: string
): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData(url);

  const tripId = parseLocationSearch(window.location.href, "tripId");

  if (tripId) {
    const parsedBody = JSON.parse(body);
    parsedBody.tripId = tripId;
    body = JSON.stringify(parsedBody);
  }

  const { data, error } = await fetchService.post(url, body);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
