import React, { FunctionComponent, useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Typography,
} from "@triporate/triporate-design-system";
import { SummaryNotes } from "../../types";
import { ExtraInfoData } from "../types";

const { TextArea } = Input;

const { Text } = Typography;

type ExtraInfoProps = {
  data: ExtraInfoData;
  addonsData?: string;
  handleNotes: (data: SummaryNotes) => void;
};

const ExtraInfo: FunctionComponent<ExtraInfoProps> = ({
  data,
  addonsData,
  handleNotes,
}) => {
  const { field1, field2 } = data;

  const [textData, setTextData] = useState({
    [field1.name]: addonsData ? addonsData : field1.value,
    [field2.name]: field2.value,
  });

  useEffect(() => {
    if (handleNotes && textData) handleNotes(textData);
  }, [textData, handleNotes]);

  useEffect(() => {
    setTextData({
      [field1.name]: addonsData ? addonsData : field1.value,
      [field2.name]: field2.value,
    });
  }, [field1, field2, addonsData]);

  const handleTextUpdate = (key: string, text: string) => {
    setTextData((prevState) => ({ ...prevState, [key]: text }));
  };

  return (
    <Row justify="space-around">
      <Col span={11}>
        <label htmlFor={field1.name}>
          <Text strong>{field1.label}</Text>
        </label>
        <TextArea
          autoSize={{ minRows: 4 }}
          id={field1.name}
          showCount
          maxLength={field1.maxLength || 100}
          value={textData[field1.name]}
          placeholder={field1.placeholder}
          onChange={(value) =>
            handleTextUpdate(field1.name, value.target.value)
          }
        />
      </Col>
      <Col offset={2} span={11}>
        <label htmlFor={field2.name}>
          <Text strong>{field2.label}</Text>
        </label>
        <TextArea
          autoSize={{ minRows: 4 }}
          id={field2.name}
          showCount
          maxLength={field2.maxLength || 100}
          value={textData[field2.name]}
          placeholder={field2.placeholder}
          onChange={(value) =>
            handleTextUpdate(field2.name, value.target.value)
          }
        />
      </Col>
    </Row>
  );
};

export default ExtraInfo;
