import React from "react";
import { Col, Row, Skeleton } from "@triporate/triporate-design-system";

import "../SearchForm.scss";

const SkeletonInputs = (): JSX.Element => {
  return (
    <>
      <Row style={{ paddingTop: "8px", paddingBottom: "8px" }}>
        <Col span={1} />
        <Col span={20}>
          <Skeleton.Input active size="small" />
        </Col>
        <Col span={1} />
      </Row>
      <Row style={{ paddingBottom: "8px" }}>
        <Col span={1} />
        <Col span={7}>
          <Skeleton.Input active size="small" style={{ minWidth: "300px" }} />
        </Col>
        <Col span={7}>
          <Skeleton.Input active size="small" style={{ minWidth: "300px" }} />
        </Col>
        <Col span={7}>
          <Skeleton.Input active size="small" style={{ minWidth: "300px" }} />
        </Col>
        <Col span={1} />
      </Row>
      <Row>
        <Col span={1} />
        <Col span={20}>
          <Skeleton.Input active size="small" style={{ minWidth: "300px" }} />
        </Col>
        <Col>
          <Skeleton.Input size="small" style={{ width: 50 }} active />
        </Col>
        <Col span={1} />
      </Row>
    </>
  );
};

export default SkeletonInputs;
