import { OptionsGroup, Option } from "../../../../types";

enum ValidModes {
  multiple = "multiple",
  tags = "tags",
}

export const validateSelectModeValue = (
  mode?: string
): ValidModes | undefined => ValidModes[mode as keyof typeof ValidModes];

export const getDefaultValue = (
  options: OptionsGroup[],
  defaultValue: Option["value"] | string[]
): Option | Option[] | undefined => {
  const optionsList: Option[] = [];
  options.forEach(({ options }) => optionsList.push(...options));

  if (Array.isArray(defaultValue)) {
    const optionsSelectedList: Option[] = [];
    defaultValue.forEach((option) => {
      const optionData = optionsList.find(({ value }) => value === option);

      if (optionData) optionsSelectedList.push(optionData);
    });

    return optionsSelectedList;
  }

  return optionsList.find(({ value }) => value === defaultValue);
};
