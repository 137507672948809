import React, { FunctionComponent, useState } from "react";
import { Row, Col } from "@triporate/triporate-design-system";
import { FareData } from "../../types";
import Luggage from "./Luggage";
import Seats from "./Seats";

type LuggageAndSeatProps = {
  luggageIcon: FareData["col3"];
  luggage: FareData["col4"];
  seat: FareData["col5"];
};

const LuggageAndSeat: FunctionComponent<LuggageAndSeatProps> = ({
  luggageIcon,
  luggage,
  seat,
}) => {
  const [layoutCondition] = useState(luggage.text3 && luggage.text4);
  return (
    <Row>
      <Col span={layoutCondition ? 10 : undefined}>
        <Luggage iconData={luggageIcon} data={luggage} />
      </Col>
      {layoutCondition ? <Col span={4} /> : null}

      <Col span={layoutCondition ? 10 : undefined}>
        <Seats data={seat} />
      </Col>
    </Row>
  );
};

export default LuggageAndSeat;
