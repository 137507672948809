import React, { FunctionComponent, useEffect } from "react";
import { useMappedState } from "redux-react-hook";
import {
  useCustomTabFavicon,
  useUniverseTheme,
} from "@triporate/triporate-design-system";

import { ConfigState } from "../../../store/config";

const ConfigContainer: FunctionComponent = ({ children }): JSX.Element => {
  const mapState = (state: ConfigState): ConfigState["config"] => state.config;
  const { universe, favicon } = useMappedState(mapState);

  useUniverseTheme(universe);
  useCustomTabFavicon(favicon);

  return <>{children}</>;
};

export default ConfigContainer;
