import { CloseCircleOutlined, RedoOutlined } from "@ant-design/icons";
import { Button, Tag } from "@triporate/triporate-design-system";
import { useTranslation } from "react-i18next";

interface Props {
    isRefreshing?: boolean;
    onClickButton: () => void;
}

export const ErrorPricing = ({
    isRefreshing = false,
    onClickButton,
}: Props) => {
    const { t } = useTranslation();

    return (
        <div
            style={{
                marginTop: 10,
                display: "flex",
                gap: 10,
                justifyContent: "end",
                alignItems: "center",
            }}
        >
            <Tag icon={<CloseCircleOutlined />} color="error">
                {t("TitleTagErrorPricing")}
            </Tag>
            <Button
                type="primary"
                icon={<RedoOutlined />}
                loading={isRefreshing}
                onClick={onClickButton}
            />
        </div>
    );
};
