import React, { FunctionComponent } from "react";
import {
  Row,
  Col,
  Image,
  Typography,
  Price,
  Space,
  Icons,
  validateIconType,
  Button,
} from "@triporate/triporate-design-system";
import BuyButton from "../../BuyButton";
import { validateTextTypeString } from "../../../../../../utils/validateTextTypeString";
import { Result } from "../../types";

import "../../CarResults.scss";

const { Title, Text } = Typography;

export type SolutionProps = {
  data: Result;
  showDetails: boolean;
  handleMoreInfo: () => void;
};

const MainContent: FunctionComponent<SolutionProps> = ({
  data,
  showDetails,
  handleMoreInfo,
}) => {
  return (
    <Row align="middle" gutter={0}>
      <Col span={3}>
        <Image width={100} src={data.col1.image} preview />
      </Col>
      <Col span={5}>
        <Title
          className="title-with-tooltip"
          ellipsis={{ tooltip: true }}
          level={5}
        >
          {data.col2.title.text}
        </Title>
        <Text ellipsis={{ tooltip: true }}>{data.col2.label.text}</Text>
      </Col>
      <Col offset={1} span={6}>
        <Row justify="start">
          {data.col3.additionals.map(({ icon, label }, key) => (
            <Col key={key} offset={key && 1}>
              <Icons icon={validateIconType(icon)} />
              <span style={{ marginLeft: "5px" }}>{label}</span>
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={3} className="fare-container">
        <Text strong>{data.col7.fareName}</Text>
        <Text className="fare-container_subtext"> ({data.col7.fareCode}) </Text>
      </Col>
      <Col span={2} onClick={handleMoreInfo}>
        <Button type="link">
          {showDetails ? "- " : "+ "}
          {data.col4.moreInfo.text}
        </Button>
      </Col>
      <Col span={4}>
        <Space align="center" direction="vertical" className="space-witdh-100">
          <Price
            money={data.col5.price.money}
            status={validateTextTypeString(data.col5.price.status)}
          />
          <BuyButton {...data.col6} />
        </Space>
      </Col>
    </Row>
  );
};

export default MainContent;
