export function validateStringPlaceholder(
  placehoder?: string | string[]
): string | undefined {
  if (typeof placehoder === "string") return placehoder;
}

export function validateArrayOfStringsPlaceholder(
  placehoder?: string | string[]
): [string, string] | undefined {
  if (Array.isArray(placehoder) && placehoder.length >= 2)
    return [placehoder[0], placehoder[1]];
}
