import React, { FunctionComponent, useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row } from "@triporate/triporate-design-system";

import "../AgentsBookingTool.scss";
import SideBarTabs from "./SideBarTabs";
import SideBarContent from "./SideBarContent";
import { Filters } from "../../../services/SearchForm/getInputsData/types";

type SideBarProps = {
  filtersConfig: Filters[];
  tabs?: {
    tabName: string;
    key: string;
  }[];
  urlArticles?: string;
};

const SideBar: FunctionComponent<SideBarProps> = ({
  tabs,
  urlArticles,
  filtersConfig,
}): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState("");
  const [defaultKey, setDefaultKey] = useState("");

  useEffect(() => {
    if (tabs?.length) {
      const firstTab = !_.isEmpty(tabs[0]) ? tabs[0].key : tabs[1].key;
      setDefaultKey(firstTab);
      setSelectedTab(firstTab);
    }
  }, [tabs]);
  return (
    <Row className="sideBar-container">
      <Col span={24}>
        <SideBarTabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          defaultKey={defaultKey}
        />
        <SideBarContent
          filtersConfig={filtersConfig}
          selectedTab={selectedTab}
          url={urlArticles}
        />
      </Col>
    </Row>
  );
};

export default SideBar;
