import React, { FunctionComponent } from "react";
import {
  Typography,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";

import { EmailData } from "../types";

const { Text } = Typography;

type TextWithPhoneLinkProps = {
  data?: EmailData;
};

const TextWithPhoneLink: FunctionComponent<TextWithPhoneLinkProps> = ({
  data,
}) => {
  return (
    <Text
      style={{
        marginLeft: "5px",
      }}
    >
      <a href={data?.href} target="_blank" rel="noreferrer">
        {data?.tripNumber}
      </a>
      <Icons icon={validateIconType(data?.icon)} className="primary-color" />
    </Text>
  );
};

export default TextWithPhoneLink;
