import React, { FunctionComponent } from "react";
import { Row, Col, Typography } from "@triporate/triporate-design-system";
import { Item as ItemType } from "../../../../types";

const { Title } = Typography;

type TripSummaryProps = {
  data: ItemType;
};

const TripSummary: FunctionComponent<TripSummaryProps> = ({
  data,
}): JSX.Element => {
  return (
    <Row>
      <Col span={24}>
        <Title level={5}>{data.row1.title.text}</Title>
      </Col>
      <Col span={24}>
        {data.row2.icon && <i className={data.row2.icon} />}
        {data.row2.text}
      </Col>
      <Col span={24}>
        {data.row3.icon && <i className={data.row3.icon} />}
        {data.row3.text}
      </Col>
      <Col span={24}>
        {data.row4.icon && <i className={data.row4.icon} />}
        {data.row4.text}
      </Col>
      <Col span={24}>
        {data.row5.icon && <i className={data.row5.icon} />}
        {data.row5.text}
      </Col>
      <Col span={24}>
        {data.row6.icon && <i className={data.row6.icon} />}
        {data.row6.text}
      </Col>
    </Row>
  );
};

export default TripSummary;
