import { FunctionComponent } from "react";
import { Row, Col } from "@triporate/triporate-design-system";
import Segments from "../Segments";

import { JourneyData } from "../types";
import { lastTicketDateInfo } from "../../../../../services/Results/getTransportResults/type";

type JourneysProps = {
    journeys: JourneyData[];
    provider: string;
    lastTicketDate: lastTicketDateInfo;
};

const Journeys: FunctionComponent<JourneysProps> = ({
    journeys,
    provider,
    lastTicketDate,
    ...props
}) => (
    <Row gutter={[0, 20]}>
        {journeys.map((segments, key) => (
            <Col className="journey-border" key={key} span={24}>
                <Segments
                    {...props}
                    index={key}
                    segments={segments}
                    provider={provider}
                    lastTicketDate={lastTicketDate}
                />
            </Col>
        ))}
    </Row>
);

export default Journeys;
