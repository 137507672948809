import { Row, Col, Button, Icons, validateIconType } from '@triporate/triporate-design-system';
import { EShowMode } from '../Context';
import { FC, useState } from 'react';

type TripModeSelectorProps = {
    tripModeSelected: EShowMode,
    onChangeModeEvent: (mode: EShowMode) => void
}

const TripModeSelectorComponent: FC<TripModeSelectorProps> = ({ tripModeSelected, onChangeModeEvent }) => {

    const handleButtonClick = (mode: EShowMode) => onChangeModeEvent(mode)

    return (
        <div className="trip-mode-selector-card">
            <Row className="trip-mode-selector">
                <Col span={24}>
                    <Row>
                        <Col span={12}>
                            <Button type={tripModeSelected == EShowMode.FROM ? 'primary' : 'default'} shape="round" size="large" onClick={() => handleButtonClick(EShowMode.FROM)} style={{ width: 100, height: 50 }} >
                                <Row justify='start'>
                                    <Col span={12}>
                                        <Icons icon={validateIconType("arrowRight")} size="big" />
                                    </Col>
                                    <Col span={12} style={{ margin: 'auto' }}>Ida</Col>
                                </Row>
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button type={tripModeSelected == EShowMode.TO ? 'primary' : 'default'} shape="round" size="large" onClick={() => handleButtonClick(EShowMode.TO)} style={{ width: 100, height: 50 }} >
                                <Row justify='start'>
                                    <Col span={12}>  <Icons icon={validateIconType("arrowLeft")} size="big" /></Col>
                                    <Col span={12} style={{ margin: 'auto' }}>  Vuelta</Col>
                                </Row>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default TripModeSelectorComponent;
