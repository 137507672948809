import React, { FunctionComponent, useState } from "react";
import {
  Tooltip,
  Modal,
  Space,
  Button,
  validateIconType,
  Icons,
} from "@triporate/triporate-design-system";
import { SBTHeaderData } from "../type";
import ContactInfo from "../../ContactInfo";

type HelpModalProps = {
  data: SBTHeaderData["help"];
};

const HelpModal: FunctionComponent<HelpModalProps> = ({
  data,
}): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);
  const showModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  if (!data) return <></>;
  return (
    <div className="help-div">
      <Tooltip title="Help">
        <Button
          type="link"
          onClick={showModal}
          className="header-button help-modal-button"
        >
          <Icons icon={validateIconType(data?.icon)} />
        </Button>
      </Tooltip>
      <Modal
        closable={false}
        visible={isVisible}
        onOk={closeModal}
        okText="Cerrar"
        cancelButtonProps={{ style: { display: "none" } }}
        width={600}
      >
        <Space align="start" direction="horizontal">
          <ContactInfo {...data} />
        </Space>
      </Modal>
    </div>
  );
};

export default HelpModal;
