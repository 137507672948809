import React, { FunctionComponent } from "react";
import { Form } from "@triporate/triporate-design-system";

import { InputData } from "../../../../../../types";

type ColLayout = { span?: number; offset?: number };

type MultiFormItemProps = {
  name: number;
  fieldKey: number;
  inputName: string;
  inputData: InputData;
  labelCol?: ColLayout;
  wrapperCol?: ColLayout;
};

const MultiFormItem: FunctionComponent<MultiFormItemProps> = ({
  name,
  fieldKey,
  inputName,
  inputData,
  children,
  ...props
}): JSX.Element => (
  <Form.Item
    {...props}
    name={[name, inputName]}
    colon={false}
    fieldKey={[fieldKey, inputName]}
    rules={inputData?.rules}
  >
    {children}
  </Form.Item>
);

export default MultiFormItem;
