import React, { FunctionComponent, useState, useEffect } from "react";

import NextResults from "../../../NextResults";
import { OtherSolutions as OtherSolutionsType } from "../../types";
import OtherSolutionsGroup from "./OtherSolutionsGroup";

type OtherSolutionsProps = {
  data: OtherSolutionsType[];
};

const OtherSolutions: FunctionComponent<OtherSolutionsProps> = ({
  data,
}): JSX.Element => {
  const [visibleOptions, setVisibleOptions] = useState<typeof data>();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSizeNumber] = useState(1);
  const currentPageNumber = pageNumber * pageSizeNumber;

  useEffect(() => {
    const paginatedOffers = data?.slice(0, currentPageNumber);
    setVisibleOptions(paginatedOffers);
  }, [data, currentPageNumber, setVisibleOptions]);

  const handleNextPage = () => {
    const maxPageNumberReached = pageNumber + 1 <= data.length;
    if (!maxPageNumberReached) return;
    setPageNumber((prevState) => prevState + 1);
  };

  return (
    <>
      {visibleOptions?.map(
        (
          { groupName, options, filterValues }: OtherSolutionsType,
          i
        ): JSX.Element => (
          <OtherSolutionsGroup
            key={i}
            groupName={groupName}
            options={options}
            filterValues={filterValues}
          />
        )
      )}

      <NextResults
        showSpinner={!(visibleOptions?.length === data?.length)}
        enableTimeOut
        nextPage={handleNextPage}
      />
    </>
  );
};
export default OtherSolutions;
