import { AgentBookingToolData } from "./types";

const agentBookingToolMockData: AgentBookingToolData = {
  header: {
    isEstimate: false,
    mode: "offline",
    company: {
      name: "Random",
      logo: "https://res.cloudinary.com/chalo/image/upload/v1627460688/logos/brand-triporate-horizontal-rgb-72dpi.png",
      alt: "Company",
      agency: {
        name: "Random",
        logo: "https://res.cloudinary.com/chalo/image/upload/v1627460688/logos/brand-triporate-horizontal-rgb-72dpi.png",
        alt: "Agency",
      },
    },
    tripNumber: {
      label: "Trip#",
      number: "9342343",
    },
    options: [
      {
        icon: {
          class: "train",
        },
        label: "Trains",
        href: "train-form",
        type: "train",
      },
    ],
  },
  articles: {
    href: "articles-data",
  },
  role: "agent",
  sideBar: [{ tabName: "filters", key: "filter" }],
};

export default agentBookingToolMockData;
