import { FetchService } from "../FetchService";
import { Summary } from "./types";

interface Response {
  error?: boolean;
  data?: Summary;
}

export async function itineraryRequest(
  url: string,
  tripId?: string
): Promise<Response> {
  const fetchService = new FetchService();
  const { data, error } = await fetchService.get(`${url}${tripId || ""}`);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
