import React, { FunctionComponent } from "react";
import FareFamilies from "./FareFamilies";
import {
  FaresData,
  SelectedFare,
  SelectedPriority,
  SelectedBaggage,
  SelectedBaggagePrice,
  SelectedId,
  SelectedIdBag,
} from "../types";

import Common from "./Common";
import { Addons } from "../../../../../../../services/Results/getTransportAncillaries/types";
import { AncillarieType } from "../../AncillarieType";

type AncillariesContentProps = {
  selectedTab: string;
  faresData: FaresData;
  selectedFare: SelectedFare;
  setSelectedFare: (value: SelectedFare) => void;
  selectedPriority: SelectedPriority;
  setSelectedPriority: (value: SelectedPriority) => void;
  selectedBaggage: SelectedBaggage;
  setSelectedBaggage: (value: SelectedBaggage) => void;
  selectedBaggagePrice: SelectedBaggagePrice;
  setSelectedBaggagePrice: (value: SelectedBaggagePrice) => void;
  selectedId: SelectedId;
  setSelectedId: (value: SelectedId) => void;
  addons: Addons;
  selectedIdBag: SelectedIdBag;
  setSelectedIdBag: (value: SelectedIdBag) => void;
};

const AncillariesContent: FunctionComponent<AncillariesContentProps> = ({
  selectedTab,
  faresData,
  selectedFare,
  setSelectedFare,
  selectedPriority,
  setSelectedPriority,
  selectedBaggage,
  setSelectedBaggage,
  selectedBaggagePrice,
  setSelectedBaggagePrice,
  selectedId,
  setSelectedId,
  addons,
  selectedIdBag,
  setSelectedIdBag,
}) => {
  switch (selectedTab) {
    case "fares":
    default:
      return (
        <FareFamilies
          data={faresData}
          selectedFare={selectedFare}
          setSelectedFare={setSelectedFare}
        />
      );
    case AncillarieType.priority:
    case AncillarieType.baggage:
      return (
        <Common
          selectedPriority={selectedPriority}
          setSelectedPriority={setSelectedPriority}
          selectedTab={selectedTab}
          selectedBaggage={selectedBaggage}
          setSelectedBaggage={setSelectedBaggage}
          selectedBaggagePrice={selectedBaggagePrice}
          setSelectedBaggagePrice={setSelectedBaggagePrice}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          addons={addons}
          selectedIdBag={selectedIdBag}
          setSelectedIdBag={setSelectedIdBag}
        />
      );
  }
};

export default AncillariesContent;
