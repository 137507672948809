import React, { FunctionComponent, useState, useEffect } from "react";
import { Col, Spin } from "@triporate/triporate-design-system";
import { useIntersect } from "../../../../hooks";
import "./NextResults.scss";

type NextResultsProps = {
  showSpinner: boolean;
  enableTimeOut?: boolean;
  nextPage: () => void;
};

const NextResults: FunctionComponent<NextResultsProps> = ({
  showSpinner,
  enableTimeOut,
  nextPage,
}) => {
  const [ref, isVisible] = useIntersect();
  const [wasHiden, setWasHiden] = useState(true);

  useEffect(() => {
    if (isVisible && wasHiden) {
      nextPage();
      setWasHiden(false);
    }

    if (enableTimeOut && isVisible && !wasHiden) {
      setTimeout(() => {
        nextPage();
      }, 1000);
    }

    if (!isVisible) {
      setWasHiden(true);
    }
  }, [enableTimeOut, isVisible, nextPage, wasHiden, setWasHiden]);

  return (
    <Col className="center-content next-results" span={24} ref={ref}>
      {showSpinner && <Spin />}
    </Col>
  );
};

export default NextResults;
