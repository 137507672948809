import React, { FunctionComponent, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Alert,
  Price,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";
import { BoughtAlert as BoughtAlertType } from "../../../../types";
import PendingAlert from "./PendingAlert";
import {
  validateCustomTextTypeString,
  validateTextTypeString,
} from "../../../../../../../utils";

const { Title, Text, Link } = Typography;

enum PriceSizeEnum {
  "S" = 5,
  "M" = 4,
  "L" = 3,
}

type BoughtAlertProps = {
  data: BoughtAlertType;
};

const BoughtAlert: FunctionComponent<BoughtAlertProps> = ({
  data,
}): JSX.Element => {
  const [isPending] = useState(!!data.pending);
  return (
    <Alert
      className={isPending ? "item-pending" : ""}
      message={
        <>
          <Row justify="space-between">
            <Col
              span={18}
              className={validateCustomTextTypeString(data.title.type)}
            >
              <Title
                ellipsis={{ tooltip: true }}
                type={validateTextTypeString(data.title.type)}
                level={5}
                className="custom-type"
              >
                <Icons icon={validateIconType(data.title.icon)} />
                {data.title.text}
              </Title>
            </Col>
            <Col>
              <Price
                money={{ ...data.price.money, currency: "EUR" }}
                status={validateTextTypeString(data.price.status)}
                size={PriceSizeEnum["S"]}
              />
            </Col>
          </Row>
          <Row>
            {data.details?.map((detail: string, key: number) => (
              <Col span={24} key={key}>
                <Text style={{ maxWidth: "80%" }} ellipsis={{ tooltip: true }}>
                  {detail}
                </Text>
              </Col>
            ))}

            {data.bookingInfo?.map((info, key) => (
              <Col span={24} key={key}>
                <Link href={info.href} download={info.href} target="_blank">
                  <Icons icon={validateIconType(info.icon)} />
                  <Text className="voucher-label" ellipsis={{ tooltip: true }}>
                    {info.text}
                  </Text>
                </Link>
              </Col>
            ))}
          </Row>
          {isPending && <PendingAlert message={data.pending} />}
        </>
      }
    />
  );
};

export default BoughtAlert;
