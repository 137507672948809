import React, { FunctionComponent, ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppliedFilters } from "../../components/commonTypes";

import { getPayments, addOption } from "../../services/Results";
import { PaymentsIFrameModalMessages } from "../../constants";
import { useRoles, useCanIUseByRole } from "../../hooks";

import ResultsSwitch from "./ResultsSwitch";
import IframeModal from "./IframeModal";
import { OptionData } from "./types";
import OptionModal from "./OptionModal";
import LoadingPayments from "./LoadingPayments";
import { AppliedOrder } from "@triporate/triporate-design-system/dist/components/utils/FiltersUI/type";
import { ScrollToTop } from "@triporate/triporate-design-system";

type RequestData = {
  url: string;
  body: string;
};

type ResultsProps = {
  inputsType?: string;
  searchFormRequestData?: RequestData;
  appliedFilters: AppliedFilters[];
  appliedOrder?: AppliedOrder;
  setIsAfterSearch?: (isAfterSearch: boolean) => void;
};

const Results: FunctionComponent<ResultsProps> = ({
  inputsType,
  searchFormRequestData,
  appliedFilters,
  appliedOrder,
  setIsAfterSearch
}): ReactElement => {
  const history = useHistory();
  const [roles] = useRoles();

  const [canTravelManagerUse] = useCanIUseByRole(roles.travelManager);
  const [canTravellerUse] = useCanIUseByRole(roles.traveller);
  const [canTravelAgentUse] = useCanIUseByRole(roles.agent);
  const [paymentsIframe, setPaymentsIframe] = useState<string>();
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const [loadingPayments, setLoadingPayments] = useState<boolean>(false);
  const [optionModalData, setOptionModalData] = useState<OptionData>();

  const fetchPaymentsData = async ({
    url,
    body,
  }: {
    url: string;
    body: string;
  }) => {
    const { data } = await getPayments(url, body);
    if (data) {
      setPaymentsIframe(data.paymentPage);
      setModalIsVisible(true);
      history.push({
        search: `?offerId=${data.offerId}`,
      });
    }
  };

  const fetchAddOption = async ({
    url,
    body,
  }: {
    url: string;
    body: string;
  }) => {
    const { data } = await addOption(url, body);
    if (data) {
      setOptionModalData(data);
    }
  };

  const handleFetchData = async (requestData: {
    url: string;
    body: string;
  }) => {
    setLoadingPayments(true);
    if (canTravelManagerUse || canTravellerUse) {
      await fetchPaymentsData(requestData);
    }
    if (canTravelAgentUse) {
      await fetchAddOption(requestData);
    }
    setLoadingPayments(false);
  };

  const handleCloseModal = () => {
    setModalIsVisible(false);
  };

  return (
    <>
      <ScrollToTop />
      {loadingPayments && <LoadingPayments />}
      <ResultsSwitch
        type={inputsType}
        requestData={searchFormRequestData}
        appliedFilters={appliedFilters}
        setPaymentsRequestData={(url, body) => handleFetchData({ url, body })}
        appliedOrder={appliedOrder}
        setIsAfterSearch={setIsAfterSearch}
      />
      <IframeModal
        visible={modalIsVisible}
        closeMessage={PaymentsIFrameModalMessages.closePaymentsIframe}
        redirectMessage={PaymentsIFrameModalMessages.redirectToDashboard}
        closeModal={handleCloseModal}
        redirectModal={handleCloseModal}
        src={paymentsIframe}
      />
      <OptionModal
        data={optionModalData}
        closeModal={() => setOptionModalData(undefined)}
      />
    </>
  );
};

export default Results;
