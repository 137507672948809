import { State, ActionTypes, SETROLE } from "./types";

const initialState: State = "unset";

export const roleReducer = (
  state = initialState,
  action: ActionTypes
): State => {
  switch (action.type) {
    case SETROLE:
      return action.payload;
    default:
      return state;
  }
};
