import React from "react";
import { Col, Row, Skeleton } from "@triporate/triporate-design-system";

const LoadingHeader = (): JSX.Element => (
  <Row>
    <Col>
      <Skeleton
        title={{ width: 30 }}
        paragraph={{ rows: 0 }}
        active
        style={{ paddingLeft: "20px" }}
      />
    </Col>
    <Col span={1} />
    <Col>
      <Skeleton title={{ width: 300 }} paragraph={{ rows: 0 }} active />
    </Col>
  </Row>
);

export default LoadingHeader;
