import { FetchService } from "../../FetchService";
import mockData from "./conditions.json";
import { FareConditions } from "./types";

interface Response {
  error?: boolean;
  data?: FareConditions;
}

const generateMockData = () => mockData;

export async function getFareConditions(url: string): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return { data: generateMockData() };

  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
