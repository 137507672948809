import React, { FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { parseLocationSearch } from "../../../../utils";
import { getCarResults } from "../../../../services/Results";
import LoadingResults from "../LoadingResults";
import NoResultsMessage from "../NoResultsMessage";
import { RequestData, ResultsData } from "./types";
import Context from "./Context";
import Solutions from "./Solutions";
import ConfirmationDrawer from "../ConfirmationDrawer";
import { useFilterResults } from "@triporate/triporate-design-system";
import { AppliedFilters } from "../../../commonTypes";

type CarResultsProps = {
  requestData?: RequestData;
  appliedFilters?: AppliedFilters[];
  setPaymentsRequestData: (url: string, body: string) => void;
};

const CarResults: FunctionComponent<CarResultsProps> = ({
  requestData,
  appliedFilters = [],
  setPaymentsRequestData,
}): JSX.Element => {
  const { search } = useLocation();

  const [searched, setSearched] = useState(false);
  const [results, setResults] = useState<ResultsData["results"]>([]);
  const [noResults, setNoResults] = useState<ResultsData["noResults"]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [summaryRequestData, setSummaryRequestData] =
    useState<RequestData | null>(null);

  useEffect(() => {
    const handleFetch = async ({ url, body }: RequestData) => {
      setResults([]);
      setSearched(true);
      setLoading(true);
      const { data } = await getCarResults(url, body);

      if (data) {
        setResults(data.results);
        setNoResults(data.noResults);
      }
      setLoading(false);
    };

    if (requestData) handleFetch(requestData);
  }, [requestData]);

  const [filteredResults, setAppliedFilters] = useFilterResults(results);

  useEffect(() => {
    setAppliedFilters(appliedFilters);
  }, [appliedFilters, setAppliedFilters]);

  const onBuyButtonClick = (options: {
    href: string;
    showSummary?: boolean | undefined;
    solutionId?: string | undefined;
  }) => {
    if (!options.href) return;
    const offerId = parseLocationSearch(search, "offerId");
    const tripId = parseLocationSearch(search, "tripId");
    const body = JSON.stringify({
      offerId,
      solutionId: options.solutionId,
      tripId,
    });
    if (options.showSummary) {
      setSummaryRequestData({ url: options.href, body });
    } else {
      setPaymentsRequestData(options.href, body);
    }
  };

  if (loading) {
    return <LoadingResults />;
  }

  if (searched && !results?.length)
    return <NoResultsMessage message={noResults?.apiMsg} />;

  if (searched && !filteredResults?.length)
    return <NoResultsMessage message={noResults?.filtersMsg} />;

  return (
    <Context.Provider value={{ onBuyButtonClick }}>
      <Solutions solutions={filteredResults} />

      <ConfirmationDrawer
        requestData={summaryRequestData}
        cleanRequestData={() => {
          setSummaryRequestData(null);
        }}
        cleanOnClose
        setPaymentsRequestData={setPaymentsRequestData}
      />
    </Context.Provider>
  );
};

export default CarResults;
